import Grid from "@mui/material/Grid2"
import React from "react"
import { swoop_white } from "../images";

export interface ProductHeroProps {
    imgSrc: string;
    tabletImgSrc?: string;
    mobileImgSrc?: string;
    alt: string;
    title: string;
    subtitle: string;
}

export const ProductHero: React.FC<React.PropsWithChildren<ProductHeroProps>> = (props) => {
    const { imgSrc, mobileImgSrc, tabletImgSrc, alt } = props;
    return (
        <>
            <Grid className="rft_hero_container" container spacing={0}>
                <Grid
                    size={{ xs: 12, md: 6 }}
                    order={{ xs: 2, md: 1 }}
                    className="rft_hero_blank_space"
                >
                    <img src={swoop_white} alt="" className="rft_hero_svg"/>
                    <div className="rft_hero_overlay">
                        <div className="rft_hero_overlay_left_spacer">
                        </div>
                        <div className='rft_hero_text'>
                            <p className="rft_hero_title">{props.title}</p>
                            <p className="rft_hero_subtitle">{props.subtitle}</p>
                        </div>
                        <div className="rft_hero_overlay_right_spacer"></div>
                    </div>
                </Grid>
                <Grid
                    size={{ xs: 12, md: 6 }}
                    order={{ xs: 1, md: 2 }}
                    className="rft_hero_img_container"
                >
                    <picture>
                        <source media="(min-width: 900px)" srcSet={imgSrc} className="rft_hero_img" />
                        <source media="(min-width: 600px)" srcSet={tabletImgSrc ?? imgSrc} className="rft_hero_img" />
                        <img src={mobileImgSrc ?? imgSrc} alt={alt} className="rft_hero_img" />
                    </picture>
                </Grid>
            </Grid>
        </>
    )
}