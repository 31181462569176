import React from 'react';
import { FAQSection } from "../../components/FAQ";

export const FAQs_Sealers: FAQSection[] = [
    {
        section: "",
        questions: [
            {
                question: "What are Bona Waterborne Sealers?",
                answer: <p>Bona Waterborne Sealers provide the initial build layer for finish to sit on for maximum performance and durability. Bona's waterborne sealers also improve adhesion, minimize side-bonding and reduce the chance for tannin pull.</p>,
            },
            {
                question: "Can Bona Waterborne Sealers be combined with other Bona products?",
                answer: <p>Yes, Bona Waterborne Sealers are designed to work in conjunction with Bona stains and finishes. This combination provides the best floor protection, enhancing both the appearance and durability of your hardwood floors.</p>,
            },
            {
                question: "How long does it take Bona Waterborne Sealers to dry?",
                answer: <p>Bona Waterborne Sealers dry within 2-3 hours, making them a fast and efficient option for sealing hardwood floors.  Additional information can be found in the Documents tab</p>,
            },
            {
                question: "What is the best way to apply Bona NordicSeal?",
                answer: <p>Waterborne sealers can be very technical because you are applying the product to bare wood. Although you can use any of our applicators, we recommend the T-bar floor coater for the quickest application. Comfort level and layout of the floor will also play a role in your applicator choice. Watch our sealers application best practices video for all the tips.</p>,
            },
            {
                question: "Is Bona NaturalSeal a clear sealer or does it have pigment? ",
                answer: <p>Bona NaturalSeal has a little bit of white pigment. The amount of pigment in the product allows it to virtually disappear, allowing the wood to look as though there is nothing on the floor, but a pure clear sealer. It's not recommended for all woods as your darker species could turn out frosty or hazy looking. The same holds true if going over a dark stained floor.</p>,
            },
            {
                question: "Can I use Bona Sealers over Bona DriFast Stain?",
                answer: 
                <>
                    <p>It's important to note that you do not need to use a sealer when you use Bona DriFast Stain. Our stain formula will penetrate and seal the wood so it's ready for a coat of finish. However, if you do decide to use a sealer over Bona stain for coloration purposes, we do have some recommendations.</p>
                    <p>First of all, we always recommend reading through the complete product specs before application in addition to making samples for client approval before coating the floor. For white stain, we recommend Bona NordicSeal, Bona ClearSeal, or Bona NaturalSeal to enhance or keep it from changing color. For all other colors, we recommend Bona IntenseSeal or Bona AmberSeal to enhance and/or protect color. We do not recommend using Bona ClassicSeal over stain.</p>
                </>,
            },
        ],
    },
];