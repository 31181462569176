import React, { useEffect } from "react"
import ReactGA from "react-ga4";
import { SiteFooter } from "./SiteFooter"
import { SiteHeader } from "./SiteHeader"
import { Helmet } from "react-helmet-async"

interface PageLayoutProps {
    title?: string;
}

export const PageLayout: React.FC<React.PropsWithChildren<PageLayoutProps>> = (props) => {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: props.title });
    })
    
    return (
        <>
            {props.title && (
                <Helmet>
                    <title>{props.title}</title>
                </Helmet>
            )}

            <SiteHeader />
            <div className="rft_site_content">
                {props.children}
            </div>
            <SiteFooter />
        </>
    )
}