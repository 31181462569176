import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from "@mui/material";
import { Divider } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React from "react"
import { PageLayout } from "../components/PageLayout"
import { ProductHero } from "../components/ProductHero"
import { floor_machine, hero_equipment_janitorial, janitorial_cleaning_equipment_left, janitorial_cleaning_equipment_right, mobile_hero_equipment_janitorial, nilfisk_machine, tablet_hero_equipment_janitorial } from "../images"

export const JanEquipmentPage: React.FC<React.PropsWithChildren<{}>> = (props) => {

    return (
        <PageLayout title="Janitorial Equipment | Rossi Floor Technologies | Pittsburgh, PA">
            <ProductHero
                imgSrc={hero_equipment_janitorial}
                mobileImgSrc={mobile_hero_equipment_janitorial}
                tabletImgSrc={tablet_hero_equipment_janitorial}
                alt=""
                title="Janitorial Cleaning Equipment"
                subtitle="Equipment for Unique Floors."
            />
            <div className="rft_content">
                <div className="rft_product_section_container">
                    <div style={{ display: "flex", margin: "24px", gap: "2rem", flexWrap: "wrap" }}>
                        <div style={{ flex: "1 1 50%", gap: "2rem" }}>
                            <div className="rft_stack">
                                <div>
                                    <h2 className="rft_section_header">Your Partner in Cleaning Solutions</h2>
                                    <p>
                                        Choosing the right cleaning equipment can be daunting, but our experienced team is here to guide you every step of the way.
                                        Whether you're looking for advice on machine selection or need assistance with maintenance, we're dedicated to
                                        helping you achieve optimal performance and efficiency.
                                    </p>
                                </div>
                                <div className='rft_stack_shop_now_container'>
                                    <Button
                                        variant="text"
                                        startIcon={<ArrowForwardIcon />}
                                        href="https://catalog.rossifloor.net/catalog/Category/JMTEQU/Janitorial-Cleaning-Equipment/"
                                        target='_blank'
                                        className="rft_stack_shop_now"
                                    >
                                        shop now
                                    </Button>
                                </div>
                            </div >
                        </div>
                        <div style={{ display: "flex", flex: "1 1 45%", flexWrap: "nowrap", gap: "2rem", justifyContent: "center" }}>
                            <img src={janitorial_cleaning_equipment_right} alt="" style={{ maxHeight: "350px", display: "inline" }} />
                            <img src={janitorial_cleaning_equipment_left} alt="" style={{ maxHeight: "350px", display: "inline" }} />
                        </div>

                    </div>
                    {/* <Grid className="rft_content" container spacing={3} sx={{ marginTop: "48px", marginBottom: "48px" }}>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <div className="rft_stack">
                                <div>
                                    <h2 className="rft_section_header">Your Partner in Cleaning Solutions</h2>
                                    <p>
                                        Choosing the right cleaning equipment can be daunting, but our experienced team is here to guide you every step of the way.
                                        Whether you're looking for advice on machine selection or need assistance with maintenance, we're dedicated to
                                        helping you achieve optimal performance and efficiency.
                                    </p>
                                </div>
                                <div className='rft_stack_shop_now_container'>
                                    <Button
                                        variant="text"
                                        startIcon={<ArrowForwardIcon />}
                                        href="https://catalog.rossifloor.net/catalog/Category/JMTEQU/Janitorial-Cleaning-Equipment/"
                                        target='_blank'
                                        className="rft_stack_shop_now"
                                    >
                                        shop now
                                    </Button>
                                </div>
                            </div >
                        </Grid>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <img src={janitorial_cleaning_equipment_right} alt="abrasives" className='rft_img_center' style={{ maxHeight: "350px", display: "inline", marginRight: "12px" }} />
                            <img src={janitorial_cleaning_equipment_left} alt="abrasives" className='rft_img_center' style={{ maxHeight: "350px", display: "inline" }} />
                        </Grid>
                    </Grid> */}
                </div>
            </div>

            <Divider sx={{ marginTop: "12px", marginBottom: "48px" }} />

            <div className="rft_content">
                <div className="rft_product_section_container">
                    <Grid className="rft_content" container spacing={3} sx={{ marginTop: "48px", marginBottom: "48px" }}>
                        <Grid size={{ xs: 12, md: 6 }} order={{ xs: 1, md: 2 }}>
                            <div className="rft_stack">
                                <div>
                                    <h2 className="rft_section_header">Extensive Inventory of Parts and Accessories</h2>
                                    <p>
                                        We understand that keeping your cleaning equipment running smoothly is essential to your operations.
                                        That's why Rossi Floor Technologies carries a large inventory of replacement parts and accessories.
                                        With our quick access to these essential components, you can minimize downtime and keep your equipment in top condition.
                                    </p>
                                </div>
                                <div></div>
                            </div >
                        </Grid>
                        <Grid size={{ xs: 12, md: 6 }} order={{ xs: 2, md: 1 }}>
                            <img src={nilfisk_machine} alt="abrasives" className='rft_img_center' style={{ maxHeight: "350px" }} />
                        </Grid>
                    </Grid>
                </div>
            </div>

            <Divider sx={{ marginTop: "12px", marginBottom: "48px" }} />

            <div className="rft_content">
                <div className="rft_product_section_container">
                    <Grid className="rft_content" container spacing={3} sx={{ marginTop: "48px", marginBottom: "48px" }}>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <div className="rft_stack">
                                <div>
                                    <h2 className="rft_section_header">Experience the Rossi Difference</h2>
                                    <p>
                                        At Rossi Floor Technologies, we redefine what it means to partner with a janitorial equipment supplier.
                                        Specializing in industrial cleaning solutions, we provide not just top-tier machinery, but also exceptional sales and support tailored to your needs.
                                    </p>
                                    <p>Visit us for:</p>
                                    <ul style={{ columns: 2, WebkitColumns: 2, MozColumns: 2 }}>
                                        <li>Sweepers</li>
                                        <li>Burnishers</li>
                                        <li>Dryers</li>
                                        <li>Extractors</li>
                                        <li>Floor machines</li>
                                        <li>Vacuums</li>
                                        <li>Scrubbers:</li>
                                        <li>Auto</li>
                                        <li>Walk</li>
                                        <li>Riders</li>
                                    </ul>
                                </div>
                                <div></div>
                            </div >
                        </Grid>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <img src={floor_machine} alt="abrasives" className='rft_img_center' />
                        </Grid>
                    </Grid>
                </div>
            </div>



        </PageLayout>
    )
}