import React from "react"
import { PageLayout } from "../components/PageLayout"
import { FAQComponent } from "../components/FAQ"
import { FAQs_General } from "../static-data/faqs/General"

export const FAQPage: React.FC<React.PropsWithChildren<{}>> = (props) => {

    return (
        <PageLayout title="Frequently Asked Questions | Rossi Floor Technologies">
            <div className="rft_content">
                <div className="rft_product_section_container" style={{ margin: "48px 0px" }}>
                    <FAQComponent faqs={FAQs_General} quickLinks={false} title="General FAQs" />
                </div>
            </div>
        </PageLayout>
    )
}