import { pacific_filler_sell_sheet, pacific_filler_tds } from "../../documents";
import { pacific_filler, thumbnail_pacific_filler } from "../../images";
import { Product, ProductCategory } from "../types";

export const Products_Fillers: Product[] = [
    {
        name: "Bona Pacific Filler",
        category: ProductCategory.Fillers,
        index: 0,
        description: "A ready-to-use waterborne wood floor filler for spot or full-trowel filling.",
        imgSrc: pacific_filler,
        shopNowUrl: "https://catalog.rossifloor.net/Catalog/CatalogSearch.aspx?Value=fillers",
        thumbnailSrc: thumbnail_pacific_filler,
        documents: [
            { title: "Bona Pacific Filler TDS", pdf: pacific_filler_tds },
            { title: "Bona Pacific Filler Sell Sheet", pdf: pacific_filler_sell_sheet },
        ],
        coverageLow: 600, coverageHigh: 800, dryTimeLow: .5, dryTimeHigh: 2
    },
];

