import { Link } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React, { useState } from "react";
import ReactGA from "react-ga4";
import { PageLayout } from "../components/PageLayout";
import { RftButton } from "../RftButton";


type ContactFormSubmitStatus =
    | "Unsubmitted"
    | "Submitting"
    | "SubmittedWithSuccess"
    | "SubmittedWithError";

interface MessageToTwinOak {
    name: string;
    email: string;
    phone: string;
    company: string;
    message: string;
}

const defaultMessage: MessageToTwinOak = {
    name: "",
    email: "",
    phone: "",
    company: "",
    message: "",
};


export const ContactPage: React.FC<React.PropsWithChildren<{}>> = (props) => {

    const [formSubmitStatus, setFormSubmitStatus,] = useState<ContactFormSubmitStatus>("Unsubmitted");
    const [messageToRft, setMessageToTwinOak] = useState(defaultMessage);

    function handleFormSubmit(event: React.FormEvent<HTMLFormElement>): void {
        event.stopPropagation();
        event.preventDefault();
        setFormSubmitStatus("Submitting");

        ReactGA.event({
            category: 'engagement',
            action: 'form_submission',
            label: 'Contact Form',
          });

        const init: RequestInit = {
            method: "POST",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(messageToRft),
        };

        const baseApiUrl = "https://api-rossifloor.azurewebsites.net";

        fetch(baseApiUrl + "/contact-us", init)
            .then(function (response) {
                if (!response.ok) {
                    throw new Error("Not 2xx response");
                }
                setFormSubmitStatus("SubmittedWithSuccess");
            })
            .catch(function () {
                setFormSubmitStatus("SubmittedWithError");
            });
    }

    function handleInputChange({
        currentTarget: { name, value },
    }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setMessageToTwinOak((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    return (
        <PageLayout title="Contact Us | Rossi Floor Technologies | Pittsburgh, PA">
            <div className='rft_page_content'>
                <Grid className="rft_content" container spacing={3}>
                    <Grid
                        size={{ xs: 12, md: 6 }}
                        order={{ xs: 3, md: 1 }}
                    >
                        <p style={{ textAlign: "left" }} className="rft_content_header">Rossi Floor Technologies</p>
                        <p>305 23rd St.</p>
                        <p>Pittsburgh, PA 15215</p>

                        <div style={{ paddingTop: "12px" }}>
                            <p>Mon - Fri: 8:00 am - 4:30 pm</p>
                            <p>Sat - Sun: Closed</p>
                        </div>

                        <div style={{ paddingTop: "12px" }}>
                            <p><b>Main Phone:</b> (412) 449-0120</p>
                            <p><b>Fax:</b> (412) 449-0130</p>
                            <p><b>Email Us:</b> <Link href="mailto:mail@rossifloor.net" underline="none">mail@rossifloor.net</Link></p>
                        </div>

                    </Grid>
                    <Grid size={{ xs: 12, md: 5 }} order={{ xs: 1, md: 3 }}>

                        <p style={{ textAlign: "left", fontSize: "12px", letterSpacing: ".2em", margin: "0px" }} >GET IN TOUCH.</p>
                        <p style={{ textAlign: "left" }} className="rft_content_header">let's work together!</p>
                        <p style={{ fontSize: "14px" }}>Please fill out this short form and we'll contact you shortly.</p>
                        {(formSubmitStatus === "Unsubmitted" ||
                            formSubmitStatus === "Submitting") && (
                                <form onSubmit={handleFormSubmit}>
                                    <Grid container spacing={2}>
                                        <Grid size={{ xs: 12, md: 6 }}>
                                            <input
                                                type="text"
                                                className="rft_contact_input"
                                                placeholder="* Your Name"
                                                required
                                                name="name"
                                                onChange={handleInputChange}
                                                value={messageToRft.name}
                                            />
                                        </Grid>
                                        <Grid size={{ xs: 12, md: 6 }}>
                                            <input
                                                type="text"
                                                className="rft_contact_input"
                                                placeholder="* Your Email"
                                                required
                                                name="email"
                                                onChange={handleInputChange}
                                                value={messageToRft.email}
                                            />
                                        </Grid>
                                        <Grid size={{ xs: 12, md: 6 }}>
                                            <input
                                                type="text"
                                                className="rft_contact_input"
                                                placeholder="Your Phone"
                                                name="phone"
                                                onChange={handleInputChange}
                                                value={messageToRft.phone}
                                            />
                                        </Grid>
                                        <Grid size={{ xs: 12, md: 6 }}>
                                            <input
                                                type="text"
                                                className="rft_contact_input"
                                                placeholder="Your Company"
                                                name="company"
                                                onChange={handleInputChange}
                                                value={messageToRft.company}
                                            />
                                        </Grid>

                                        <Grid size={12}>
                                            <textarea
                                                className="tos-form_Textarea rft_contact_input"
                                                placeholder="* Tell us more"
                                                required
                                                name="message"
                                                onChange={handleInputChange}
                                                value={messageToRft.message}
                                                rows={5}
                                            />
                                        </Grid>
                                    </Grid>
                                    <RftButton
                                        type="submit"
                                        className="tos_form_button"
                                        disabled={formSubmitStatus === "Submitting"}
                                        sx={{ marginTop: "24px", fontSize: "10px", letterSpacing: ".2em", padding: "22px" }}
                                    >
                                        Send it over
                                    </RftButton>
                                </form>
                            )}

                        {formSubmitStatus === "SubmittedWithError" && (
                            <div>
                                <p>
                                    An unexpected error occurred. Please email us at{" "}
                                    <a href="mailto:mail@rossifloor.net" target="_email">
                                        mail@rossifloor.net
                                    </a>
                                </p>
                            </div>
                        )}
                        {formSubmitStatus === "SubmittedWithSuccess" && (
                            <div>
                                <p>
                                    Thanks for contacting us. We'll get back to you as soon as we can.
                                </p>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </div>
            <div className='rft_map_wrapper'>
                <iframe
                    title="rft_map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6068.197534819102!2d-79.91592182359483!3d40.49519895085907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8834ed672c2c8283%3A0x9542c9ceee5a53f5!2s305%2023rd%20St%2C%20Pittsburgh%2C%20PA%2015215!5e0!3m2!1sen!2sus!4v1729424844960!5m2!1sen!2sus"

                    loading="lazy" />
            </div>
        </PageLayout>
    )
}