import { chroma_how_to_best_practices_sheet, chroma_sell_sheet, chroma_tds, chroma_troubleshooting, drifast_stain_contractor_brochure, drifast_stain_sell_sheet, drifast_stain_tds, redout_faqs, redout_sell_sheet, redout_tds, transtint_tds, varathane_tds } from "../../documents";
import { bona_drifast_stain, bona_redout, chroma, thumbnail_bona_drifast_stain, thumbnail_bona_redout, thumbnail_chroma, thumbnail_transtint, thumbnail_verathane_rustoleum, transtint, verathane_rustoleum } from "../../images";
import { Product, ProductCategory, ProductSubCategory } from "../types";

export const Products_Stains: Product[] = [
    {
        name: "Bona DriFast Stain",
        category: ProductCategory.Stains,
        subCategory: ProductSubCategory.OilModified,
        index: 0,
        description: "A premium quality, oil-modified stain for interior hardwood floors. Provides speed and high performance for hardwood floor finishing professionals.",
        imgSrc: bona_drifast_stain,
        shopNowUrl: "https://catalog.rossifloor.net/Catalog/CatalogSearch.aspx?Value=drifast+stain",
        thumbnailSrc: thumbnail_bona_drifast_stain,
        documents: [
            { title: "Bona DriFast Stain TDS", pdf: drifast_stain_tds },
            { title: "Bona DriFast Stain Sell Sheet", pdf: drifast_stain_sell_sheet },
            { title: "Bona DriFast Stain Brochure", pdf: drifast_stain_contractor_brochure },
            
        ],
        coverageLow: 800, coverageHigh: 1000, dryTimeLow: 2, dryTimeHigh: 18
    },
    {
        name: "Bona Chroma",
        category: ProductCategory.Stains,
        subCategory: ProductSubCategory.Waterborne,
        index: 1,
        description: "Bona Chroma® Waterborne Wood Floor Coloration is a specially formulated low VOC, low/no odor coloration product that is easy-to-use, eliminates the need to water-pop the floor, and gives the wood an intense and vibrant color prior to sealer and finish being applied. ",
        imgSrc: chroma,
        shopNowUrl: "https://catalog.rossifloor.net/Catalog/CatalogSearch.aspx?Value=chroma",
        thumbnailSrc: thumbnail_chroma,
        documents: [
            { title: "Bona Chroma TDS", pdf: chroma_tds },
            { title: "Bona Chroma Sell Sheet", pdf: chroma_sell_sheet },
            { title: "Bona Chroma Best Practices", pdf: chroma_how_to_best_practices_sheet },
            { title: "Bona Chroma Troubleshooting", pdf: chroma_troubleshooting },
        ],
        coverageLow: 600, coverageHigh: 700, dryTimeLow: 2, dryTimeHigh: 3
    },
    {
        name: "Bona RedOut",
        category: ProductCategory.Stains,
        subCategory: ProductSubCategory.Waterborne,
        index: 2,
        description: "Bona Red Out™ is a simple, easy-to-use solution to remove red and pink hues commonly found in red oak flooring and other wood species with red tones.",
        imgSrc: bona_redout,
        thumbnailSrc: thumbnail_bona_redout,
        documents: [
            { title: "Bona RedOut TDS", pdf: redout_tds },
            { title: "Bona RedOut Sell Sheet", pdf: redout_sell_sheet },
            { title: "Bona RedOut FAQs", pdf: redout_faqs },
            
        ],
        coverageLow: 450, coverageHigh: 650, dryTimeLow: 12, dryTimeHigh: 12
    },
    {
        name: "Varathane Wood Stain",
        category: ProductCategory.Stains,
        subCategory: ProductSubCategory.Waterborne,
        index: 3,
        description: "Dries in 1 hour. Achieve color in one coat. Covers 75% more. Oil based. Complete projects 3X faster.",
        imgSrc: verathane_rustoleum,
        shopNowUrl: "https://catalog.rossifloor.net/Catalog/CatalogSearch.aspx?Value=varathane",
        thumbnailSrc: thumbnail_verathane_rustoleum,
        documents: [
            { title: "Varathane Wood Stain TDS", pdf: varathane_tds },
        ],
        coverageLow: 900, coverageHigh: 1100, dryTimeLow: 2, dryTimeHigh: 2
    },
    {
        name: "TransTint Liquid Dye",
        category: ProductCategory.Stains,
        subCategory: ProductSubCategory.Dyes,
        index: 4,
        description: "Formulated from light stable metallized acid dyes. More lightfast when compared to conventional \"aniline\" powdered type dye stains. Concentrated formulation allows the user great latitude in using the dye because glycol ethers are compatible with a wide variety of finishing products.",
        imgSrc: transtint,
        shopNowUrl: "https://catalog.rossifloor.net/Catalog/CatalogSearch.aspx?Value=transtints",
        thumbnailSrc: thumbnail_transtint,
        documents: [
            { title: "TransTint Liquid Dye TDS", pdf: transtint_tds },
        ],
        coverageLow: 800, coverageHigh: 1000, dryTimeLow: 2, dryTimeHigh: 18
    },
];

