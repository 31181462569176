import React from "react"
import { PageLayout } from "../components/PageLayout"
import { Link } from "@mui/material"
import { urlToHome } from "../Routing"
import { InfoGraphic } from "../components/InfoGraphic"
import { cat_rentals } from "../images"

export const Page404: React.FC<React.PropsWithChildren<{}>> = (props) => {

    return (
        <PageLayout title="Page Not Found | Rossi Floor Technologies">
            <div className="rft_content" style={{ textAlign: "center", marginTop: "2rem", marginBottom: "4rem" }}>
                <InfoGraphic title="Page not found" imgSrc={cat_rentals}
                >
                    <>
                        <p>Error!</p>
                        <h1 style={{ fontSize: "5rem", margin: "1rem" }} > 404</h1>
                        <p>Page Not Found</p>
                        <br />
                        <Link href={urlToHome} underline="always" sx={{ textDecorationColor: "white" }}>Click here to return to the home page.</Link>
                    </>
                </InfoGraphic>


            </div>
        </PageLayout >
    )
}