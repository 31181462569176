import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { LinkProps } from 'react-router-dom';

export const RftButton = styled(Button)<ButtonProps | LinkProps>(({ theme }) => ({
  maxWidth: 230,
  height: 39,
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 0,
  textTransform: "uppercase",
  fontWeight: 700,
  letterSpacing: "0.1em",
}));
