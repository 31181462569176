import Grid from '@mui/material/Grid2';
import React, { useEffect } from "react";
import { PageLayout } from "../components/PageLayout";

export const ReviewsPage: React.FC<React.PropsWithChildren<{}>> = (props) => {

    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "//widget.hibu.us/static/js/front.js";
        script.async = true;
      
        document.body.appendChild(script);
      
        return () => {
          document.body.removeChild(script);
        }
      }, []);

    return (
        <PageLayout title="Reviews | Rossi Floor Technologies | Pittsburgh, PA">
            <div className='rft_page_content'>
                <Grid className="rft_content" container spacing={3}>
                    <Grid size={12}>
                        <p 
                            className="rft_content_header"
                            style={{textAlign: 'left', marginBottom: "48px" }}
                        >
                            Here's what our customers are saying...
                        </p>
                        <div id="fdnReviewsWidget" data-merchant="56819"></div>
                    </Grid>
                </Grid>
            </div>
        </PageLayout >
    )
}