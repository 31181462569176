import Grid from "@mui/material/Grid2";
import React from "react";
import { CoverageChart } from "../components/CoverageChart";
import { DocumentCenter } from "../components/DocumentCenter";
import { FAQComponent } from "../components/FAQ";
import { PageLayout } from "../components/PageLayout";
import { ProductHero } from "../components/ProductHero";
import { ProductSquare } from "../components/ProductSquare";
import { ProductTab, ProductTabs } from "../components/ProductTabs";
import { VideoGrid } from "../components/VideoGrid";
import { hero_finishes, mobile_hero_finishes, tablet_hero_finishes } from "../images";
import { FAQs_Finishes } from "../static-data/faqs/Finishes";
import { Products_Finishes } from "../static-data/products/Finishes";
import { ProductCategory, ProductSubCategory } from "../static-data/types";
import { VideoCategories, VideoCategory } from "../static-data/Videos";

export const FinishesPage: React.FC<React.PropsWithChildren<{}>> = (props) => {
    return (
        <PageLayout title="Wood Floor Finishes | Rossi Floor Technologies | Pittsburgh, PA">
            <ProductHero
                imgSrc={hero_finishes}
                mobileImgSrc={mobile_hero_finishes}
                tabletImgSrc={tablet_hero_finishes}
                alt=""
                title="Floor Finishes"
                subtitle="The complete system for flooring contractors"
            />
            <div className="rft_content">
                <ProductTabs tabs={tabs} />
            </div>
        </PageLayout>
    )
}

const waterFinishes = Products_Finishes.filter(p => p.category === ProductCategory.Finishes && p.subCategory === ProductSubCategory.Waterborne);
const oilFinishes = Products_Finishes.filter(p => p.category === ProductCategory.Finishes && p.subCategory === ProductSubCategory.OilModified);
const videos = VideoCategories.filter(p => p.category === VideoCategory.Finishes);

const Overview: React.FC<React.PropsWithChildren<{}>> = (props) => {
    return (

        <div className="rft_product_section_container">
            <h2 className="rft_section_header">Bona Wood Floor Finishes</h2>
            <p style={{ marginBottom: "48px" }}>The world's leading waterborne finishes, providing unsurpassed scuff, scratch and chemical resistance.</p>

            <div className="rft_grid_350">
                {waterFinishes.sort((a, b) => { return a.index - b.index; }).map((p) => {
                    return (
                        <ProductSquare product={p} />
                    );
                })}
            </div>

            <h2 className="rft_section_header">Bona Oil-Modified Finishes</h2>
            <p style={{ marginBottom: "48px" }}>Premium, oil-modified wood floor finish formulated for interior use on residential and commercial wood floors.</p>

            <div className="rft_grid_350">
                {oilFinishes.sort((a, b) => { return a.index - b.index; }).map((p) => {
                    return (
                        <ProductSquare product={p} />
                    );
                })}
                {/* Empty divs to keep the grid columns aligned since there is only on oil product */}
                <div></div>
                <div></div>
            </div>
        </div>
    )
};

const HowTo: React.FC<React.PropsWithChildren<{}>> = (props) => {
    return (
        <div className="rft_product_section_container">
            <VideoGrid sections={videos} />
        </div>
    );
};

const tabs: ProductTab[] = [
    {
        label: "Overview",
        component: <Overview />
    },
    {
        label: "FAQs",
        component: <FAQComponent faqs={FAQs_Finishes} quickLinks={false} />
    },
    {
        label: "Document Center",
        component: <DocumentCenter subtitle="Helpful documents for Bona wood floor finishes." products={Products_Finishes} />
    },
    {
        label: "How To's",
        component: <HowTo />
    },
    {
        label: "Coverage Chart",
        component: <CoverageChart />
    },
];