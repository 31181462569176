import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from "@mui/material";
import React from "react";
import { Product } from '../static-data/types';



export interface ProductSquareProps {
    product: Product;
    outerStyle?: React.CSSProperties;
}

export const ProductSquare: React.FC<React.PropsWithChildren<ProductSquareProps>> = (props) => {
    const { product, outerStyle } = props;

    return (
        <div className="rft_stack rft_product_square" style={outerStyle}>
            <div className="rft_product_square_image">
                <img src={product.imgSrc} alt={product.name} />
            </div>
            <div>
                <h2 className="rft_stack_name">{product.name}</h2>
                <p className="rft_stack_desc">{product.description}</p>
            </div>
            <div>
                {product.shopNowUrl && (
                    <Button
                        variant="text"
                        startIcon={<ArrowForwardIcon />}
                        href={product.shopNowUrl ?? "#"}
                        target='_blank'
                        className="rft_stack_shop_now"
                    >
                        shop now
                    </Button>
                )}
            </div>
        </div >
    );
};