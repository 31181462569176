import { Paper, Typography, useMediaQuery } from "@mui/material";
import Grid from '@mui/material/Grid2';
import React, { useMemo } from "react";
import { AbrasivesCard, AdhesivesCard, CoatingsCard, EquipmentCard, SportCard, ToolsCard } from '../components/CategoryCard';
import { Direction, InfoGraphic } from '../components/InfoGraphic';
import { PageLayout } from "../components/PageLayout";
import {
    banner_3,
    cat_tools,
    hero_abrasives,
    hero_sealers,
    hero_stains,
    mobile_banner_3,
    mobile_hero_abrasives,
    mobile_hero_sealers,
    mobile_hero_stains,
    tablet_banner_3,
    tablet_hero_abrasives,
    tablet_hero_sealers,
    tablet_hero_stains,
    tablet_hero_tools
} from "../images";
import { RftButton } from "../RftButton";
import { urlToCatalogHome, urlToContactUs } from "../Routing";

export const HomePage: React.FC<React.PropsWithChildren<{}>> = (props) => {

    const isMobile = useMediaQuery('(max-width: 599px)');
    const isTablet = useMediaQuery('(max-width: 899px)');

    function getImageSrc(desktopSrc: string, tabletSrc: string, mobileSrc: string) {
        return isMobile ? mobileSrc : isTablet ? tabletSrc : desktopSrc;
    }

    return (
        <PageLayout>
            <HomePageBanner />

            <div className="rft_background_light">
                <div className="rft_content" style={{ padding: '48px 0px' }}>
                    <div className='rft_product_section_container'>
                        <InfoGraphic title="Your One Stop Shop" imgSrc={cat_tools} color="primary"
                        >
                            <p>for Wood Floor Care Products, Janitorial Equipment and More</p>
                            <p>
                                DIYers and pros alike turn to the experts at Rossi Floor Technologies for everything they need—whether
                                it's janitorial cleaning gear, repairs, rentals, or the best in abrasives, finishes, sealers, tools,
                                stains, and more.
                            </p>
                        </InfoGraphic>
                        <div className="rft_mobile_only" style={{ marginTop: "24px", textAlign: "center" }}>
                            <RftButton href={urlToCatalogHome} target="_blank" variant="contained" sx={{ width: "230px" }}>Shop Now</RftButton>
                        </div>
                    </div>
                </div>
            </div>


            <CategoryStripe2
                imgSrc={getImageSrc(hero_stains, tablet_hero_stains, mobile_hero_stains)}
                title="Homegrown in Pittsburgh"
                direction="normal"
            >
                <p>Experience personalized service and quality you can trust at our local, family-owned store.</p>
                <ul style={{ lineHeight: "2" }}>
                    <li>Shop online or in person at our Pittsburgh show room</li>
                    <li>Call for pricing or to place an order</li>
                </ul>
                <RftButton
                    href={urlToContactUs}
                    className=""
                    variant="contained"
                    sx={{ width: "230px", marginTop: "12px" }}>
                    Contact Us Today!
                </RftButton>
            </CategoryStripe2>

            <CategoryStripe2
                imgSrc={getImageSrc(hero_sealers, tablet_hero_sealers, mobile_hero_sealers)}
                title="Home Owners and Contractors Welcome!"
                direction="reverse"
            >
                <p>Whether you're an experienced wood floor contractor, or a DIYer, we have the products, equipment, and knowledge to help you succeed.</p>
            </CategoryStripe2>

            <CategoryStripe2
                imgSrc={getImageSrc(hero_abrasives, tablet_hero_abrasives, mobile_hero_abrasives)}
                title="Equipment Sales, Repairs, Rentals, and Parts"
                direction="normal"
            >
                <p>
                    We offer a full range of floor equipment sales, rentals, repairs, and parts to meet your needs.
                    Whether you're looking to buy, rent, or maintain equipment, we've got you covered.
                    From sanders and buffers to vacuums and scrubbers, we provide expert service, reliable products,
                    and flexible rental options to keep your floors looking their best.
                </p>
            </CategoryStripe2>

            <div className="rft_background_light" style={{ padding: '48px 0px' }}>
                <div className="rft_content">
                    <div className="rft_product_section_container">
                        <h2 className="rft_section_header">Explore our products</h2>
                        <div className="rft_cat_card_container">
                            <CoatingsCard />
                            <AbrasivesCard />
                            <AdhesivesCard />
                            <EquipmentCard />
                            <SportCard />
                            <ToolsCard />
                        </div>
                    </div>
                </div >
            </div >
        </PageLayout >
    )
}

export const HomePageBanner: React.FC<React.PropsWithChildren<{}>> = (props) => {
    const isMobile = useMediaQuery('(max-width: 599px)');
    const isTablet = useMediaQuery('(max-width: 899px)');

    const image = banner_3;
    const tabletImage = tablet_banner_3;
    const mobileImage = mobile_banner_3;

    return useMemo(() => {
        function getImageSrc() {
            if (isMobile) return mobileImage;
            else if (isTablet) return tabletImage;
            else return image;
        }

        return (
            <Grid container
                sx={{
                    backgroundImage: `url(${getImageSrc()})`,
                    width: "100%",
                    height: "560px",
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    backgroundPosition: "50% 50%",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                }}>
                <Grid size={{ xs: 12 }} sx={{ maxWidth: "1100px" }} container>
                    <Grid size={7} sx={{ display: { xs: "none", md: "flex" } }}>
                    </Grid>
                    <Grid sx={{ justifyContent: "center", alignContent: "center", textAlign: 'center' }} size={{ xs: 12, md: 5 }}>
                        <div style={{ backgroundColor: "rgb(231, 240, 255, .75)", padding: "24px" }}>
                            <Typography className="rft_banner_text">
                                Wood Floor Products, Equipment, and Service in Pittsburgh, PA
                            </Typography>
                            <br />
                            <RftButton
                                href={urlToContactUs}
                                className=""
                                variant="contained"
                                sx={{ width: "230px", marginTop: "12px" }}>
                                Contact Us
                            </RftButton>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        );
    }, [image, isMobile, isTablet, mobileImage, tabletImage]);
}

export const MarketingFluff: React.FC<React.PropsWithChildren<{}>> = () => {

    return (
        <>
            {/* One Stop Shop */}
            <div className="rft_product_section_container">
                <Grid className="rft_content" container spacing={3} sx={{ marginTop: "48px", marginBottom: "24px" }}>
                    <Grid size={{ xs: 12, md: 5 }}>
                        <div className="rft_stack">
                            <div>
                                <h2 className="rft_section_header">Your One Stop Shop</h2>
                                <p >for Wood Floor Care Products, Janitorial Equipment and More</p>
                                <p >
                                    DIYers and pros alike turn to the experts at Rossi Floor Technologies for everything they need—whether
                                    it's janitorial cleaning gear, repairs, rentals, or the best in abrasives, finishes, sealers, tools, stains,
                                    and more. Based in Pittsburgh, PA, we've got the whole Tri-State region covered. At the heart of it all,
                                    Rossi Floor Technologies is your go-to for anything wood floor related!
                                </p>
                            </div>
                        </div >
                    </Grid>
                    <Grid size={{ xs: 6 }} className="rft_hide_mobile">
                        <img src={tablet_hero_tools} alt="abrasives" className='rft_img_center' style={{ height: "100%", objectFit: 'cover', borderRadius: '12px' }} />
                    </Grid>
                </Grid>
            </div>
        </>
    )

}

interface CategoryStripeProps {
    imgSrc: string;
    title: string;
    direction: Direction;
}

const CategoryStripe2: React.FC<React.PropsWithChildren<CategoryStripeProps>> = (props) => {
    const { imgSrc, title, direction = "normal" } = props;
    return (
        <div className={`rft_home_stripe ${direction === "reverse" ? "rft_home_stripe_reverse" : ""}`}>
            <div className="rft_home_stripe_image" style={{ backgroundImage: `url(${imgSrc})` }}>
            </div>
            <div className="rft_home_learn_more">
                <Paper
                    className="rft_home_learn_more_paper"
                    square
                >
                    <div className="rft_home_learn_more_inner">
                        <h3 className="rft_section_header">{title}</h3>
                        {props.children}
                    </div>
                </Paper>
            </div>
        </div>
    );
}