import { Product, ProductCategory, ProductSubCategory } from "../types";
import { bona_domo_sell_sheet, bona_domo_tds, bona_mega_one_sell_sheet, bona_mega_one_tds, bona_mega_sell_sheet, bona_mega_tds, bona_traffic_anti_slip_tds, bona_traffic_go_sell_sheet, bona_traffic_go_tds, bona_traffic_hd_sell_sheet, bona_traffic_hd_tds, bona_traffic_raw_sell_sheet, bona_traffic_raw_tds, woodline_poly_tds } from '../../documents';
import { bona_domo, bona_mega, bona_mega_one, bona_traffic_anti_slip, bona_traffic_go, bona_traffic_hd, bona_traffic_hd_raw, bona_woodline_poly, thumbnail_domo, thumbnail_mega, thumbnail_mega_one, thumbnail_traffic_anti_slip, thumbnail_traffic_go, thumbnail_traffic_hd, thumbnail_traffic_hd_raw, thumbnail_woodline } from "../../images";

export const Products_Finishes: Product[] = [
    {
        name: "Bona Traffic HD",
        category: ProductCategory.Finishes,
        subCategory: ProductSubCategory.Waterborne,
        index: 0,
        description: "Bona Traffic HD is the world's leading water based floor finish. The best hardwood floor finish providing unsurpassed scuff, scratch and chemical resistance and has very little VOCs",
        imgSrc: bona_traffic_hd,
        shopNowUrl: "https://catalog.rossifloor.net/Catalog/CatalogSearch.aspx?Value=traffic+hd%20Finish",
        thumbnailSrc: thumbnail_traffic_hd,
        documents: [
            { title: "Bona Traffic HD TDS", pdf: bona_traffic_hd_tds },
            { title: "Bona Traffic HD Sell Sheet", pdf: bona_traffic_hd_sell_sheet },
        ],
        coverageLow: 350, coverageHigh: 400, dryTimeLow: 2, dryTimeHigh: 3, footTraffic: 24, fullCure: 3,
    },
    {
        name: "Bona Traffic HD RAW",
        category: ProductCategory.Finishes,
        subCategory: ProductSubCategory.Waterborne,
        index: 1,
        description: "Premium protection that looks and feels like raw wood. Create ultra-matte, natural looking floors with the superior durability of the Traffic HD family.",
        imgSrc: bona_traffic_hd_raw,
        shopNowUrl: "https://catalog.rossifloor.net/Catalog/CatalogSearch.aspx?Value=hd+raw",

        thumbnailSrc: thumbnail_traffic_hd_raw,
        documents: [
            { title: "Bona Traffic HD Raw TDS", pdf: bona_traffic_raw_tds },
            { title: "Bona Traffic HD Raw Sell Sheet", pdf: bona_traffic_raw_sell_sheet },
        ],
        coverageLow: 350, coverageHigh: 400, dryTimeLow: 2, dryTimeHigh: 3, footTraffic: 24, fullCure: 3,
    },
    {
        name: "Bona Traffic HD Anti Slip",
        category: ProductCategory.Finishes,
        subCategory: ProductSubCategory.Waterborne,
        index: 2,
        description: "Bona Traffic HD® Anti-Slip is the best hardwood floor finish for scuff, scratch and slip resistance in commercial settings or residential such as stairs, kitchens, or homes with older adults.",
        imgSrc: bona_traffic_anti_slip,
        shopNowUrl: "https://catalog.rossifloor.net/Catalog/CatalogSearch.aspx?Value=traffic+hd%20Finish",
        thumbnailSrc: thumbnail_traffic_anti_slip,
        documents: [
            { title: "Bona Traffic HD Anti-Slip TDS", pdf: bona_traffic_anti_slip_tds },
        ],
        coverageLow: 350, coverageHigh: 400, dryTimeLow: 2, dryTimeHigh: 3, footTraffic: 24, fullCure: 3,
    },
    {
        name: "Bona Traffic GO",
        category: ProductCategory.Finishes,
        subCategory: ProductSubCategory.Waterborne,
        index: 3,
        description: "Bona Traffic GO™ presents a revolutionary approach to achieving impressive durability without compromise. With premium build and performance, Bona Traffic GO is the highest performing one-component finish within our offering.",
        imgSrc: bona_traffic_go,
        shopNowUrl: "https://catalog.rossifloor.net/Catalog/CatalogSearch.aspx?Value=traffic+go",
        thumbnailSrc: thumbnail_traffic_go,
        documents: [
            { title: "Bona Traffic Go TDS", pdf: bona_traffic_go_tds },
            { title: "Bona Traffic Go Sell Sheet", pdf: bona_traffic_go_sell_sheet },
        ],
        coverageLow: 350, coverageHigh: 400, dryTimeLow: 2, dryTimeHigh: 3, footTraffic: 24, fullCure: 3,
    },
    {
        name: "Bona Mega ONE",
        category: ProductCategory.Finishes,
        subCategory: ProductSubCategory.Waterborne,
        index: 4,
        description: "Crystal clear, one-component waterborne finish that provides superior protection. Bona Mega ONE® is an easy-to-use, fast-drying, crystal clear, water based floor finish that provides high build for superior protection.",
        imgSrc: bona_mega_one,
        shopNowUrl: "https://catalog.rossifloor.net/Catalog/CatalogSearch.aspx?Value=mega+one",
        thumbnailSrc: thumbnail_mega_one,
        documents: [
            { title: "Bona Mega One TDS", pdf: bona_mega_one_tds },
            { title: "Bona Mega One Sell Sheet", pdf: bona_mega_one_sell_sheet },
        ],
        coverageLow: 500, coverageHigh: 600, dryTimeLow: 2, dryTimeHigh: 3, footTraffic: 24, fullCure: 5,
    },
    {
        name: "Bona Mega",
        category: ProductCategory.Finishes,
        subCategory: ProductSubCategory.Waterborne,
        index: 5,
        description: "Revolutionary, oxygen-crosslinking, waterborne wood floor finish for use on heavy traffic interior residential and commercial floors.",
        imgSrc: bona_mega,
        shopNowUrl: "https://catalog.rossifloor.net/Catalog/CatalogSearch.aspx?Value=Mega",
        thumbnailSrc: thumbnail_mega,
        documents: [
            { title: "Bona Mega TDS", pdf: bona_mega_tds },
            { title: "Bona Mega Sell Sheet", pdf: bona_mega_sell_sheet },
        ],
        coverageLow: 500, coverageHigh: 600, dryTimeLow: 2, dryTimeHigh: 3, footTraffic: 24, fullCure: 7,
    },
    {
        name: "Bona Domo",
        category: ProductCategory.Finishes,
        subCategory: ProductSubCategory.Waterborne,
        index: 6,
        description: "Bona Domo™ is an easy-to-use, quick-drying, water based floor finish for residential, light traffic environments. This hardwood floor finish is a protective one-component formula with low VOCs.",
        imgSrc: bona_domo,
        shopNowUrl: "https://catalog.rossifloor.net/Catalog/CatalogSearch.aspx?Value=domo",
        thumbnailSrc: thumbnail_domo,
        documents: [
            { title: "Bona Domo TDS", pdf: bona_domo_tds },
            { title: "Bona Domo Sell Sheet", pdf: bona_domo_sell_sheet },
        ],
        coverageLow: 500, coverageHigh: 600, dryTimeLow: 2, dryTimeHigh: 3, footTraffic: 24, fullCure: 7,
    },
    {
        name: "Bona Woodline Poly",
        category: ProductCategory.Finishes,
        subCategory: ProductSubCategory.OilModified,
        index: 7,
        description: "A premium, oil-modified wood floor finish formulated for interior use on residential and commercial wood floors.",
        imgSrc: bona_woodline_poly,
        shopNowUrl: "https://catalog.rossifloor.net/catalog/Items/WFF2/Finish-and-Sealers-Oil/",
        thumbnailSrc: thumbnail_woodline,
        documents: [
            { title: "Bona Woodline Poly TDS", pdf: woodline_poly_tds },
        ],
        coverageLow: 600, coverageHigh: 700, dryTimeLow: 1.5, dryTimeHigh: 2, fullCure: 14,
    },
];

