import { Divider } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React from "react";
import { InfoGraphic } from "../components/InfoGraphic";
import { PageLayout } from "../components/PageLayout";
import { ProductHero } from "../components/ProductHero";
import { hero_equipment_wood_floor, hero_repairs, mobile_hero_repairs, tablet_hero_repairs, there_when_you_need_us } from "../images";

export const RepairsPage: React.FC<React.PropsWithChildren<{}>> = (props) => {

    return (
        <PageLayout title="Floor Equipment Repair | Rossi Floor Technologies | Pittsburgh, PA">
            <ProductHero
                imgSrc={hero_repairs}
                mobileImgSrc={mobile_hero_repairs}
                tabletImgSrc={tablet_hero_repairs}
                alt=""
                title="Equipment Repair"
                subtitle="Keep your wood floor and janitorial equipment in good condition with regular maintenance service performed by Rossi Floor Technologies."
            />
            <div className="rft_background_light">
                <div className="rft_content" style={{ padding: '48px 0px' }}>
                    <div className='rft_product_section_container'>
                        <InfoGraphic title="Reliable Equipment Repair Services" imgSrc={hero_equipment_wood_floor} color="primary" direction="reverse"
                        >
                            <p>
                                In addition to our sales and support, we offer both in-shop and on-location equipment repair services.
                                Our skilled technicians are equipped to handle a wide range of repairs, ensuring that your equipment
                                is back to working order as quickly as possible. We also provide warranty service for your peace of mind,
                                so you can trust that your investment is protected.
                            </p>
                        </InfoGraphic>
                        <br />
                        <br />
                        <InfoGraphic title="There When You Need Us" imgSrc={there_when_you_need_us} color="secondary" direction="normal"
                        >
                            <p>
                                Need to get in touch with a Rossi Floor technician? We're ready to help.
                            </p>
                            <ul>
                                <li>Access contact details for your local technician</li>
                                <li>Receive precise estimates for your technician's arrival time</li>
                                <li>Get instant advice and support for troubleshooting minor issues</li>
                            </ul>
                        </InfoGraphic>
                    </div>
                </div>

                <Divider sx={{ marginTop: "12px", marginBottom: "48px" }} />

                <div className="rft_content">
                    <div className="rft_product_section_container">
                        <Grid className="rft_content" container spacing={3}>
                            <Grid size={{ xs: 12, md: 6 }}>
                                <div className="rft_stack">
                                    <div>
                                        <h2 className="rft_section_header">Authorized Warranty Repair Center</h2>
                                        <p >
                                            When you choose Rossi Floor Technologies, you're not just buying equipment; you're gaining a partner
                                            committed to your success. Let us help you maintain an efficient environment with our reliable repair solutions and unparalleled customer support.
                                        </p>
                                        <p>We repair:</p>
                                        <ul style={{ columns: 2, WebkitColumns: 2, MozColumns: 2 }}>
                                            <li>Vacuums</li>
                                            <li>Edgers</li>
                                            <li>Sanders</li>
                                            <li>Scrubbers:</li>
                                            <ul>
                                                <li>Auto</li>
                                                <li>Walk</li>
                                                <li>Rider</li>
                                            </ul>
                                            <li>Floor machines</li>
                                            <li>Extractors</li>
                                            <li>Dryers</li>
                                            <li>Sweepers</li>
                                            <li>Burnishers</li>
                                            <li>Specialty equipment</li>
                                            <li>And more</li>
                                        </ul>
                                    </div>
                                    <div></div>
                                </div >
                            </Grid>
                            <Grid size={{ xs: 0, md: 1 }}></Grid>
                            <Grid size={{ xs: 12, md: 5 }}>
                                <div className="rft_stack">
                                    <div>
                                        <h2 className="rft_section_header">Parts and Accessories</h2>
                                        <p >
                                            Rossi Floor carries a large inventory of equipment replacement parts and accessories including:
                                        </p>
                                        <ul style={{ columns: 2, WebkitColumns: 2, MozColumns: 2, listStyleType: "none" }}>
                                            <li>Switches</li>
                                            <li>Belts</li>
                                            <li>Vac motors</li>
                                            <li>Drive motors</li>
                                            <li>Pumps</li>
                                            <li>Cords</li>
                                            <li>Hoses</li>
                                            <li>Solution fittings</li>
                                            <li>Squeegee blades</li>
                                            <li>Batteries</li>
                                            <li>Chargers</li>
                                            <li>Brushes</li>
                                            <li>Brooms</li>
                                            <li>Filters</li>
                                            <li>Carpet and upholstery</li>
                                            <li>Wands and Tools</li>
                                            <li>Vac tools</li>
                                            <li>Gaskets</li>
                                            <li>Pad holders</li>
                                            <li>Jets</li>
                                            <li>Castors</li>
                                            <li>Wheels</li>
                                            <li>Replacement brush strips</li>
                                            <li>Cloth bags</li>
                                        </ul>
                                        <p>
                                            Give us the make, model number and part number of what you're looking for and Rossi Floor can source it. Have a question about a machine or a part?  Just give us a call!
                                        </p>
                                    </div>
                                    <div></div>
                                </div >
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>

        </PageLayout>
    );
}