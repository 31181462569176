import React, { CSSProperties } from "react";

export interface ImageStackProps {
    imgSrc: string;
    title: string;
    body: string;
    imgStyle?: CSSProperties | undefined;
}

export const ImageStack: React.FC<React.PropsWithChildren<ImageStackProps>> = (props) => {
    const { imgSrc, title, body, imgStyle } = props;

    return (
        <div className="rft_img_text_stack" >
            <img src={imgSrc} alt={title} style={{ width: "100%", ...imgStyle }} />
            <div style={{ padding: "24px" }}>
                <h4>{title}</h4>
                <p>{body}</p>
            </div>
        </div >
    );
}