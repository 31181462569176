import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from "@mui/material";
import { FAQs_Abrasives } from "../static-data/faqs/Abrasives";
import Grid from "@mui/material/Grid2";
import React from "react";
import { PageLayout } from "../components/PageLayout";
import { ProductHero } from "../components/ProductHero";
import { abrasives_image_right, hero_abrasives, mobile_hero_abrasives, tablet_hero_abrasives } from "../images";
import { VideoGrid } from '../components/VideoGrid';
import { VideoCategories, VideoCategory } from '../static-data/Videos';
import { ProductTab, ProductTabs } from '../components/ProductTabs';
import { FAQComponent } from '../components/FAQ';

export const AbrasivesPage: React.FC<React.PropsWithChildren<{}>> = (props) => {

    return (
        <PageLayout title="Abrasives | Rossi Floor Technologies | Pittsburgh, PA">
            <ProductHero
                imgSrc={hero_abrasives}
                mobileImgSrc={mobile_hero_abrasives}
                tabletImgSrc={tablet_hero_abrasives}
                alt=""
                title="Abrasives"
                subtitle="Revolutionize your sanding and hardwood floor refinishing process"
            />
            <div className="rft_content" style={{ marginTop: "24px" }}>
                <ProductTabs tabs={tabs} />
            </div>
        </PageLayout>
    )
}

const videos = VideoCategories.filter(p => p.category === VideoCategory.Abrasives);

const Overview: React.FC<React.PropsWithChildren<{}>> = (props) => {
    return (
        <div className="rft_product_section_container">
            <div style={{ marginTop: "24px" }}>
                <h2 className="rft_section_header">Abrasives from Bona, Norton and Virginia Abrasives</h2>
                <p>
                    Whether you're preparing for or in the middle of a wood finishing or refinishing project, Rossi Floor Technologies has all the abrasives you'll need.
                </p>
            </div>

            <Grid className="rft_content" container spacing={3} sx={{ marginTop: "48px", marginBottom: "48px" }}>
                <Grid size={{ xs: 12, md: 5 }}>
                    <div className="rft_stack">
                        <div>
                            <h2 className="rft_section_header">Abrasive Products for Sanding Projects</h2>
                            <p>
                                Find the sanding belts, discs, rolls, sheets, and screens required for your finishing or refinishing project in our inventory:
                            </p>
                            <ul>
                                <li>Sanding belts</li>
                                <li>Floor sanding discs</li>
                                <li>Floor sanding rolls</li>
                                <li>Floor sanding sheets</li>
                                <li>Floor sanding screens</li>
                            </ul>
                        </div>
                        <div className='rft_stack_shop_now_container'>
                            <Button
                                variant="text"
                                startIcon={<ArrowForwardIcon />}
                                href="https://catalog.rossifloor.net/Catalog/Category/AB1/Abrasives/"
                                target='_blank'
                                className="rft_stack_shop_now"
                            >
                                shop now
                            </Button>
                        </div>
                    </div >
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                    <img src={abrasives_image_right} alt="abrasives" className='rft_img_center' style={{ height: "100%", objectFit: 'cover' }} />
                </Grid>
            </Grid>
        </div>
    );
};

const HowTo: React.FC<React.PropsWithChildren<{}>> = (props) => {
    return (
        <div className="rft_product_section_container">
            <VideoGrid sections={videos} />
        </div>
    );
};

const tabs: ProductTab[] = [
    {
        label: "Overview",
        component: <Overview />
    },
    {
        label: "FAQs",
        component: <FAQComponent faqs={FAQs_Abrasives} quickLinks={false} />
    },
    {
        label: "How To's",
        component: <HowTo />
    },
];