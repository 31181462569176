import { BorderRight } from "@mui/icons-material";
import { AccordionDetails, AccordionSummary, Box, styled, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { RftAccordion } from "./RftAccordion";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export interface ProductTabsProps {
    tabs: ProductTab[];
}
export interface ProductTab {
    label: string;
    component: React.ReactNode;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

interface StyledTabProps {
    label: string;
}

const StyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
))(({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(20),
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
    '&.Mui-selected': {
        // backgroundColor: '#E7F0FF',
        // borderRadius: "30px 30px 0px 0px",
    },
}));

interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        //display: 'none',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        width: '100%',
        backgroundColor: '#0C173C',
    },
});

export const ProductTabs: React.FC<React.PropsWithChildren<ProductTabsProps>> = (props) => {
    const { tabs } = props;
    const [value, setValue] = React.useState(0);
    const isMobile = useMediaQuery('(max-width: 899px)');

    const handleChange = (event: React.SyntheticEvent, newValue: any) => {
        setValue(newValue);
    };

    return isMobile ? (
        <>
            {tabs.map((tab, i) => (
                <RftAccordion key={`accordion_${i}`} defaultExpanded={false}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content-1"
                        id="panel1a-header-1"
                    >
                        <Typography color="primary" style={{ fontWeight: "bold", fontSize: "1.25em" }}>
                            {tab.label}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {tab.component}
                    </AccordionDetails>
                </RftAccordion>
            ))}
        </>
    ) :
        (
            <Box sx={{ width: "100%", marginTop: '24px' }}>
                <Box>
                    <StyledTabs
                        value={value}
                        onChange={handleChange}
                        aria-label="product sub navigation"
                    >
                        {tabs.map(({ label }, i) => (
                            <StyledTab label={label} key={`styled_tab_${i}`} />
                        ))}
                    </StyledTabs>
                </Box>
                {tabs.map(({ component }, i) => (
                    <CustomTabPanel value={value} index={i} key={i}>
                        {component}
                    </CustomTabPanel>
                ))}
            </Box>
        );
}