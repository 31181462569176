import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React from "react";
import { Product, ProductDocument } from '../static-data/types';

export interface DocumentCenterProps {
    title?: string;
    subtitle?: string;
    products: Product[];
}

export const DocumentCenter: React.FC<React.PropsWithChildren<DocumentCenterProps>> = (props) => {
    const { title, subtitle, products } = props;
    return (
        <div className="rft_product_section_container">

            <h2 className="rft_product_section_header">{title}</h2>
            <p>{subtitle}</p>

            <Grid container rowSpacing={5} columnSpacing={3} sx={{ marginBottom: "48px" }}>
                {products.map((p, i) => {
                    return (
                        <Grid size={{ xs: 12, md: 4 }} key={p.name}>
                            <DocumentLinks title={p.name} documents={p.documents} thumbnailSrc={p.thumbnailSrc} />
                        </Grid>
                    );
                })}
            </Grid>
        </div >
    );
};

export interface DocumentLinksProps {
    title: string;
    documents: ProductDocument[];
    thumbnailSrc?: string;
}

export const DocumentLinks: React.FC<React.PropsWithChildren<DocumentLinksProps>> = (props) => {
    const { title, documents, thumbnailSrc } = props;
    return (
        <div style={{ display: "flex" }}>
            <div>
                {thumbnailSrc && (
                    <img src={thumbnailSrc} alt="thumbnail" width="85px" style={{ marginTop: '24px' }} />
                )}
            </div>
            <div>
                <h2 className="rft_document_section_header" style={{ marginBottom: "12px" }}>{title}</h2>
                {documents.map((d, i) => {
                    return (
                        <div key={`document_${title}_${i}`}>
                            <Button
                                variant="text"
                                startIcon={<ArrowForwardIcon />}
                                href={d.pdf ?? "#"}
                                target='_blank'
                                className="rft_document_link"
                            >
                                {d.title}
                            </Button>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};