import { amber_seal_sell_sheet, amber_seal_tds, bona_dri_fast_sealer_tds, classic_seal_sell_sheet, classic_seal_tds, clear_seal_sell_sheet, clear_seal_tds, intense_seal_sell_sheet, intense_seal_tds, natural_seal_sell_sheet, natural_seal_tds, nordic_seal_sell_sheet, nordic_seal_tds, universal_seal_coat_tds } from "../../documents";
import { amber_seal, classic_seal, clear_seal, drifast_sealer, intense_seal, natural_seal, nordic_seal, seal_coat, thumbnail_amber_seal, thumbnail_classic_seal, thumbnail_clear_seal, thumbnail_drifast_sealer, thumbnail_intense_seal, thumbnail_natural_seal, thumbnail_nordic_seal, thumbnail_seal_coat } from "../../images";
import { Product, ProductCategory, ProductSubCategory } from "../types";

export const Products_Sealers: Product[] = [
    {
        name: "Bona NordicSeal",
        category: ProductCategory.Sealers,
        subCategory: ProductSubCategory.Waterborne,
        index: 0,
        description: "Bona NordicSeal provides a classic Scandinavian, white-washed look without adding stain or caustic bleach. Contains twice the amount of white pigment as found in NaturalSeal.",
        imgSrc: nordic_seal,
        shopNowUrl: "https://catalog.rossifloor.net/catalog/p/BK618001/Bona-NordicSeal-Sanding-Sealer-Gal/",
        thumbnailSrc: thumbnail_nordic_seal,
        documents: [
            { title: "Bona NordicSeal TDS", pdf: nordic_seal_tds },
            { title: "Bona NordicSeal Sell Sheet", pdf: nordic_seal_sell_sheet },
        ],
        coverageLow: 400, coverageHigh: 500, dryTimeLow: 2, dryTimeHigh: 3, fullCure: 7,
    },
    {
        name: "Bona NaturalSeal",
        category: ProductCategory.Sealers,
        subCategory: ProductSubCategory.Waterborne,
        index: 1,
        description: "Using a small amount of white pigment, Bona NaturalSeal creates the look and depth of pure, freshly sanded wood.",
        imgSrc: natural_seal,
        shopNowUrl: "https://catalog.rossifloor.net/catalog/p/BK1618001/Bona-NaturalSeal-Sanding-Sealer-Gal/",
        thumbnailSrc: thumbnail_natural_seal,
        documents: [
            { title: "Bona NaturalSeal TDS", pdf: natural_seal_tds },
            { title: "Bona NaturalSeal Sell Sheet", pdf: natural_seal_sell_sheet },
        ],
        coverageLow: 400, coverageHigh: 500, dryTimeLow: 2, dryTimeHigh: 3, fullCure: 7,
    },
    {
        name: "Bona ClassicSeal",
        category: ProductCategory.Sealers,
        subCategory: ProductSubCategory.Waterborne,
        index: 2,
        description: "Bona ClassicSeal is slightly opaque and blonde, giving a more neutral, natural look.",
        imgSrc: classic_seal,
        shopNowUrl: "https://catalog.rossifloor.net/catalog/p/BK1728/Bona-ClassicSeal-Wood-Floor-Sealer-Gal/",
        thumbnailSrc: thumbnail_classic_seal,
        documents: [
            { title: "Bona ClassicSeal TDS", pdf: classic_seal_tds },
            { title: "Bona ClassicSeal Sell Sheet", pdf: classic_seal_sell_sheet },
        ],
        coverageLow: 500, coverageHigh: 600, dryTimeLow: 1, dryTimeHigh: 2, fullCure: 7,
    },
    {
        name: "Bona ClearSeal",
        category: ProductCategory.Sealers,
        subCategory: ProductSubCategory.Waterborne,
        index: 3,
        description: "Bona ClearSeal is a waterborne sealer that offers easy abradability, great build characteristics and is fast drying.",
        imgSrc: clear_seal,
        shopNowUrl: "https://catalog.rossifloor.net/catalog/p/BK100618001/Bona-ClearSeal-Floor-Sealer-Gal/",
        thumbnailSrc: thumbnail_clear_seal,
        documents: [
            { title: "Bona ClearSeal TDS", pdf: clear_seal_tds },
            { title: "Bona ClearSeal Sell Sheet", pdf: clear_seal_sell_sheet },
        ],
        coverageLow: 500, coverageHigh: 600, dryTimeLow: 1, dryTimeHigh: 2, fullCure: 7,
    },
    {
        name: "Bona IntenseSeal",
        category: ProductCategory.Sealers,
        subCategory: ProductSubCategory.Waterborne,
        index: 4,
        description: "Bona IntenseSeal's unique, “wet look” pops stain colors and works with the extractive oils and resins in each species to highlight their colors on natural floors.",
        imgSrc: intense_seal,
        shopNowUrl: "https://catalog.rossifloor.net/catalog/p/BK18001/Bona-IntenseSeal-Sanding-Sealer-Gal/",
        thumbnailSrc: thumbnail_intense_seal,
        documents: [
            { title: "Bona IntenseSeal TDS", pdf: intense_seal_tds },
            { title: "Bona IntenseSeal Sell Sheet", pdf: intense_seal_sell_sheet },
        ],
        coverageLow: 450, coverageHigh: 600, dryTimeLow: 2, dryTimeHigh: 3, fullCure: 7,
    },
    {
        name: "Bona AmberSeal",
        category: ProductCategory.Sealers,
        subCategory: ProductSubCategory.Waterborne,
        index: 5,
        description: "Bona AmberSeal has a warm, rich, oil-modified undertone, but won't yellow or get darker with age. Contains an amber pigment.",
        imgSrc: amber_seal,
        shopNowUrl: "https://catalog.rossifloor.net/catalog/p/BK18003/Bona-AmberSeal-Sanding-Sealer-Gal/",
        thumbnailSrc: thumbnail_amber_seal,
        documents: [
            { title: "Bona AmberSeal TDS", pdf: amber_seal_tds },
            { title: "Bona AmberSeal Sell Sheet", pdf: amber_seal_sell_sheet },
        ],
        coverageLow: 400, coverageHigh: 500, dryTimeLow: 2, dryTimeHigh: 3, fullCure: 7,
    },
    {
        name: "Bona DriFast Sealer",
        category: ProductCategory.Sealers,
        subCategory: ProductSubCategory.OilModified,
        index: 6,
        description: "A fast drying, oil-modified sealer designed to penetrate and seal bare wood. Bona DriFast® Sealer is a quick drying, oil-modified polyurethane sanding sealer that's designed to penetrate and seal bare wood. It is compatible with all Bona stains, oil-modified and waterborne finishes.",
        imgSrc: drifast_sealer,
        shopNowUrl: "https://catalog.rossifloor.net/catalog/p/BK51001/Bona-DriFast-Sealer-Qt/",
        thumbnailSrc: thumbnail_drifast_sealer,
        documents: [
            { title: "Bona DriFast Sealer TDS", pdf: bona_dri_fast_sealer_tds },
        ],
        coverageLow: 600, coverageHigh: 700, dryTimeLow: 1.5, dryTimeHigh: 2, fullCure: 14,
    },
    {
        name: "Zinsser SealCoat",
        category: ProductCategory.Sealers,
        subCategory: ProductSubCategory.Universal,
        index: 7,
        description: "Save time when finishing porous woods by starting with Rust-Oleum® Zinsser® Bulls Eye® Sealcoat Universal Sanding Sealer. This high-building solution seals the wood to create a surface that can be easily sanded. Contributes to an ultra-smooth final finish.",
        imgSrc: seal_coat,
        shopNowUrl: "",
        thumbnailSrc: thumbnail_seal_coat,
        documents: [
            { title: "Zinsser SealCoat TDS", pdf: universal_seal_coat_tds },
        ],
        coverageLow: 400, coverageHigh: 600, dryTimeLow: 1, dryTimeHigh: 1, fullCure: 5,
    },
];

