import { createTheme, ThemeProvider } from "@mui/material";
import React, { useEffect } from 'react';
import ReactGA from "react-ga4";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
    AbrasivesPage,
    AdhesivesPage,
    ContactPage,
    FinishesPage,
    JanEquipmentPage,
    OilsPage,
    RentalsPage,
    RepairsPage,
    SealersPage,
    SportPage,
    StainsPage,
    ToolsPage,
    WoodEquipmentPage,
    WoodFillersPage
} from "./pages";
import { Page404 } from "./pages/404";
import { AboutPage } from "./pages/About";
import { CoatingsPage } from "./pages/Coatings";
import { CoverageChartPage } from "./pages/CoverageChartPage";
import { DocumentCenterPage } from "./pages/DocumentCenter";
import { EquipmentPage } from "./pages/Equipment";
import { FAQPage } from "./pages/FAQPage";
import { HomePage } from "./pages/Home";
import { HowToPage } from "./pages/HowTo";
import { ReviewsPage } from "./pages/Reviews";
import {
    urlToAbout,
    urlToAbrasives,
    urlToAdhesive,
    urlToCoatings,
    urlToContactUs,
    urlToCoverageChart,
    urlToDocumentCenter,
    urlToEquipment,
    urlToFaqs,
    urlToFillers,
    urlToFinishes,
    urlToHome,
    urlToHowTo,
    urlToJanEquipment,
    urlToOils,
    urlToRentals,
    urlToRepairs,
    urlToReviews,
    urlToSealers,
    urlToSport,
    urlToStains,
    urlToTools,
    urlToWoodEquipment
} from "./Routing";

const pageTitle = "Wood Floor Supplies | Rossi Floor Technologies | Pittsburgh, PA";
const desc = "We specialize in floor finishes, sealers, abrasives, equipment, repairs, and rentals. Contractor bulk pricing. Immediate pricing. Call for service.";
const img = process.env.PUBLIC_URL + '/yourPathHere.jpg';

//This is supposed to automatically track page views (based on setting in GA account)
//Let's find out...
const TRACKING_ID = "G-4HG62SQY5T";
ReactGA.initialize(TRACKING_ID);

function App() {
    
    return (
        <div className="rft_site">
            <Helmet>
                <title>{pageTitle}</title>
                <meta name="description" content={desc} />

                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={desc} />
                <meta name="twitter:image" content={img} />
                <meta name="twitter:card" content="summary" />

                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={desc} />
                <meta property="og:image" content={img} />
            </Helmet>
            <Routes>
                <Route index element={<HomePage />} />
                <Route path={urlToCoatings} element={<CoatingsPage />} />
                <Route path={urlToEquipment} element={<EquipmentPage />} />
                <Route path={urlToAbout} element={<AboutPage />} />
                <Route path={urlToAbrasives} element={<AbrasivesPage />} />
                <Route path={urlToAdhesive} element={<AdhesivesPage />} />
                <Route path={urlToContactUs} element={<ContactPage />} />
                <Route path={urlToFinishes} element={<FinishesPage />} />
                <Route path={urlToSealers} element={<SealersPage />} />
                <Route path={urlToHome} element={<HomePage />} />
                <Route path={urlToJanEquipment} element={<JanEquipmentPage />} />
                <Route path={urlToOils} element={<OilsPage />} />
                <Route path={urlToRentals} element={<RentalsPage />} />
                <Route path={urlToRepairs} element={<RepairsPage />} />
                <Route path={urlToReviews} element={<ReviewsPage />} />
                <Route path={urlToSport} element={<SportPage />} />
                <Route path={urlToStains} element={<StainsPage />} />
                <Route path={urlToTools} element={<ToolsPage />} />
                <Route path={urlToWoodEquipment} element={<WoodEquipmentPage />} />
                <Route path={urlToFillers} element={<WoodFillersPage />} />
                <Route path={urlToHowTo} element={<HowToPage />} />
                <Route path={urlToFaqs} element={<FAQPage />} />
                <Route path={urlToDocumentCenter} element={<DocumentCenterPage />} />
                <Route path={urlToCoverageChart} element={<CoverageChartPage />} />
                <Route path="*" element={<Page404 />} />
            </Routes>
        </div>
    );
}

const AppWrapper = () => {
    return (
        <BrowserRouter>
            <HelmetProvider>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </HelmetProvider>
        </BrowserRouter>
    );
};

export default AppWrapper;


const theme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: '#0C173C',
        },
        secondary: {
            main: '#768A76',
        },
        info: {
            main: '#0A173D',
        },
        error: {
            main: "#B90000",
        },
    },
    typography: {
        fontFamily: [
            '"Montserrat"',
        ].join(','),
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});
