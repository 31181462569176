import { Divider, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery } from "@mui/material";
import React from "react";
import { CoverageCalculator } from "./CoverageCalculator";
import { Product, ProductCategory, ProductSubCategory } from "../static-data/types";
import { Products } from "../static-data/products/Products";

export const CoverageChart: React.FC<React.PropsWithChildren<{}>> = () => {
    const waterFinishes = Products.filter(p => p.category === ProductCategory.Finishes && p.subCategory === ProductSubCategory.Waterborne);
    const oilFinishes = Products.filter(p => p.category === ProductCategory.Finishes && p.subCategory === ProductSubCategory.OilModified);
    const waterSealers = Products.filter(p => p.category === ProductCategory.Sealers && (p.subCategory === ProductSubCategory.Waterborne || p.subCategory === ProductSubCategory.Universal));
    const oilSealers = Products.filter(p => p.category === ProductCategory.Sealers && p.subCategory === ProductSubCategory.OilModified);
    const stains = Products.filter(p => p.category === ProductCategory.Stains || p.category === ProductCategory.Oils);

    return (
        <div className='rft_product_section_container'>
            <div style={{ marginBottom: "24px" }}>
                <CoverageCalculator />
            </div>

            <h2 className='rft_section_header'>Coverage Chart</h2>
            <CoverageTable sectionName="Water-Based Finish" products={waterFinishes} />
            <CoverageTable sectionName="Water-Based Sealer" products={waterSealers} />
            <CoverageTable sectionName="Stains" products={stains} />
            <CoverageTable sectionName="Oil-Modified Finish" products={oilFinishes} />
            <CoverageTable sectionName="Oil-Modified Sealer" products={oilSealers} />
        </div>
    );
};

interface CoverageTableProps {
    sectionName: string;
    products: Product[];
}

const CoverageTable: React.FC<React.PropsWithChildren<CoverageTableProps>> = (props) => {
    const { sectionName, products } = props;

    const isMobile = useMediaQuery('(max-width: 599px)');
    function getDryTimeText(low?: number, high?: number) {

        if (!low && !high) return "--";

        if (low && !high) {
            if (low === 1) return `${low} hour`;
            return `${low} hours`;
        }

        if (high && !low) {
            if (high === 1) return `${high} hour`;
            return `${high} hours`;
        }

        if (low === high) {
            if (low === 1) return `${low} hour`;
            return `${low} hours`;
        }
        return `${low}-${high} hours`;
    }

    return isMobile ? (
        <>
            {products.map((p, i) => {
                return (
                    <Table className="rft_coverage_table">
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={2} sx={{ textAlign: 'center !important' }}>{p.name}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow key={`cov_tbl_${p.category}_${i}`}>
                                <TableCell>Coverage</TableCell>
                                <TableCell>{`${p.coverageLow}-${p.coverageHigh} sf/${p.coverageUnits ?? "gal"}`}</TableCell>
                            </TableRow>
                            <TableRow key={`cov_tbl_${p.category}_${i}`}>
                                <TableCell>Dry Time</TableCell>
                                <TableCell>{getDryTimeText(p.dryTimeLow, p.dryTimeHigh)}</TableCell>
                            </TableRow>
                            {sectionName === "Water-Based Finish" && (
                                <TableRow key={`cov_tbl_${p.category}_${i}`}>
                                    <TableCell>Foot Traffic</TableCell>

                                    <TableCell>{p.footTraffic ? `${p.footTraffic} hours` : ''}</TableCell>

                                </TableRow>
                            )}
                            {sectionName !== "Stains" && (
                                <TableRow key={`cov_tbl_${p.category}_${i}`}>
                                    <TableCell>Fully Cured</TableCell>

                                    <TableCell>{p.fullCure} days</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                );
            })
            }
        </>
    ) : (
        <div>
            <Table className="rft_coverage_table">
                <TableHead>
                    <TableRow>
                        <TableCell>{sectionName}</TableCell>
                        <TableCell>Coverage</TableCell>
                        <TableCell>Dry Time</TableCell>
                        {sectionName === "Water-Based Finish" && (
                            <TableCell>Foot Traffic</TableCell>
                        )}
                        {sectionName !== "Stains" && (
                            <TableCell>Fully Cured</TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {products.map((p, i) => {
                        return (
                            <TableRow key={`cov_tbl_${p.category}_${i}`}>
                                <TableCell>{p.name}</TableCell>
                                <TableCell>{`${p.coverageLow}-${p.coverageHigh} sf/${p.coverageUnits ?? "gal"}`}</TableCell>
                                <TableCell>{getDryTimeText(p.dryTimeLow, p.dryTimeHigh)}</TableCell>
                                {sectionName === "Water-Based Finish" && (
                                    <TableCell>{p.footTraffic ? `${p.footTraffic} hours` : ''}</TableCell>
                                )}
                                {sectionName !== "Stains" && (
                                    <TableCell>{p.fullCure} days</TableCell>
                                )}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    )
};