import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React from "react";
import { CoverageChart } from "../components/CoverageChart";
import { FAQComponent } from "../components/FAQ";
import { PageLayout } from "../components/PageLayout";
import { ProductHero } from "../components/ProductHero";
import { ProductTab, ProductTabs } from "../components/ProductTabs";
import { VideoGrid } from "../components/VideoGrid";
import { useMediaImage } from "../components/useMediaImage";
import { bona_pacific_colors, hero_fillers, mobile_bona_pacific_colors, mobile_hero_fillers, tablet_bona_pacific_colors, tablet_hero_fillers } from "../images";
import { VideoCategories, VideoCategory } from "../static-data/Videos";
import { FAQs_Fillers } from "../static-data/faqs/Fillers";
import { Products_Fillers } from "../static-data/products/Fillers";
import { DocumentCenter } from '../components/DocumentCenter';

export const WoodFillersPage: React.FC<React.PropsWithChildren<{}>> = (props) => {

    return (
        <PageLayout title="Wood Fillers | Rossi Floor Technologies | Pittsburgh, PA">
            <ProductHero
                imgSrc={hero_fillers}
                mobileImgSrc={mobile_hero_fillers}
                tabletImgSrc={tablet_hero_fillers}
                alt=""
                title="Wood Floor Fillers"
                subtitle="Your wood floors will look good as new"
            />
            <div className="rft_content">
                <ProductTabs tabs={tabs} />
            </div>
        </PageLayout>
    )
}

const videos = VideoCategories.filter(p => p.category === VideoCategory.Fillers);

const Overview: React.FC<React.PropsWithChildren<{}>> = (props) => {
    
    const fillerImg = useMediaImage(bona_pacific_colors, tablet_bona_pacific_colors, mobile_bona_pacific_colors)
    return (

        <div className="rft_product_section_container" style={{ marginTop: "24px" }}>
            <Grid className="rft_content" container spacing={3} sx={{ marginTop: "48px", marginBottom: "48px" }}>
                <Grid size={{ xs: 12, md: 6 }}>
                    <div className="rft_stack">
                        <div>
                            <h2 className="rft_section_header">Wood Floor Fillers</h2>
                            <p >
                                A ready-to-use waterborne wood floor filler for spot or full-trowel filling.
                            </p>
                            <p >
                                Bona Pacific Filler® is a high solids, waterborne compound specifically designed for filling cracks,
                                holes, chips, gouges, and broken edges in hardwood floors prior to finishing. It is a ready-to-use formula,
                                for spot or full-trowel filling. It has excellent adhesion, staining, and finishing properties with minimal
                                shrinkage. For more accurate color matching, Bona Pacific Fillers can be blended together, tinted with
                                universal tints, or mixed with fine edger dust to achieve the closest match possible.
                            </p>
                        </div>
                        <div className='rft_stack_shop_now_container'>
                            <Button
                                variant="text"
                                startIcon={<ArrowForwardIcon />}
                                href="https://catalog.rossifloor.net/catalog/Items/WDFR/Wood-Fillers/"
                                target='_blank'
                                className="rft_stack_shop_now"
                            >
                                shop now
                            </Button>
                        </div>
                    </div >
                </Grid>
                <Grid size={{ xs: 0, md: 1 }}></Grid>
                <Grid size={{ xs: 12, md: 5 }} sx={{ alignContent: 'center' }}>
                    <iframe
                        width={"100%"}
                        height="350"
                        src="https://www.youtube.com/embed/RQm1Ff8JiV4"
                        title="How to Use Bona Pacific Filler® on Hardwood Floors"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen></iframe>
                    <Typography className="rft_video_title">How to Use Bona Pacific Filler</Typography>
                </Grid>
            </Grid>
            <div>
                <img src={fillerImg} alt="Bona Pacific Filler Colors" className="rft_img_center"/>
            </div>
        </div>
    )
};

const HowTo: React.FC<React.PropsWithChildren<{}>> = (props) => {
    return (
        <div className="rft_product_section_container">
            <VideoGrid sections={videos} />
        </div>
    );
};

const tabs: ProductTab[] = [
    {
        label: "Overview",
        component: <Overview />
    },
    // {
    //     label: "FAQs",
    //     component: <FAQComponent faqs={FAQs_Fillers} />
    // },
    {
        label: "Document Center",
        component: <DocumentCenter subtitle="Helpful documents for Bona wood floor fillers." products={Products_Fillers} />
    },
    {
        label: "How To's",
        component: <HowTo />
    },
    // {
    //     label: "Coverage Chart",
    //     component: <CoverageChart />
    // },
];