import { styled } from '@mui/material';
import Accordion, { AccordionProps } from '@mui/material/Accordion';
import React from 'react';

export const RftAccordion = styled((props: AccordionProps) => (
    <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    boxShadow: "none", // this styles directly apply to accordion
        // marginTop: "12px",
        overflow: "hidden",
        width: "100%",
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: "transparent",
        backgroundImage: "none",
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "0px !important",
            border: "none",
        },

        "& .MuiAccordionSummary-root": {
            backgroundColor: "transparent",
            backgroundImage: "none",
        },
        "& .MuiAccordionDetails-root": {
            padding: "0px",
            backgroundColor: "transparent",
            backgroundImage: "none",
        },
}));