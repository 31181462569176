import { Link, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useTheme } from '@mui/material/styles';
import React from "react";
import { urlToContactUs } from "../Routing";
/**
 * The site-wide header
 */
export const SiteFooter: React.FC = () => {
    const theme = useTheme();
    return (
        <div className="rft_footer" style={{ backgroundColor: theme.palette.secondary.main }}>
            <div className="rft_footer_inner">
                <Grid container sx={{ width: "100%" }}>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                        justifyContent="center"
                        alignSelf="center"
                        textAlign="center"
                        sx={{ my: { xs: "24px", md: "24px" } }}
                    >
                        <Typography variant="h1">visit us</Typography>
                        <Typography variant="body1">305 23RD STREET</Typography>
                        <Typography variant="body1">PITTSBURGH, PA 15215</Typography>
                    </Grid>
                    <Grid size={{ xs: 12 }} sx={{ display: { xs: "flex", md: "none" } }}>
                        <div style={{ backgroundColor: "rgba(255,255,255,0.2)" }} className="rft_divider"></div>
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                        justifyContent="center"
                        alignSelf="center"
                        textAlign="center"
                        sx={{
                            borderLeft: { xs: "none", md: "1px solid rgba(4,4,4,0.2)" },
                            borderRight: { xs: "none", md: "1px solid rgba(4,4,4,0.2)" },
                            my: { xs: "24px", md: "24px" }
                        }}
                    >
                        <Link href={urlToContactUs} underline="none"><Typography variant="h1">contact us</Typography></Link>
                        <Typography variant="body1">PHONE: (412) 449-0120</Typography>
                        <Typography variant="body1">FAX: (412) 449-0130</Typography>
                        <Link href="mailto:mail@rossifloor.net" underline="none"><Typography variant="body1" color="white"><u>mail@rossifloor.net</u></Typography></Link>
                    </Grid>
                    <Grid size={{ xs: 12 }} sx={{ display: { xs: "flex", md: "none" } }}>
                        <div style={{ backgroundColor: "rgba(255,255,255,0.2)" }} className="rft_divider"></div>
                    </Grid>
                    <Grid
                        size={{ xs: 12, md: 4 }}
                        justifyContent="center"
                        alignSelf="center"
                        textAlign="center"
                        sx={{ my: "24px" }}
                    >
                        <Typography variant="h1">business hours</Typography>
                        <Typography variant="body1">MON - FRI: 8:00 - 4:30</Typography>
                        <Typography variant="body1">SAT - SUN: CLOSED</Typography>
                    </Grid>
                </Grid>
            </div>
            <div style={{ backgroundColor: "rgba(255,255,255,0.2)" }} className="rft_divider"></div>
            <Typography sx={{ color: "white", fontSize: "9px", fontWeight: "700", letterSpacing: "0.2em", lineHeight: "2", mt: "12px" }}>© 2024 Rossi Floor Technologies</Typography>
        </div >
    );
};