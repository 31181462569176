import { FAQSection } from "../../components/FAQ";
import React from "react";

export const FAQs_Abrasives: FAQSection[] = [
    {
        section: "Abrasives and Sanding",
        questions: [
            {
                question: "What is the best sanding sequence for hardwood floor renovation?",
                answer:
                    <>
                        <p>The most important thing to remember in your sanding sequence is to follow the National Wood Flooring Association (NWFA)'s general rule: Never skip more than one grit of paper between cuts.</p>
                        <p>When using Bona's floor sanding sequence, blended mineral abrasives are dialed in to give you the best performance for each specific step.</p>
                    </>,
            },
            {
                question: "How do I combine Bona's modern sanding machines and high-tech abrasives?",
                answer:
                    <>
                        <p>This specific floor sanding grit sequence works for natural, stained or oiled floors that demand a scratch-free surface; while still being open enough to permit proper colorant penetration and achieve optimal adhesion for oils, stains, sealers and finishes.</p>
                        <p>Fine sanding can also be done with 80 or 120 grit Bona Diamond Abrasives and the optional Bona Steel Plates accessory. One set of Bona Diamond Abrasives lasts 4,000 – 6,000 square feet and works perfectly for your final pass on raw wood, especially when working with species like Brazilian cherry, maple, hickory, ash and pine for scratch-free results.</p>
                    </>,
            },
            {
                question: "What is an example of applying the Bona Sanding Sequence, using Bona Abrasives and Bona Sanding Machines to a flooring project?",
                answer:
                    <>
                        <ul>
                            <li>Bona Net Ceramic Abrasives Sanding Sequence :</li>
                            <ul>
                                <li>Coarse sanding: Bona Green Abrasives </li>
                                <li>Medium sanding: Bona Green Abrasives </li>
                                <li>Fine sanding: Bona Net Ceramic Abrasives </li>
                            </ul>
                            <br />
                            <li>Bona Net Alox Abrasive Sanding Sequence </li>
                            <ul>
                                <li>Coarse sanding: Bona Blue Abrasives </li>
                                <li>Medium sanding: Bona Blue Abrasives </li>
                                <li>Fine Sanding: Bona Net Alox Abrasives </li>
                            </ul>
                        </ul>
                    </>,
            },
            {
                question: "What are the key, immediate benefits of using the Bona dust containment system during floor sanding?",
                answer:
                    <>
                        <p>Immediate benefits include:</p>
                        <ul>
                            <li>Direct removal of dust through a vacuum system connection</li>
                            <li>Reduced need for prep work or clean-ups after sanding</li>
                            <li>Sealed system with multi-stage air filtration prevents dust from settling into the finish</li>
                        </ul>
                    </>,
            },
            {
                question: "How do Bona Wire Brushes enhance the natural character of wood floors?",
                answer: <p>Bona Wire Brushes are designed to get down to the grain of the wood, creating texture and beautiful wood floor effects without scratching or harming the wood fibers. This technology enhances the natural character of wood floors and allows for dramatic new looks and two-tone contrasts when combined with Bona penetrating oils, stains, and finishes.</p>,
            },
            {
                question: "What types of finishes can be achieved with the Bona wire brush system?",
                answer: <p>With the Bona wire brush system and penetrating oils, flooring professionals can create stunning two-tone contrasts or eye-catching contemporary 2-color effects. These techniques allow for the creation of textured, three-dimensional surfaces that highlight the wood's natural beauty and character.</p>,
            },
            {
                question: "What grit should I use to abrade finish?",
                answer: 
                <>
                    <p>Grit selection is going to depend on your reason for abrading. For our waterborne finishes that have just dried, a 320-grit maroon pad should be enough unless you feel you need to be a little more aggressive due to an extreme amount of grain raise and/or debris in the finish coat. To be a little more aggressive you can use one or two Bona Diamond Discs (180 or 240-grit) on the bottom of the maroon pad, or if the finish has dried overnight or longer then you could consider using the Bona MultiDisc with 1/4" intermediate pads for abrading.</p>
                    <p>There are only two reasons for abrading Bona waterborne finishes that have just been applied – for smoothness or if it has been more than 48 hours since the previous coat. For Bona Woodline Oil Base finish it's common to use a 100-grit screen or the Bona MultiDisc with 1/4" intermediate pads with Diamond Disc(s) (120 or 180-grit).</p>
                </>,
            },
        ],
    },
];