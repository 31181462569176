import React from "react"
import { PageLayout } from "../components/PageLayout"
import { VideoGrid } from "../components/VideoGrid"
import { VideoCategories } from "../static-data/Videos"

export const HowToPage: React.FC<React.PropsWithChildren<{}>> = (props) => {

    return (
        <PageLayout title="How To | Rossi Floor Technologies">
            <div className="rft_content" style={{ marginTop: "48px" }}>
                <VideoGrid sections={VideoCategories} />
            </div>
        </PageLayout>
    )
}