
import { adhesives_brochure, quantum_flow_sell_sheet, quantum_flow_tds, quantum_r851_tds, quantum_sausage_tds, quantum_vertical_tds, r540_sell_sheet, r540_tds, rollguard_sell_sheet, rollguard_tds } from "../../documents";
import { bona_flow, bona_r540, bona_r851, bona_rollguard, bona_sausages, bona_vertical, thumbnail_bona_flow, thumbnail_bona_r540, thumbnail_bona_r851, thumbnail_bona_rollguard, thumbnail_bona_sausages, thumbnail_bona_vertical } from "../../images";
import { Product, ProductCategory, ProductSubCategory } from "../types";

export const Products_Adhesives: Product[] = [
    {
        name: "Bona Quantum Flow",
        category: ProductCategory.Adhesives,
        subCategory: ProductSubCategory.Adhesives,
        index: 0,
        description: "Bona QUANTUM FLOW® is a pourable adhesive that has outstanding “green-grab” properties and helps to cover a larger surface area in less time without causing fatigue. Quick and efficient, flooring can be ready in as little as one day for furniture and foot traffic.",
        imgSrc: bona_flow,
        shopNowUrl: "https://catalog.rossifloor.net/catalog/p/BK65001/Bona-Quantum-Flow-Wood-Floor-Adhesive-4-Gal/",
        thumbnailSrc: thumbnail_bona_flow,
        documents: [
            { title: "Bona Quantum Flow Sell Sheet", pdf: quantum_flow_sell_sheet },
            { title: "Bona Quantum Flow TDS", pdf: quantum_flow_tds },
            { title: "Bona Adhesive Brochure", pdf: adhesives_brochure },
        ],
    },
    {
        name: "Bona Quantum R851",
        category: ProductCategory.Adhesives,
        subCategory: ProductSubCategory.Adhesives,
        index: 1,
        description: "Bona QUANTUM™ R851 is a silane-based, adaptable wood floor adhesive with outstanding green-grab and easy application that doesn't cause fatigue. Quick and efficient, flooring can be ready in as little as one day for furniture and foot traffic.",
        imgSrc: bona_r851,
        shopNowUrl: "https://catalog.rossifloor.net/catalog/p/BK1030US/Bona-R851-Adhesive-For-Wood-Floors-3-Gal/",
        thumbnailSrc: thumbnail_bona_r851,
        documents: [
            { title: "Bona Quantum R851 TDS", pdf: quantum_r851_tds },
            { title: "Bona Adhesive Brochure", pdf: adhesives_brochure },
        ],
    },
    {
        name: "Bona Quantum 20oz. Sausages",
        category: ProductCategory.Adhesives,
        subCategory: ProductSubCategory.Adhesives,
        index: 2,
        description: "Bona QUANTUM™ provides outstanding adhesion to a variety of surfaces, and allows for the natural movement of wood flooring. Use along the leading edge of refinished or solid plank floors, coupled with staple or nail down system.",
        imgSrc: bona_sausages,
        shopNowUrl: "https://catalog.rossifloor.net/catalog/p/BK5600US/Bona-R850T-Adhesive-For-Wood-Floors-203-oz/",
        thumbnailSrc: thumbnail_bona_sausages,
        documents: [
            { title: "Bona Quantum Sausages TDS", pdf: quantum_sausage_tds },
            { title: "Bona Adhesive Brochure", pdf: adhesives_brochure },
        ],
    },
    {
        name: "Bona VERTICAL",
        category: ProductCategory.Adhesives,
        subCategory: ProductSubCategory.Adhesives,
        index: 3,
        description: "Bona VERTICAL™ is a silane-based construction adhesive with soft elasticity and high bonding strength for rapid setting.",
        imgSrc: bona_vertical,
        shopNowUrl: "https://catalog.rossifloor.net/catalog/p/BK0300US/Bona-R880-Adhesive-104-oz-Tube/",
        thumbnailSrc: thumbnail_bona_vertical,
        documents: [
            { title: "Bona Vertical TDS", pdf: quantum_vertical_tds },
            { title: "Bona Adhesive Brochure", pdf: adhesives_brochure },
        ],
    },
    {
        name: "Bona RollGuard",
        category: ProductCategory.Adhesives,
        subCategory: ProductSubCategory.MoistureBarrier,
        index: 4,
        description: "Bona RollGuard™ - the superior, silane-based moisture barrier that sets new benchmarks in efficiency, user experience, and environmental consciousness.",
        imgSrc: bona_rollguard,
        shopNowUrl: "https://catalog.rossifloor.net/catalog/p/BKG02000USB/Bona-RollGuard-1-Component-Roll-On-Moisture-Membrane-5-L/",
        thumbnailSrc: thumbnail_bona_rollguard,
        documents: [
            { title: "Bona RollGuard TDS", pdf: rollguard_tds },
            { title: "Bona RollGuard Sell Sheet", pdf: rollguard_sell_sheet },
        ],
    },
    {
        name: "Bona R540",
        category: ProductCategory.Adhesives,
        subCategory: ProductSubCategory.MoistureBarrier,
        index: 5,
        description: "Bona® R450 is a single component, roll-on moisture barrier and primer, used to mitigate floor moisture issues such as cupping.",
        imgSrc: bona_r540,
        shopNowUrl: "https://catalog.rossifloor.net/catalog/p/BK2200US/Bona-R540-Moisture-Barrier-Primer-5-L-Jug/",
        thumbnailSrc: thumbnail_bona_r540,
        documents: [
            { title: "Bona R540 TDS", pdf: r540_tds },
            { title: "Bona R540 Sell Sheet", pdf: r540_sell_sheet },
        ],
    },
];

