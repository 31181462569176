import { FormControl, InputLabel, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Product, ProductCategory, ProductSubCategory } from "../static-data/types";
import { Products } from "../static-data/products/Products";

export interface CoverageChartSection {
    name: string;
    products: Product[];
};

const waterFinishes = Products.filter(p => p.category === ProductCategory.Finishes && p.subCategory === ProductSubCategory.Waterborne);
const oilFinishes = Products.filter(p => p.category === ProductCategory.Finishes && p.subCategory === ProductSubCategory.OilModified);
const waterSealers = Products.filter(p => p.category === ProductCategory.Sealers && (p.subCategory === ProductSubCategory.Waterborne || p.subCategory === ProductSubCategory.Universal));
const oilSealers = Products.filter(p => p.category === ProductCategory.Sealers && p.subCategory === ProductSubCategory.OilModified);
const stains = Products.filter(p => p.category === ProductCategory.Stains && p.subCategory !== ProductSubCategory.Dyes);

const groups: CoverageChartSection[] = [
    {
        name: "Water-Based Finish",
        products: waterFinishes,
    },
    {
        name: "Water-Based Sealer",
        products: waterSealers,
    },
    {
        name: "Stains",
        products: stains,
    },
    {
        name: "Oil-Modified Finish",
        products: oilFinishes,
    },
    {
        name: "Oil-Modified Sealer",
        products: oilSealers,
    },
];

function toTitleCase(str: string) {
    return str.replace(
      /\w\S*/g,
      text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
  }

export const CoverageCalculator: React.FC<React.PropsWithChildren<{}>> = () => {
    const [minGallons, setMinGallons] = useState(0);
    const [maxGallons, setMaxGallons] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState("");
    const [squareFeet, setSquareFeet] = useState(2000);
    const [coats, setCoats] = useState(2);
    const [units, setUnits] = useState("Gallon(s)");

    useEffect(() => {

        const products = groups.flatMap(g => g.products).filter(x => x.name === selectedProduct);
        if (products.length === 1) {
            const product = products[0];
            const low = product.coverageLow;
            const high = product.coverageHigh;

            const totalSqFt = squareFeet * coats;

            const min = high ? Math.ceil(totalSqFt / high) : 0;
            const max = low ? Math.ceil(totalSqFt / low) : 0;

            setUnits(`${product.coverageUnits ? `${toTitleCase(product.coverageUnits)}(s)` : "Gallon(s)"}`);

            setMinGallons(isNaN(min) ? 0 : min);
            setMaxGallons(isNaN(max) ? 0 : max);
        }
    }, [selectedProduct, squareFeet, coats]);

    return React.useMemo(() => {
        return (

            <div>
                <div style={{ marginBottom: "24px" }}>
                    <h2 className='rft_section_header'>Coverage Calculator</h2>
                    <p><b>Easily calculate how much product you need for your project by filling out the section below.</b></p>
                </div>
                <div className="rft_coverage_calc">
                    <FormControl className="rft_coverage_calc_control">
                        <InputLabel htmlFor="product-select">Select Product</InputLabel>
                        <Select
                            native
                            fullWidth
                            id="product-select"
                            variant="filled"
                            value={selectedProduct}
                            onChange={(e) => { setSelectedProduct(e.target.value); }}
                        >
                            <option aria-label="None" value="" />
                            {groups.map((g, i) => {
                                return (
                                    <optgroup label={g.name} key={`optGroup_${g.name}`}>
                                        {g.products.map((p, j) => {
                                            return (
                                                <option key={`calc_products_${j}`} value={p.name}>{p.name}</option>
                                            );
                                        })}
                                    </optgroup>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <FormControl className="rft_coverage_calc_control">
                        <TextField
                            type="number"
                            fullWidth
                            variant="filled"
                            label="Enter Square Footage"
                            value={squareFeet}
                            onChange={(e) => { setSquareFeet(parseInt(e.target.value)); }}
                        />
                    </FormControl>
                    <FormControl className="rft_coverage_calc_control">
                        <TextField
                            type="number"
                            fullWidth
                            variant="filled"
                            label="How Many Coats?"
                            value={coats}
                            onChange={(e) => { setCoats(parseInt(e.target.value)); }}
                        />
                    </FormControl>
                    <div className="rft_coverage_calc_results">
                        <div>
                            <p className="rt"><b>You will need a minimum of</b></p>
                            <p style={{ fontSize: "32px", color: "black", margin: "0px" }}><b>{minGallons}</b></p>
                            <p><b>{units}</b></p>
                        </div>
                        <div>
                            <p><b>You will need a maximum of</b></p>
                            <p style={{ fontSize: "32px", color: "black", margin: "0px" }}><b>{maxGallons}</b></p>
                            <p><b>{units}</b></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }, [coats, maxGallons, minGallons, selectedProduct, squareFeet, units])
};