import React from "react"
import { PageLayout } from "../components/PageLayout"
import { DocumentCenter } from "../components/DocumentCenter"
import { Products } from "../static-data/products/Products"
import { Products_Finishes } from "../static-data/products/Finishes"
import { Products_Stains } from "../static-data/products/Stains"
import { Products_Sealers } from "../static-data/products/Sealers"
import { Products_Adhesives } from "../static-data/products/Adhesives"
import { Products_Oils } from "../static-data/products/Oils"

export const DocumentCenterPage: React.FC<React.PropsWithChildren<{}>> = (props) => {

    return (
        <PageLayout title="Document Center | Rossi Floor Technologies">
            <div className="rft_content" style={{ paddingTop: "48px" }}>
                <DocumentCenter title="Finishes" products={Products_Finishes} />
                <DocumentCenter title="Sealers" products={Products_Sealers} />
                <DocumentCenter title="Stains" products={Products_Stains} />
                <DocumentCenter title="Oils" products={Products_Oils} />
                <DocumentCenter title="Adhesives" products={Products_Adhesives} />
            </div>
        </PageLayout >
    )
}