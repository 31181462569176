import React from 'react';
import { FAQSection } from "../../components/FAQ";

export const FAQs_Stains: FAQSection[] = [
    {
        section: "DriFast Stain",
        questions: [
            {
                question: "How long do I have to wait for Bona DriFast Stain to dry?",
                answer: <p>The dry time of the stain depends on which color you're using. Wood tones are a minimum of 2-hour dry times, 6 hours for white stain, and 12-18 hours for Ebony, Spice Brown, and Bark under ideal conditions: 60º-80º F(16º-27ºC) with 35-75% relative humidity. Keep in mind water-popped floors will increase dry times.</p>,
            },
            {
                question: "How do I fix a stain blemish, when the first coat finish is applied?",
                answer: <p>When attempting this type of repair you will need to create the same footprint to the areas around the repair, final sandpaper grits, water popping, and stain used from the same can/batch. Also the 'chasing the grain technique' will assist greatly in matching the area around the repair.</p>,
            },
            {
                question: "Can I apply Bona NordicSeal over Bona Drifast Stain - White to take some of the pink tones out of the red oak?",
                answer: <p>Yes, Bona NordicSeal will adhere over Bona DriFast Stain - White. Be sure to make samples and have the client approve the look prior to coating the floor. Review our TDS to ensure proper application. </p>,
            },
            {
                question: "Can I use Bona Sealers over Bona DriFast Stain?",
                answer:
                    <>
                        <p>It's important to note that you do not need to use a sealer when you use Bona DriFast Stain. Our stain formula will penetrate and seal the wood so it's ready for a coat of finish. However, if you do decide to use a sealer over Bona stain for coloration purposes, we do have some recommendations.</p>
                        <p>First of all, we always recommend reading through the complete product specs before application in addition to making samples for client approval before coating the floor. For white stain, we recommend Bona NordicSeal, Bona ClearSeal, or Bona NaturalSeal to enhance or keep it from changing color. For all other colors, we recommend Bona IntenseSeal or Bona AmberSeal to enhance and/or protect color. We do not recommend using Bona ClassicSeal over stain.</p>
                    </>,
            },
        ],
    },
    {
        section: "Bona Chroma",
        questions: [
            {
                question: "Will my final finishing grit influence the color?",
                answer: <p>Finer sanding will result in a lighter color, and coarser sanding will result in a darker color.</p>,
            },
            {
                question: "Is it necessary to water-pop the floor before applying Chroma?",
                answer: <p>Because Chroma is water-based, it is not necessary.  However, on some species such as maple, water-popping may help alleviate inconsistencies in the sanding process.</p>,
            },
            {
                question: "Will water-popping make Bona Chroma darker?",
                answer: <p>Yes, the color will be darker, but not as dark as it would be if using an oil-based stain.</p>,
            },
            {
                question: "Can Bona Chroma be buffed on and buffed off?",
                answer: <p>Bona recommends applying Bona Chroma with a roller and cut-in pad, removing approximately 50% of the excess liquid with a terry towel and tack tool, and then buffing the remainder in with a 3M white pad.</p>,
            },
            {
                question: "What thickness is needed when applying Bona Chroma with a cut-in pad and roller?",
                answer: <p>The amount of coloration should be similar to a thin coat of paint without puddles.  The wood grain should not be easily visible.</p>,
            },
            {
                question: "How soon can I walk on Bona Chroma after application?",
                answer: <p>The floor can be walked on immediately after buffing in color.  For best results, Bona recommends using booties or towels under your feet.</p>,
            },
            {
                question: "Will Bona Chroma cause grain raise?",
                answer:
                    <p>
                        No, the buffing process with Bona Chroma mitigates this and provides an ultra smooth surface ready for your finishing system.
                        Areas where you cannot use the buffer will have minor grain raise, but not to the same degree if you were to water-pop the floor.
                    </p>,
            },
            {
                question: "Can I add water to Bona Chroma to dilute the coloration?",
                answer: <p>No, do not add water to Bona Chroma.  Use Bona Chroma Clear to dilute the color</p>,
            },
            {
                question: "What if I get Bona Chroma on painted surfaces like baseboards or trim?",
                answer: <p>Typically, Bona Chroma will wipe off with a dampened terry cloth towel.  Some low sheen-painted finishes may require a multi-purpose surface cleaner to adequately remove the stain.</p>,
            },
            {
                question: "How long will a white pad last?",
                answer: <p>It depends on the color applied, but typically a white pad will last 150-300 sq ft per pad using both sides</p>,
            },
            {
                question: "Why do I need to add the Coloration Additive?",
                answer: <p>The Coloration Additive retains the color of Chroma when coating with a Bona finish.</p>,
            },
            {
                question: "What is the pot life once Bona Chroma is mixed with the coloration additive?",
                answer: <p>Once mixed, Chroma has a pot life of 24 hours.</p>,
            },
            {
                question: "What is the Coloration Additive Ratio?",
                answer: <p>To mix smaller amounts, use a 20:1 (5%) ratio of Chroma to Coloration Additive</p>,
            },
        ]
    },
    {
        section: "Bona RedOut",
        questions: [
            {
                question: "What's the difference between a penetrating stain like Bona DriFast® Stain vs. a reactive stain like Bona Red Out?",
                answer: <p>A reactive stain achieves its look by working in tandem with the extractive elements of the wood's chemistry, whereas a penetrating stain achieves its look by wetting into the wood's surface structure but does not need the wood's chemistry. </p>,
            },
            {
                question: "Should I water pop before using the Bona Red Out to help reduce lapping?",
                answer: <p>It is unnecessary to water pop before using Bona Red Out, and water popping may increase lapping with part</p>,
            },
            {
                question: "Do I need to water pop the floor after using the Bona Red Out before staining?  ",
                answer: <p>Water popping after Bona Red Out is not necessary to achieve a deep color unless the floor has been buffed significantly to reduce grain raise.</p>,
            },
            {
                question: "What should I do after applying Bona Red Out before applying a waterborne sealer? ",
                answer: <p>After applying Bona Red Out, the floor will have a rough texture, like that of a water-popped floor. To smooth, use a buffer with a Bona Conditioning Pad to denib and smooth the surface. Be sure to move the buffer smoothly and evenly across the floor to minimize the chance of removing any color. </p>,
            },
            {
                question: "Will my final sanding grit affect the Bona Red Out reactivity? ",
                answer: <p>Your final sanding grit will not affect the Bona Red Out reactivity; however, it may affect your coverage rate.  It is recommended to sand the floor to 120 grits.   </p>,
            },
            {
                question: "Will Red Out have an adverse reaction on metal inlays, metal transitions, gas fireplace regulators, etc.?     ",
                answer: <p>Yes, Bona Red Out will likely have an adverse reaction with metals, so it's important to protect and cover metal surfaces such as inlays, or transitions from making contact.  </p>,
            },
            {
                question: "How does this affect wood filler? Should I use white oak filler on red oak if I'm using the Bona Red Out? ",
                answer: <p>Bona Red Out does not react with wood fillers; it is recommended to do a small sample and see which wood filler works best for your project.  </p>,
            },
            {
                question: "Should I even fill the floor?  ",
                answer: <p>If a filler is required, it should be spot filling only, and trowel filling should be avoided.  </p>,
            },
            {
                question: "Can Bona Red Out be diluted with water?  ",
                answer: <p>Yes, Bona Red Out can be diluted with water for less intensity.</p>,
            },
        ],
    },
];