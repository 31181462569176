import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React from "react";
import { CoverageChart } from "../components/CoverageChart";
import { DocumentCenter } from '../components/DocumentCenter';
import { FAQComponent } from "../components/FAQ";
import { PageLayout } from "../components/PageLayout";
import { ProductHero } from "../components/ProductHero";
import { ProductSquare } from "../components/ProductSquare";
import { ProductTab, ProductTabs } from "../components/ProductTabs";
import { VideoGrid } from "../components/VideoGrid";
import { bona_sealers_homeowner_flyer } from '../documents';
import { bona_sealer_color_chart, hero_sealers, mobile_hero_sealers, tablet_hero_sealers } from "../images";
import { VideoCategories, VideoCategory } from "../static-data/Videos";
import { FAQs_Sealers } from "../static-data/faqs/Sealers";
import { Products_Sealers } from "../static-data/products/Sealers";
import { ProductCategory, ProductSubCategory } from "../static-data/types";

export const SealersPage: React.FC<React.PropsWithChildren<{}>> = (props) => {

    return (
        <PageLayout title="Wood Floor Sealers | Rossi Floor Technologies | Pittsburgh, PA">
            <ProductHero
                imgSrc={hero_sealers}
                mobileImgSrc={mobile_hero_sealers}
                tabletImgSrc={tablet_hero_sealers}
                alt=""
                title="Floor Sealers"
                subtitle="The complete system for flooring contractors"
            />
            <div className="rft_content">
                <ProductTabs tabs={tabs} />
            </div>
        </PageLayout>
    )
}

const waterSealers = Products_Sealers.filter(p => p.category === ProductCategory.Sealers && p.subCategory === ProductSubCategory.Waterborne);
const oilSealers = Products_Sealers.filter(p => p.category === ProductCategory.Sealers && p.subCategory === ProductSubCategory.OilModified);
const universalSealers = Products_Sealers.filter(p => p.category === ProductCategory.Sealers && p.subCategory === ProductSubCategory.Universal);
const videos = VideoCategories.filter(p => p.category === VideoCategory.Sealers);

const Overview: React.FC<React.PropsWithChildren<{}>> = (props) => {
    return (

        <div className="rft_product_section_container">
            <h2 className="rft_section_header">Waterborne Bona Floor Sealers</h2>
            <p style={{ marginBottom: "48px" }}>Floor sealers in general add coloration, but with Bona floor sealers, there's more beneath the surface. Choose between six color options - from light colors for the freshest floor to rich amber for an oiled look but with the benefits of a waterborne product.</p>

            <div className="rft_grid_350">
                {waterSealers.sort((a, b) => { return a.index - b.index; }).map((p) => {
                    return (
                        <ProductSquare product={p} />
                    );
                })}
            </div>


            <Grid className="rft_content" container spacing={3} sx={{ marginBottom: "48px" }}>
                <Grid size={{ xs: 12, md: 6 }}>
                    <div className="rft_stack" style={{ width: "100%" }}>
                        <div>
                            <p className="rft_content_header" style={{ textAlign: "left" }}>5 Classic Colors</p>
                            <p >Highlight your hardwood floor's true colors with Bona Waterborne Sealers</p>
                            <p >
                                Bona Waterborne Sealers are specifically formulated to develop vibrant looks of deep and natural tones on all kinds of hardwood floors.
                                That said, Bona sealers are more than just a beauty formula. They are a penetrating layer of protection that creates a long-lasting bond
                                to strengthen your floor and provide the perfect foundation for a finish.
                            </p>
                            <p >Benefits of Bona Waterborne Sealers:</p>
                            <ul><li>Seals wood, preventing finish from penetrating</li></ul>
                        </div>
                        <div>
                            <Button
                                variant="text"
                                startIcon={<ArrowForwardIcon />}
                                href={bona_sealers_homeowner_flyer}
                                target='_blank'
                                className="rft_stack_shop_now"
                            >
                                download the brochure
                            </Button>
                        </div>
                    </div >
                </Grid>
                <Grid size={{ xs: 12, md: 6 }} sx={{ alignContent: "center" }}>
                    <img src={bona_sealer_color_chart} alt="bona_sealer_color_chart" style={{ width: "100%" }} />
                </Grid>
            </Grid>

            <h2 className="rft_section_header">Oil-Modified and Universal Sealers</h2>
            <p style={{ marginBottom: "48px" }}>Fast drying, oil-modified sealers designed to penetrate and seal bare wood.</p>

            <div className="rft_grid_350">
                {oilSealers.sort((a, b) => { return a.index - b.index; }).map((p) => {
                    return (
                        <ProductSquare product={p} />
                    );
                })}
                {universalSealers.sort((a, b) => { return a.index - b.index; }).map((p) => {
                    return (
                        <ProductSquare product={p} />
                    );
                })}
                <div></div>
            </div>
        </div>
    )
};

const HowTo: React.FC<React.PropsWithChildren<{}>> = (props) => {
    return (
        <div className="rft_product_section_container">
            <VideoGrid sections={videos} />
        </div>
    );
};

const tabs: ProductTab[] = [
    {
        label: "Overview",
        component: <Overview />
    },
    {
        label: "FAQs",
        component: <FAQComponent faqs={FAQs_Sealers} quickLinks={false} />
    },
    {
        label: "Document Center",
        component: <DocumentCenter subtitle="Helpful documents for Bona wood floor sealers." products={Products_Sealers} />
    },
    {
        label: "How To's",
        component: <HowTo />
    },
    {
        label: "Coverage Chart",
        component: <CoverageChart />
    },
];