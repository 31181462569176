import React from "react";
import { FAQSection } from "../../components/FAQ";

export const FAQs_Adhesives: FAQSection[] = [
    {
        section: "",
        questions: [
            {
                question: "Is a moisture barrier necessary when installing hardwood floors?",
                answer:
                    <>
                        <p>Because subfloor conditions are usually less than ideal when installing hardwood floors, the use of a moisture barrier is usually the right choice. Especially over concrete subfloors, or in climates that are either very humid or go from one extreme to another, a moisture barrier protects against moisture-related damages such as warping and cupping.</p>
                        <p>While the necessity of a moisture barrier may depend on specific installation conditions, incorporating one can significantly benefit the long-term performance and aesthetic appeal of hardwood floors. We recommend Bona R450 – the moisture barrier/primer that mitigates vapor transmission on absorbent and non-absorbent subfloors, including Gypsum-based underlayments. Bona R540 is compatible with the entire Bona Adhesives System.</p>
                    </>,
            },
            {
                question: "What are considered suitable substrates for hardwood floors?",
                answer: <p>Suitable hardwood floor substrates include concrete, plywood, cork, particle or chipboard, stone, ceramic, terrazzo, Warmboard®, radiant heat flooring, dry above-grade gypsum underlayment and recycled rubber underlayment.</p>,
            },
            {
                question: "Do Bona wood floor adhesives offer moisture protection?",
                answer: <p>Yes. 18 pounds and 95% RH with standard MPB clip-on trowel, or ¼” x ¼” v-notch trowel. Unlimited MP with Bona QUANTUM Flow. </p>,
            },
            {
                question: "Do Bona adhesives provide freeze-thaw stability?",
                answer: <p>Yes – down to zero degrees F.</p>,
            },
            {
                question: "Can Bona® floor adhesives be used to glue to rubber and acoustical rubber mats?",
                answer: <p>Yes. Use the Bona 1000F trowel or Bona Engineered Flooring (EF) trowel. Bona Adhesives are engineered to reduce impact noise and rate higher than average in Impact Insulation Class (71 dB) and Sound Transmission Class (65 dB). </p>,
            },
            {
                question: "Can flooring adhesives fail?",
                answer:
                    <>
                        <p>Yes. Adhesive failure in hardwood flooring is a critical concern that can affect the durability, appearance, and safety of the floor. Understanding the causes and signs of adhesive failure is essential for both professionals and homeowners to ensure the longevity of hardwood floors.</p>,
                        <p><b>Causes of adhesive failure include:</b></p>
                        <ul>
                            <li>Moisture and high relative humidity (RH)</li>
                            <li>Improper surface preparation</li>
                            <li>Incorrect adhesive selection or application</li>
                            <li>Exceeding open time</li>
                            <li>Environmental conditions</li>
                        </ul>
                    </>
            },
            {
                question: "What are the signs of adhesive failure?",
                answer:
                    <ul>
                        <li>Loose or Lifting Planks: One of the most obvious signs of adhesive failure is when hardwood planks start to come loose or lift from the subfloor.</li>
                        <li>Hollow Sounds: Walking across the floor may produce hollow sounds in areas where the adhesive has failed to bond effectively.</li>
                        <li>Gapping or Cupping: Changes in wood plank alignment, such as gapping or cupping, can indicate that the adhesive has failed to keep the wood in place against environmental changes.</li>
                        <li>Visible Adhesive Residue: Improper application can result in adhesive being pushed up between the planks, visible as a residue on the surface.</li>
                        <li>Discoloration: Moisture issues leading to adhesive failure might also cause discoloration of the wood or the development of mold and mildew.</li>
                    </ul>,
            },
            {
                question: "Does the wood floor adhesive need to be back-troweled or ‘keyed in'?",
                answer: <p>No, but by back-troweling or keying in the adhesive, the moisture protection will increase to 15 lbs – one of the best ratings on the market.</p>,
            },
            {
                question: "Why am I not hitting my spread rates on the adhesive?",
                answer: <p>Trowels are measuring devices that dictate how much glue will be spread; Bona recommends holding the trowel as close to a 90-degree angle as possible to ensure the right ridges and spread rates.</p>,
            },
            {
                question: "How many coats of Bona R540 should be applied on concrete? What about plywood subfloor?",
                answer: <p>Two coats before installing LVT/LVP and only one coat needed when used with 1250G or 1500G trowel and any Bona Silane Adhesive. Two coats are required in all other installations over concrete when the above criteria is not met. Only one coat over plywood.</p>,
            },
            {
                question: "Since Bona QUANTUM® R851 has a moisture barrier in it, can I use only one coat of Bona® R540 on concrete?",
                answer: <p>Yes, only one coat is needed when used with 1250G or 1500G trowel and any Bona Silane Adhesive.</p>,
            },
        ],
    },
];