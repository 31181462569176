import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React from "react";
import { CoverageChart } from "../components/CoverageChart";
import { DocumentCenter } from '../components/DocumentCenter';
import { FAQComponent } from "../components/FAQ";
import { PageLayout } from "../components/PageLayout";
import { ProductHero } from "../components/ProductHero";
import { ProductSquare } from "../components/ProductSquare";
import { ProductTab, ProductTabs } from "../components/ProductTabs";
import { VideoGrid } from "../components/VideoGrid";
import { bona_chroma_tall, drifast_stain_colors, hero_stains, mobile_hero_stains, tablet_hero_stains, transtint_colors, varathane_stains_collection } from "../images";
import { VideoCategories, VideoCategory } from "../static-data/Videos";
import { FAQs_Stains } from "../static-data/faqs/Stains";
import { Products } from "../static-data/products/Products";
import { Products_Stains } from '../static-data/products/Stains';
import { ProductCategory } from "../static-data/types";

export const StainsPage: React.FC<React.PropsWithChildren<{}>> = (props) => {

    return (
        <PageLayout title="Wood Floor Stains | Rossi Floor Technologies | Pittsburgh, PA">
            <ProductHero
                imgSrc={hero_stains}
                mobileImgSrc={mobile_hero_stains}
                tabletImgSrc={tablet_hero_stains}
                alt=""
                title="Stains & Transtints"
                subtitle="Make floor dreams come true"
            />
            <div className="rft_content">
                <ProductTabs tabs={tabs} />
            </div>
        </PageLayout>
    )
}

const stains = Products.filter(p => p.category === ProductCategory.Stains);
const videos = VideoCategories.filter(p => p.category === VideoCategory.Stains);

const driFast = Products.filter(p => p.name === "Bona DriFast Stain")[0];
const chroma = Products.filter(p => p.name === "Bona Chroma")[0];
const redout = Products.filter(p => p.name === "Bona RedOut")[0];
const varathane = Products.filter(p => p.name === "Varathane Wood Stain")[0];
const transtint = Products.filter(p => p.name === "TransTint Liquid Dye")[0];

const Overview: React.FC<React.PropsWithChildren<{}>> = (props) => {
    return (

        <div className="rft_product_section_container">
            <div style={{ marginTop: "24px" }}>
                <h2 className="rft_section_header">New! Bona Chroma</h2>
                <p style={{ marginBottom: "48px" }}>
                    Bona Chroma® Waterborne Wood Floor Coloration is a specially formulated low VOC, low/no odor coloration product that is easy-to-use,
                    eliminates the need to water-pop the floor, and gives the wood an intense and vibrant color prior to sealer and finish being applied.
                </p>
                <Button
                    variant="text"
                    startIcon={<ArrowForwardIcon />}
                    href="https://catalog.rossifloor.net/Catalog/CatalogSearch.aspx?Value=chroma"
                    target='_blank'
                    className="rft_stack_shop_now"
                >
                    Shop Bona Chroma
                </Button>
            </div>
            <div style={{ marginTop: "24px", textAlign: 'center' }}>
                <h2 className="rft_section_header" style={{ marginBottom: "12px" }}>Make Your Own Color Mixes by Adding Bona Chroma® Clear</h2>
                <img src={bona_chroma_tall} alt="Bona Chroma - Make your own colors" width={'100%'} />
            </div>

            <div style={{ marginTop: "24px" }}>
                <h2 className="rft_section_header">Wood Stain Experts</h2>
                <p>
                    We've got everything you need to stain and protect those beautiful wood surfaces—hardwood floors and beyond—all customized
                    to your exact needs. With 39+ years of experience under our belt we're pros at making wood look its best, so you can trust
                    you're getting the full benefit of everything we've learned along the way!
                </p>
                <Grid container rowSpacing={5} columnSpacing={3} className="rft_stain_lineup">
                    {stains.filter(p => p.name !== "Bona Chroma").sort((a, b) => { return a.index - b.index; }).map((p) => {
                        return (
                            <Grid size={{ xs: 12, md: 3 }}>
                                <img src={p.imgSrc} alt={p.name} style={{ padding: "12px" }} />
                            </Grid>
                        );
                    })}
                </Grid>
            </div>

            <div style={{ marginTop: "24px" }}>
                <h2 className="rft_section_header">Wood Floor Stain</h2>
                <p>We have everything you need to stain and protect wood surfaces such as hardwood floors indoors and more according to your specifications</p>
            </div>
            <Grid container rowSpacing={5} columnSpacing={3} sx={{ marginBottom: "48px" }}>
                <Grid size={{ xs: 12, md: 4 }} sx={{ py: "48px", px: "12px" }}>
                    <ProductSquare product={driFast}/>
                </Grid>
                <Grid size={{ xs: 0, md: 1 }}></Grid>
                <Grid size={{ xs: 12, md: 7 }}>
                    <img src={drifast_stain_colors} alt="Bona DriFast Stain Color Chart" className="rft_img_center" style={{ maxHeight: "600px" }} />
                </Grid>
            </Grid>

            <Grid container rowSpacing={5} columnSpacing={3} sx={{ marginBottom: "48px" }}>
                <Grid size={{ xs: 12, md: 4 }} sx={{ py: "48px" }}>
                    <ProductSquare product={varathane} />
                </Grid>
                <Grid size={{ xs: 0, md: 1 }}></Grid>
                <Grid size={{ xs: 12, md: 7 }}>
                    <img src={varathane_stains_collection} alt="Varathan Stain Color Chart" className="rft_img_center" style={{ maxHeight: "600px" }} />
                </Grid>
            </Grid>

            <Grid container rowSpacing={5} columnSpacing={3} sx={{ marginBottom: "48px" }}>
                <Grid size={{ xs: 12, md: 4 }} >
                    <ProductSquare product={transtint} />
                </Grid>
                <Grid size={{ xs: 0, md: 1 }}></Grid>
                <Grid size={{ xs: 12, md: 7 }} sx={{ py: "24px" }}>
                    <img src={transtint_colors} alt="TransTint Color Chart" className="rft_img_center" style={{ maxHeight: "600px" }} />
                </Grid>
            </Grid>

            <Grid container rowSpacing={5} columnSpacing={3} sx={{ marginBottom: "48px" }}>
                <Grid size={{ xs: 12, md: 4 }}>
                    <ProductSquare product={redout} />
                </Grid>
                <Grid size={{ xs: 0, md: 1 }}></Grid>
                <Grid size={{ xs: 12, md: 7 }} sx={{ py: "24px" }}>
                    <iframe
                        style={{ width: "100%", minHeight: "400px" }}
                        src="https://www.youtube.com/embed/M_inN_-iVAc"
                        title="Bona RedOut - Lacing-In"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    />
                    <Typography sx={{ fontWeight: "600", letterSpacing: "0em !important" }}>Bona RedOut - Lacing-in</Typography>
                </Grid>
            </Grid>
        </div>
    )
};

const HowTo: React.FC<React.PropsWithChildren<{}>> = (props) => {
    return (
        <div className="rft_product_section_container">
            <VideoGrid sections={videos} />
        </div>
    );
};

const tabs: ProductTab[] = [
    {
        label: "Overview",
        component: <Overview />
    },
    {
        label: "FAQs",
        component: <FAQComponent faqs={FAQs_Stains} />
    },
    {
        label: "Document Center",
        component: <DocumentCenter subtitle="Helpful documents for water-borne and oil-modified stains, dyes, and coloration." products={Products_Stains} />
    },
    {
        label: "How To's",
        component: <HowTo />
    },
    {
        label: "Coverage Chart",
        component: <CoverageChart />
    },
];