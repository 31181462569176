import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from "@mui/material";
import { Divider } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React from "react";
import { ImageStack } from "../components/ImageStack";
import { MobileCarousal } from "../components/MobileCarousal";
import { PageLayout } from "../components/PageLayout";
import { ProductHero } from "../components/ProductHero";
import { bona_buffer, bona_dust_containment_system, clarke_belt_sander, clarke_edger, hero_equipment_wood_floor, machines_we_carry_bottom_right, mobile_hero_equipment_wood_floor, tablet_hero_equipment_wood_floor } from "../images";

export const WoodEquipmentPage: React.FC<React.PropsWithChildren<{}>> = (props) => {

    const items: React.ReactNode[] = [
        <ImageStack imgSrc={clarke_belt_sander} title="Belt Sanders" body="Sets new bars in cutting performance, smooth operation, and overall ease of use." />,
        <ImageStack imgSrc={bona_buffer} title="Buffers" body="Engineered for performance, convenience, durability, unequaled power, superb balance and superior dust pick-up." />,
        <ImageStack imgSrc={bona_dust_containment_system} title="Dust Containment Systems" body="This system eliminates the need to expose the collected dust to the job site when emptying the bag." />,
        <ImageStack imgSrc={clarke_edger} title="Edgers" body="Sets new bars in cutting performance, smooth operation, and overall ease of use." />
    ];

    return (
        <PageLayout title="Wood Floor Equipment | Rossi Floor Technologies | Pittsburgh, PA">
            <ProductHero
                imgSrc={hero_equipment_wood_floor}
                mobileImgSrc={mobile_hero_equipment_wood_floor}
                tabletImgSrc={tablet_hero_equipment_wood_floor}
                alt=""
                title="Wood Floor Equipment"
                subtitle="Revolutionize your sanding and hardwood floor refinishing process with the Bona Power Drive®."
            />
            <div className="rft_content">
                <div className="rft_product_section_container">
                    <div style={{ marginTop: "24px" }}>
                        <h2 className="rft_section_header">Equipment for Wood Floors</h2>
                        <p >
                            Make your wood floor finishing or refinishing project a success with the right tools from 
                            Rossi Floor Technologies! Whether you're a contractor tackling a big job or a DIYer taking 
                            on a weekend project, we've got the equipment to help you shine. Our experienced team is 
                            ready to guide you in picking the perfect equipment for your unique needs.
                        </p>
                    </div>
                </div>

                <MobileCarousal itemsPerRow={4} items={items} placeHolderImgSrc={clarke_belt_sander} />
            </div>


            <Divider sx={{ marginTop: "12px", marginBottom: "48px" }} />

            <div className="rft_content">
                <div className="rft_product_section_container">
                    <Grid className="rft_content" container spacing={3} sx={{ marginTop: "48px", marginBottom: "48px" }}>
                        <Grid size={{ xs: 12, md: 5 }}>
                            <div className="rft_stack">
                                <div>
                                    <h2 className="rft_section_header">Equipment we Carry</h2>
                                    <p >
                                        As the sole distributor of Bona products, Rossi Floor Technologies is your go-to source for 
                                        all things sanding and vacuuming. We also carry Clarke machines and offer a range of dust-containment 
                                        equipment to ensure your projects are completed with precision and cleanliness.
                                    </p>
                                    <p>Visit us for:</p>
                                    <ul>
                                        <li>Drum Sanders</li>
                                        <li>Orbital Sanders</li>
                                        <li>Rotary Sanders</li>
                                        <li>Edgers</li>
                                        <li>Dust containment</li>
                                        <li>Buffers</li>
                                    </ul>
                                </div>
                                <div className='rft_stack_shop_now_container'>
                                    <Button
                                        variant="text"
                                        startIcon={<ArrowForwardIcon />}
                                        href="https://catalog.rossifloor.net/catalog/Category/SE/Sanding-Equipment/"
                                        target='_blank'
                                        className="rft_stack_shop_now"
                                    >
                                        shop now
                                    </Button>
                                </div>
                            </div >
                        </Grid>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <img src={machines_we_carry_bottom_right} alt="abrasives" className='rft_img_center' style={{ maxHeight: "350px" }} />
                        </Grid>
                    </Grid>
                </div>
            </div>


        </PageLayout>
    )
}