
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Paper } from "@mui/material";
import React from "react";
import { cat_abrasives, cat_adhesives, cat_equipment_wood_floor, cat_fillers, cat_finishes, cat_oils, cat_sealers, cat_sport, cat_stains, cat_tools } from '../images';
import { urlToAbrasives, urlToAdhesive, urlToCoatings, urlToEquipment, urlToFillers, urlToFinishes, urlToOils, urlToSealers, urlToSport, urlToStains, urlToTools } from '../Routing';

interface CategoryCardProps {
    imgSrc: string;
    title: string;
    text: string;
    learnMoreHref: string;
}

export const CategoryCard: React.FC<React.PropsWithChildren<CategoryCardProps>> = ({ imgSrc, title, text, learnMoreHref }) => (
    <Paper elevation={20} className="rft_cat_card" >
        <img src={imgSrc} alt={title} />
        <div className="">
            <h3 className="">{title}</h3>
            <p className="">{text}</p>
        </div>
        {
            learnMoreHref && (
                <Button
                    variant="text"
                    startIcon={<ArrowForwardIcon />}
                    href={learnMoreHref}
                    className=""
                >
                    learn more
                </Button>
            )
        }
    </Paper >
)

export const CoatingsCard: React.FC<React.PropsWithChildren<{}>> = () => (
    <CategoryCard
        imgSrc={cat_finishes}
        title="Coatings"
        learnMoreHref={urlToCoatings}
        text="Whether you're looking to stain, finish or to seal your hardwood floors, Rossi Floor Technologies has an extensive selection of products to achieve your look! Find a wide range of products in stock including oils and fillers at our showroom in Pittsburgh."
    />
);

export const AbrasivesCard: React.FC<React.PropsWithChildren<{}>> = () => (
    <CategoryCard
        imgSrc={cat_abrasives}
        title="Abrasives"
        learnMoreHref={urlToAbrasives}
        text="Discover all the wood floor sanding essentials at Rossi Floor Technologies! We offer a complete range of in stock products including sanding belts, screens, rolls, discs and sheets, ensuring that you're fully equipped for your next project."
    />
);

export const AdhesivesCard: React.FC<React.PropsWithChildren<{}>> = () => (
    <CategoryCard
        imgSrc={cat_adhesives}
        title="Adhesives"
        learnMoreHref={urlToAdhesive}
        text="Bona® silane-based adhesives are an elite range of professional construction adhesive products that are the first in the industry to be GREENGUARD GOLD certified."
    />
);

export const EquipmentCard: React.FC<React.PropsWithChildren<{}>> = () => (
    <CategoryCard
        imgSrc={cat_equipment_wood_floor}
        title="Equipment"
        learnMoreHref={urlToEquipment}
        text="Discover the perfect solution for your wood floors with our comprehensive parts and equipment sales, rentals, and repair services. We have everything you need for flawless, long-lasting results!"
    />
);

export const SportCard: React.FC<React.PropsWithChildren<{}>> = () => (
    <CategoryCard
        imgSrc={cat_sport}
        title="Sport Floor Products"
        learnMoreHref={urlToSport}
        text="We carry everything you need to clean, restore, and maintain wood floors used as indoor courts for basketball, volleyball, and other indoor sports."
    />
);

export const ToolsCard: React.FC<React.PropsWithChildren<{}>> = () => (
    <CategoryCard
        imgSrc={cat_tools}
        title="Tools and Accessories"
        learnMoreHref={urlToTools}
        text="Before you start your wood floor refinishing project, visit Rossi Floor Technologies to stock up on all your tools and accessories. We carry an extensive line of brushes and rollers, knee pads, pour buckets and more."
    />
);

export const FinishesCard: React.FC<React.PropsWithChildren<{}>> = () => (
    <CategoryCard
        imgSrc={cat_finishes}
        title="Finishes"
        learnMoreHref={urlToFinishes}
        text="Choose from premier water-borne and  oil-based finishes from Bona to protect and enhance the appearance of your wood floors. We are the only local Bona distributor in the area."
    />
);

export const SealersCard: React.FC<React.PropsWithChildren<{}>> = () => (
    <CategoryCard
        imgSrc={cat_sealers}
        title="Sealers"
        learnMoreHref={urlToSealers}
        text="Floor sealers in general add coloration, but with Bona floor sealers, there's more beneath the surface."
    />
);

export const StainsCard: React.FC<React.PropsWithChildren<{}>> = () => (
    <CategoryCard
        imgSrc={cat_stains}
        title="Stains And Transtints"
        learnMoreHref={urlToStains}
        text="Are you planning to stain the dining room floor? We carry a variety of liquid dye concentrates and stains for wood. Whatever color you want, you are sure to find it in our inventory!"
    />
);

export const OilsCard: React.FC<React.PropsWithChildren<{}>> = () => (
    <CategoryCard
        imgSrc={cat_oils}
        title="Craft Oil 2k"
        learnMoreHref={urlToOils}
        text="Bona Craft Oil 2K® is a unique blend of different vegetable oils, modified for long-lasting protection of wood floors."
    />
);

export const FillersCard: React.FC<React.PropsWithChildren<{}>> = () => (
    <CategoryCard
        imgSrc={cat_fillers}
        title="Wood Fillers"
        learnMoreHref={urlToFillers}
        text="Bona Pacific Filler® is a high solids, waterborne compound specifically designed for filling cracks, holes, chips, gouges, and broken edges in hardwood floors prior to finishing."
    />
);