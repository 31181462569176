
export enum VideoCategory {
    Finishes = "Finishes",
    Sealers = "Sealers",
    Stains = "Stains & Transtints",
    Abrasives = "Abrasives",
    Sport = "Sport",
    Tools = "Tolls & Accessories",
    Fillers = "Wood Floor Fillers",
    Oils = "Wood Floor Oils",
    Adhesives = "Adhesives",
}

export interface Video {
    title: string;
    url: string;
}

export interface VideoGridSectionProps {
    category: VideoCategory;
    title: string;
    videos: Video[];
}

export const VideoCategories: VideoGridSectionProps[] = [
    {
        category: VideoCategory.Finishes,
        title: "Waterborne Finishes",
        videos: [
            { title: "Traffic Go - Application Best Practices", url: "https://www.youtube.com/embed/5FTT17iEQK0?si=v9dOw8mLlNoGOTDl" },
            { title: "Roller Application 101", url: "https://www.youtube.com/embed/HDWIlKVDBBM?si=SZjlKq3Ao-kQexyO" },
            { title: "Roller Application", url: "https://www.youtube.com/embed/KzWkpL5OWf4?si=zsjuaqhhESXHePkH" },
            { title: "Traffic Raw vs. Unfinished Wood", url: "https://www.youtube.com/embed/RcepwbWi5sg?si=4lm0Y5Ob38PHLC44" },
            { title: "T-Bar Application", url: "https://www.youtube.com/embed/xwiIuX14FI8?si=5romL5T9zdOFt16j" },
            { title: "Mega ONE", url: "https://www.youtube.com/embed/V0toCDZu2qc?si=Mwp7gNIMVP5WyTwO" },
        ]
    },
    {
        category: VideoCategory.Sealers,
        title: "Sealers",
        videos: [
            { title: "Bona® ClearSeal - Product Overview", url: "https://www.youtube.com/embed/T9-fXhD0BHo?si=Uj9O9XiGDmgz15hb" },
            { title: "Sealer Application - Pre-Preparation", url: "https://www.youtube.com/embed/uQeEvlhdSN8?si=j1iw_5_q6zSfkGYx" },
            { title: "T-Bar Application", url: "https://www.youtube.com/embed/aR8phKYxkJo?si=Z0l1DLrJ-c4LnLDv" },
            { title: "Best Application Practices 1", url: "https://www.youtube.com/embed/fXXMPMkwV_o?si=5wkypTvsn2Rl-_1L" },
            { title: "Best Application Practices 2", url: "https://www.youtube.com/embed/gV_UJdNy-v4?si=7Qsn_XvNdDprqJtm" },
        ]
    },
    {
        category: VideoCategory.Fillers,
        title: "Wood Fillers",
        videos: [
            { title: "How to Use Bona Pacific Filler® on Hardwood Floors", url: "https://www.youtube.com/embed/RQm1Ff8JiV4" },
        ]
    },
    {
        category: VideoCategory.Abrasives,
        title: "Abrasives",
        videos: [
            { title: "Bona Sand & Finish Training", url: "https://www.youtube.com/embed/AK077Zr6GQQ?si=DwadEpPOgKjx6WrS" },
            { title: "Bona PowerDrive", url: "https://www.youtube.com/embed/0a0QwYum4vI?si=CbfT8fkhz-PHtvb8" },
            { title: "Bona Diamond Abrasives", url: "https://www.youtube.com/embed/ddudXp2nNLc?si=5xnl3QOnZsVVp5aG" },
        ]
    },
    {

        category: VideoCategory.Stains,
        title: "Stains and Transtints",
        videos: [
            { title: "Bona DriFast Stain Collection", url: "https://www.youtube.com/embed/Otxs2GtP8fc?si=wLCl6kvo2-JUAz4K" },
            { title: "Buffing on Stain", url: "https://www.youtube.com/embed/jFKUzCIpq0Y?si=DYk1HYJnl_IX8E4V" },
            { title: "Bona Chroma: Product Overview", url: "https://www.youtube.com/embed/-gQOeFQayNw?si=kRS6XEaz0OyI0JOK" },
            { title: "Bona Chroma Application", url: "https://www.youtube.com/embed/eke6WFFEtr8?si=L4YJJ84Kzk688wir" },
            { title: "Bona Red Out- Instructional Video", url: "https://www.youtube.com/embed/1TWWoXLVQVY?si=xmauGzFWP-HZhUqG" },
        ]
    },
    {
        category: VideoCategory.Oils,
        title: "Craft 2k Oil",
        videos: [
        ]
    },
];