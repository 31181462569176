import React from "react";

export type Direction = "normal" | "reverse";
export type Color = "primary" | "secondary" | "info" | "light";
export interface InfoGraphicProps {
    imgSrc: string;
    title: string;
    chevron?: boolean;
    color?: Color;
    direction?: Direction;
}

export const InfoGraphic: React.FC<React.PropsWithChildren<InfoGraphicProps>> = (props) => {
    const { imgSrc, title, color = "primary", direction = "normal", chevron = true } = props;


    const bgClass = color === "secondary" ? "rft_background_secondary"
        : color === "info" ? "rft_background_info"
            : color === "light" ? "rft_background_light"
                : "rft_background_primary";

    const dirClass = direction === "reverse" ? "reverse" : "";

    return (
        <div className={`rft_content_tile ${dirClass}`} >
            <div className={`rft_content_tile_color_block ${bgClass} ${dirClass}`}>
                <h2>{title}</h2>
                {props.children}
            </div>
            <div className={`rft_content_tile_img ${dirClass} ${bgClass}`} style={{ backgroundImage: `url(${imgSrc})` }}>
                {chevron && (
                    <>
                        <div className={`rft_content_tile_chevron ${bgClass} ${dirClass}`} />
                        <div className={`rft_content_tile_chevron2 ${bgClass} ${dirClass}`} />
                    </>
                )}
            </div>
        </div >
    );
}