export enum ProductCategory {
    Finishes = "Finishes",
    Sealers = "Sealers",
    Stains = "Stains & Transtints",
    Abrasives = "Abrasives",
    Sport = "Sport",
    Tools = "Tolls & Accessories",
    Fillers = "Wood Floor Fillers",
    Oils = "Wood Floor Oils",
    Adhesives = "Adhesives",
}

export enum ProductSubCategory {
    Waterborne = "Water-Borne",
    OilModified = "Oil-Modified",
    Universal = "Universal",
    Dyes = "Dyes",
    Adhesives = "Adhesives",
    MoistureBarrier = "Moisture Barrier",
}


export interface ProductDocument {
    title: string;
    pdf: string;
}

export interface Product {
    name: string;
    category: ProductCategory;
    subCategory?: ProductSubCategory;
    index: number;
    description: string;
    imgSrc: string;
    shopNowUrl?: string;
    thumbnailSrc: string;
    documents: ProductDocument[];
    coverageLow?: number;
    coverageHigh?: number;
    coverageUnits?: string;
    dryTimeLow?: number;
    dryTimeHigh?: number;
    footTraffic?: number;
    fullCure?: number;
}