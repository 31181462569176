import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Collapse, Drawer, IconButton, Link, List, ListItem, ListItemButton, Menu, MenuItem, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import React, { useState } from "react";
import { RftButton } from "../RftButton";
import { urlToAbout, urlToAbrasives, urlToAdhesive, urlToCatalogHome, urlToContactUs, urlToCoverageChart, urlToDocumentCenter, urlToFaqs, urlToFillers, urlToFinishes, urlToHome, urlToHowTo, urlToJanEquipment, urlToOils, urlToRentals, urlToRepairs, urlToReviews, urlToSealers, urlToSport, urlToStains, urlToTools, urlToWoodEquipment } from '../Routing';
/**
 * The site-wide header
 */

const floorProducts = [
    { title: "Finishes", href: urlToFinishes },
    { title: "Sealers", href: urlToSealers },
    { title: "Stains &\nTranstints", href: urlToStains },
    { title: "Abrasives", href: urlToAbrasives },
    { title: "Sport\nFloor Products", href: urlToSport },
    { title: "Tools &\nAccsesories", href: urlToTools },
    { title: "Fillers", href: urlToFillers },
    { title: "Oils", href: urlToOils },
    { title: "Adhesives", href: urlToAdhesive },
];

const equipment = [
    { title: "Wood Floor\nEquipment", href: urlToWoodEquipment },
    { title: "Janitorial\nEquipment", href: urlToJanEquipment },
    // { title: "Repair", href: urlToRepairs },
    // { title: "Rental", href: urlToRentals },
];

const about = [
    { title: "About Us", href: urlToAbout },
    { title: "Reviews", href: urlToReviews },
];

const resources = [
    { title: "How To's", href: urlToHowTo },
    { title: "FAQs", href: urlToFaqs },
    { title: "Document Center", href: urlToDocumentCenter },
    { title: "Coverage Chart", href: urlToCoverageChart },
];

export const SiteHeader: React.FC = () => {
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

    function handleHamburgerClick() {
        setMobileMenuVisible((prevState) => !prevState);
    }

    function handleOutsideMenuClick() {
        setMobileMenuVisible(false);
    }

    return (
        <div className="rft_header_outer">
            {/* <div className="rft_header_super">
                <div className="rft_header_inner rft_header_links" style={{ marginBottom: "0" }}>
                    <a href="https://7j83za-mx.myshopify.com/">
                        <span>Online Store</span>
                    </a>
                    <a href={urlToHome} className="selected">
                        <span>RFT Home</span>
                    </a>
                </div>
            </div> */}
            <div className="rft_header_inner" style={{ paddingTop: '24px' }}>
                <Grid container spacing={1} sx={{ width: "100%" }}>
                    <Grid
                        order={{ xs: 2, md: 1 }}
                        size={{ xs: 12, md: 3, lg: 4 }}
                        className="rft_header_left"
                        display="flex"
                        justifyContent="center"
                        alignSelf="center"
                        textAlign="center"
                        sx={{
                            display: { xs: "none", md: "flex" },
                        }}
                    >
                        <p>
                            pittsburgh floor supplies
                            <br />
                            412.449.0120
                        </p>
                    </Grid>
                    <Grid
                        order={{ xs: 1, md: 2 }}
                        size={{ xs: 12, md: 6, lg: 4 }}
                        display="flex"
                        className="rft_header_center"
                        justifyContent="center"
                        sx={{ position: "relative" }}
                    >
                        <div className="rft_mobile_hmenu_btn">
                            <IconButton onClick={handleHamburgerClick} aria-label="Mobile Menu Hamburger">
                                <MenuIcon sx={{ width: "40px", height: "40px" }} />
                            </IconButton>
                        </div>
                        <div style={{ margin: "0 auto" }}>
                            <Link href={urlToHome} underline="none">
                                <h2 className="rft_header_title">Rossi Floor <br />Technologies</h2>
                            </Link>
                        </div>
                    </Grid>
                    <Grid
                        order={{ xs: 3 }}
                        size={{ xs: 12, md: 3, lg: 4 }}
                        className="rft_header_right"
                        justifyContent="center"
                        alignSelf="center"
                        sx={{
                            display: { xs: "none", md: "flex" },
                        }}
                    >
                        <RftButton href={urlToCatalogHome} target="_blank" variant="contained" sx={{ width: "230px" }}>Shop Now</RftButton>
                    </Grid>
                </Grid>
            </div>
            <div className="rft_divider"></div>
            {/* Desktop Navigation */}
            <Box sx={{
                display: { xs: 'none', md: 'flex' },
                flexDirection: "row",
                alignItems: 'stretch',
                justifyContent: 'center',
                maxWidth: "1000px",
                width: "100%",
                margin: "12px",
            }}>
                <NavItem><NavLink href={urlToHome}>Home</NavLink></NavItem>
                <NavItem><NavMenu title="Flooring Products" items={floorProducts} /></NavItem>
                <NavItem><NavMenu title="Flooring Equipment" items={equipment} /></NavItem>
                <NavItem><NavLink href={urlToRepairs}>Equipment<br />Repair</NavLink></NavItem>
                <NavItem><NavLink href={urlToRentals}>Equipment<br />Rental</NavLink></NavItem>
                <NavItem><NavMenu title="About &nbsp;" items={about} /></NavItem>
                <NavItem><NavLink href={urlToContactUs}>Contact Us</NavLink></NavItem>
                <NavItem><NavMenu title="Resources &nbsp;" items={resources} /></NavItem>
            </Box>

            {/* Mobile Navigation */}
            <MobileMenu open={mobileMenuVisible} onClose={handleOutsideMenuClick} />

        </div >
    );
};

const NavItem: React.FC<React.PropsWithChildren<{}>> = (props) => {
    return (
        <div className="rft_nav_item">
            {props.children}
        </div>
    )
}

const NavLink: React.FC<React.PropsWithChildren<{ href: string }>> = (props) => {

    return (
        <Link
            href={props.href}
            underline="none"
            sx={{
                textDecoration: "none",
                borderRadius: "0px",
                display: "block",
                height: "100%",
                width: "100%",
            }}>
            <Typography sx={{
                fontSize: "12px",
                textTransform: "uppercase",
                textDecoration: "none",
                letterSpacing: "0.1em",
            }}>{props.children}</Typography>
        </Link >
    )
}

const SubNavLink: React.FC<React.PropsWithChildren<{ href: string }>> = (props) => {

    return (
        <Link
            href={props.href}
            underline="none"
            sx={{
                textDecoration: "none",
                borderRadius: "0px",
                display: "block",
                height: "100%",
                width: "100%",
                padding: "8px 16px"
            }}>
            <Typography sx={{
                fontSize: "12px",
                textTransform: "uppercase",
                textDecoration: "none",
                letterSpacing: "0.1em",
            }}>{props.children}</Typography>
        </Link >
    )
}

const NavMenu: React.FC<React.PropsWithChildren<NavMenuProps>> = (props) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Link
                component="button"
                underline="none"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                    fontSize: "12px",
                    mx: "auto",
                    textTransform: "uppercase",
                    textDecoration: "none",
                    textAlign: "center",
                    letterSpacing: "0.1em",
                    lineHeight: "1.5",
                    borderRadius: "0px",
                    display: "block",
                    height: "100%",
                    width: "100%",
                }}>
                <>
                    <span style={{ float: "right" }}>{open ? <ExpandLess sx={{ stroke: "#fff", strokeWidth: 1 }} /> : <ExpandMore sx={{ stroke: "#fff", strokeWidth: 1 }} />}</span>
                    <div>
                        {props.title
                            .split(' ')
                            .map((t, i) => {
                                return (
                                    <span key={`nav_menu_${t}_${i}`}>
                                        {t} <br />
                                    </span>
                                );
                            })}
                    </div>
                </>
            </Link>
            <Menu
                id="nav-menu"
                // elevation={0}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className="rft_product_menu"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: 0,
                    },
                }}
            >
                {props.items.map((item, i) => {
                    return (
                        <MenuItem key={`nav_link_${item.title}_${i}`} sx={{ padding: "0px", textAlign: "center" }}>
                            <SubNavLink href={item.href}>
                                {item.title
                                    .split('\n')
                                    .map((t) => {
                                        return (
                                            <span key={`nav_menu_link_${t}_${i}`}>
                                                {t} <br />
                                            </span>
                                        );
                                    })}
                            </SubNavLink>
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
}


const MobileNavItem: React.FC<React.PropsWithChildren<{ href: string }>> = (props) => {

    return (
        <Link
            underline="none"
            href={props.href}
            sx={{
                display: "block",
                fontSize: "14px",
                textTransform: "uppercase",
                fontFamily: "Montserrat",
                textDecoration: "none",
                color: "white"
            }}>
            {props.children}
        </Link>
    )
}

interface iNavItem {
    title: string;
    href: string;
};

interface NavMenuProps {
    title: string;
    items: iNavItem[];
}

interface MobileMenuProps {
    open: boolean;
    onClose?(): void;
}

const MobileMenu: React.FC<React.PropsWithChildren<MobileMenuProps>> = (props) => {
    const container = window !== undefined ? () => window.document.body : undefined;
    const [productListOpen, setProductListOpen] = useState(false);
    const [equipmentListOpen, setEquipmentListOpen] = useState(false);
    const [aboutOpen, setAboutOpen] = useState(false);
    const [resourcesOpen, setResourcesOpen] = useState(false);

    function toggleProductList() {
        setProductListOpen((prevState) => !prevState);
    }

    function toggleEquipmentList() {
        setEquipmentListOpen((prevState) => !prevState);
    }

    function toggleAbout() {
        setAboutOpen((prevState) => !prevState);
    }

    function toggleResources() {
        setResourcesOpen((prevState) => !prevState);
    }

    return (
        <Drawer
            container={container}
            variant="temporary"
            open={props.open}
            onClose={props.onClose}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                display: { xs: 'block', md: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: { xs: "75%", sm: "50%" }, backgroundColor: "#0C173C" },
            }}
        >
            <Box>
                <Box onClick={props.onClose} style={{ textAlign: "right", padding: "12px 12px" }}>
                    <IconButton onClick={props.onClose}>
                        <CloseIcon sx={{ width: "40px", height: "40px" }} htmlColor="white" />
                    </IconButton>
                </Box>
                <List>
                    <ListItem key="mobile_home">
                        <ListItemButton sx={{ textAlign: 'left' }}>
                            <MobileNavItem href={urlToHome}>Home</MobileNavItem>
                        </ListItemButton>
                    </ListItem>
                    <ListItem key="mobile_products" onClick={toggleProductList}>
                        <ListItemButton sx={{ textAlign: 'left', display: "block" }}>
                            <Typography
                                color="primary"
                                sx={{
                                    display: "block",
                                    float: "left",
                                    fontSize: "14px",
                                    textTransform: "uppercase",
                                    fontFamily: "Montserrat",
                                    textDecoration: "none",
                                    color: "white"
                                }}
                            >
                                FLOORING PRODUCTS
                            </Typography>
                            <span style={{ float: "right" }}>{productListOpen ? <ExpandLess htmlColor='white' /> : <ExpandMore htmlColor='white' />}</span>
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={productListOpen} timeout="auto" unmountOnExit>
                        <List component="div">
                            {floorProducts.map((p, i) => {
                                return (
                                    <ListItem key={`mobile_products_${i}`}>
                                        <ListItemButton sx={{ pl: 8 }}>
                                            <MobileNavItem href={p.href}>{p.title}</MobileNavItem>
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Collapse>
                    <ListItem key="mobile_equipment" onClick={toggleEquipmentList}>
                        <ListItemButton sx={{ textAlign: 'left', display: "block" }}>
                            <Typography
                                color="primary"
                                sx={{
                                    display: "block",
                                    float: "left",
                                    fontSize: "14px",
                                    textTransform: "uppercase",
                                    fontFamily: "Montserrat",
                                    textDecoration: "none",
                                    color: "white"
                                }}
                            >
                                Flooring Equipment
                            </Typography>
                            <span style={{ float: "right" }}>{equipmentListOpen ? <ExpandLess htmlColor='white' /> : <ExpandMore htmlColor='white' />}</span>
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={equipmentListOpen} timeout="auto" unmountOnExit>
                        <List component="div">
                            {equipment.map((p, i) => {
                                return (
                                    <ListItem key={`mobile_equipment_${i}`}>
                                        <ListItemButton sx={{ pl: 8 }}>
                                            <MobileNavItem href={p.href}>{p.title}</MobileNavItem>
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Collapse>
                    <ListItem key="mobile_repairs">
                        <ListItemButton sx={{ textAlign: 'left' }}>
                            <MobileNavItem href={urlToRepairs}>Equipment Repair</MobileNavItem>
                        </ListItemButton>
                    </ListItem>
                    <ListItem key="mobile_rentals">
                        <ListItemButton sx={{ textAlign: 'left' }}>
                            <MobileNavItem href={urlToRentals}>Equipment Rental</MobileNavItem>
                        </ListItemButton>
                    </ListItem>
                    <ListItem key="mobile_about" onClick={toggleAbout}>
                        <ListItemButton sx={{ textAlign: 'left', display: "block" }}>
                            <Typography
                                color="primary"
                                sx={{
                                    display: "block",
                                    float: "left",
                                    fontSize: "14px",
                                    textTransform: "uppercase",
                                    fontFamily: "Montserrat",
                                    textDecoration: "none",
                                    color: "white"
                                }}
                            >
                                About
                            </Typography>
                            <span style={{ float: "right" }}>{aboutOpen ? <ExpandLess htmlColor='white' /> : <ExpandMore htmlColor='white' />}</span>
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={aboutOpen} timeout="auto" unmountOnExit>
                        <List component="div">
                            {about.map((p, i) => {
                                return (
                                    <ListItem key={`mobile_aout_${i}`}>
                                        <ListItemButton sx={{ pl: 8 }}>
                                            <MobileNavItem href={p.href}>{p.title}</MobileNavItem>
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Collapse>

                    <ListItem key="mobile_contact">
                        <ListItemButton sx={{ textAlign: 'left' }}>
                            <MobileNavItem href={urlToContactUs}>Contact Us</MobileNavItem>
                        </ListItemButton>
                    </ListItem>
                    <ListItem key="mobile_resources" onClick={toggleResources}>
                        <ListItemButton sx={{ textAlign: 'left', display: "block" }}>
                            <Typography
                                color="primary"
                                sx={{
                                    display: "block",
                                    float: "left",
                                    fontSize: "14px",
                                    textTransform: "uppercase",
                                    fontFamily: "Montserrat",
                                    textDecoration: "none",
                                    color: "white"
                                }}
                            >
                                Resources
                            </Typography>
                            <span style={{ float: "right" }}>{aboutOpen ? <ExpandLess htmlColor='white' /> : <ExpandMore htmlColor='white' />}</span>
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={resourcesOpen} timeout="auto" unmountOnExit>
                        <List component="div">
                            {resources.map((p, i) => {
                                return (
                                    <ListItem key={`mobile_resources_${i}`}>
                                        <ListItemButton sx={{ pl: 8 }}>
                                            <MobileNavItem href={p.href}>{p.title}</MobileNavItem>
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Collapse>
                    <ListItem key="mobile_online_store">
                        <ListItemButton sx={{ textAlign: 'left' }}>
                            <MobileNavItem href={urlToCatalogHome}>Online Store</MobileNavItem>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </Drawer>
    );
}