import React from "react";
import { CategoryCard } from '../components/CategoryCard';
import { InfoGraphic } from "../components/InfoGraphic";
import { PageLayout } from "../components/PageLayout";
import { ProductHero } from '../components/ProductHero';
import {
    cat_equipment_janitorial,
    cat_equipment_wood_floor,
    cat_rentals,
    cat_repairs,
    hero_equipment_wood_floor,
    mobile_hero_equipment_wood_floor,
    tablet_hero_equipment_wood_floor
} from "../images";
import { urlToJanEquipment, urlToRentals, urlToRepairs, urlToWoodEquipment } from "../Routing";

export const EquipmentPage: React.FC<React.PropsWithChildren<{}>> = (props) => {

    return (
        <PageLayout title="Floor Equipment | Rossi Floor Technologies | Pittsburgh, PA">
            <ProductHero
                imgSrc={hero_equipment_wood_floor}
                mobileImgSrc={mobile_hero_equipment_wood_floor}
                tabletImgSrc={tablet_hero_equipment_wood_floor}
                alt=""
                title="Floor Equipiment"
                subtitle="Revitalize your space with our expert wood floor equipment and repair services, ensuring your floors look stunning and last for years to come!"
            />
            <div className="rft_content" style={{ marginTop: '48px', marginBottom: '48px' }}>
                <div className='rft_product_section_container'>
                    <InfoGraphic title="Your One Stop Shop" imgSrc={cat_rentals}
                    >
                        <p>Discover the perfect solution for your wood floors with our comprehensive sales, rentals, and repair services, ensuring you have everything you need for flawless, long-lasting results!</p>
                    </InfoGraphic>
                </div>
            </div>


            <div className="rft_background_light">
                <div className="rft_content">
                    <div className="rft_product_section_container">
                        <div className="rft_cat_card_container" style={{ margin: "24px 0px"}}>
                            {/* Wood Floor Equipment */}
                            <CategoryCard
                                imgSrc={cat_equipment_wood_floor}
                                title="Wood Floor Equipment"
                                learnMoreHref={urlToWoodEquipment}
                                text="Make us your trusted source for quality sanding equipment for wood floors. We offer brands such as Clarke-American and Bona. We also sell vacuums with dust containment capabilities."
                            />



                            {/* Janitorial Equipment */}
                            <CategoryCard
                                imgSrc={cat_equipment_janitorial}
                                title="Janitorial Cleaning Equipment"
                                learnMoreHref={urlToJanEquipment}
                                text="Come to us for janitorial cleaning equipment such as scrubbers, floor machines, burnishers, extractors, dryers, sweepers, and vacuums for commercial and residential use."
                            />

                            {/* Janitorial Products */}
                            <CategoryCard
                                imgSrc={cat_rentals}
                                title="Equipment Rentals"
                                learnMoreHref={urlToRentals}
                                text="We rent out edgers, marble polishers, sweepers, wet/dry vacs, battery chargers, extractors, propane and electric burnishers, belt sanders, and auto, walk-behind, and rider scrubbers."
                            />

                            {/* Eqipment Repair */}
                            <CategoryCard
                                imgSrc={cat_repairs}
                                title="Equipment Repair"
                                learnMoreHref={urlToRepairs}
                                text="Our facility is an authorized warranty repair service center for floor machines, dryers, scrubbers, burnishers, extractors, sweepers, vacuums, and other specialty equipment for the wood floor and janitorial industry."
                            />

                        </div>
                    </div>
                </div >
            </div>
        </PageLayout >
    )
}