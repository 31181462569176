import React from "react";
import { FAQSection } from "../../components/FAQ";

export const FAQs_Oils: FAQSection[] = [
    {
        section: "",
        questions: [
            {
                question: "What are the key benefits of Bona Craft Oil 2K® penetrating oil finish?",
                answer:
                    <>
                        <p>Bona Craft Oil 2K is a premium penetrating oil finish designed for long-lasting protection, giving wood floors an enhanced natural appearance. This unique blend of vegetable oils, modified for durability, augments the inherent beauty and color of various wood species and delivers a stunning ultra-matte finish. Its formulation allows for straightforward spot repairs and color blending customization, making it a versatile choice for professionals. </p>
                        <p><b>Key benefits include:</b></p>
                        <ul>
                            <li>Rapid Drying Time: With an impressive drying time—Neutral shades in 8 hours and colors in 12 hours—it facilitates speedier project completion.</li>
                            <li>Environmental and Health Safety: Boasts extremely low VOCs (25 g/L) and is GREENGUARD Gold Certified for Indoor Air Quality, emphasizing its commitment to environmental responsibility and indoor health safety.</li>
                            <li>Customizable and Blendable Colors: Available in nine base colors that can be blended for custom shades, offering unparalleled creativity in design.</li>
                            <li>High Saturation for Natural Look: Delivers high saturation for an enhanced natural look and feel with an extremely matte finish, accentuating the wood's organic beauty.</li>
                            <li>Ease of Repair and Enhanced Durability: Simplifies spot repairs while offering the option to increase durability by topcoating with Bona Traffic HD family of products.</li>
                        </ul>
                    </>,
            },
            {
                question: "Do I have to apply Bona Traffic HD over Bona Craft Oil 2K?",
                answer: <p>No, in situations where an additional layer of protection is desired, Bona Craft Oil 2K can be top-coated with Bona Traffic HD Anti-Slip, Bona Traffic HD RAW, or Bona Traffic GO, but it is not necessary.</p>,
            },
        ],
    },
];