import { craft_oil_contractor_brochure, craft_oil_tds, pacific_filler_sell_sheet, pacific_filler_tds } from "../../documents";
import { bona_craft_oil, thumbnail_bona_craft_oil } from "../../images";
import { Product, ProductCategory } from "../types";

export const Products_Oils: Product[] = [
    {
        name: "Bona Craft Oil 2K",
        category: ProductCategory.Oils,
        index: 0,
        description: "Bona Craft Oil 2K® is a unique blend of different vegetable oils, modified for long-lasting protection of wood floors. The exclusive blend enhances the natural and organic look and color of a wood species with an extremely matte finish to add a warm and rustic feel to a floor.",
        imgSrc: bona_craft_oil,
        shopNowUrl: "https://catalog.rossifloor.net/catalog/Items/CFTOIL/Craft-Oil-2K/",
        thumbnailSrc: thumbnail_bona_craft_oil,
        documents: [
            { title: "Bona Craft Oil 2K Brochure", pdf: craft_oil_contractor_brochure },
            { title: "Bona Craft Oil 2K TDS", pdf: craft_oil_tds },
        ],
        coverageUnits: "container", coverageLow: 600, coverageHigh: 800, dryTimeLow: 8, dryTimeHigh: 12, footTraffic: 24, fullCure: 5
    },
];

