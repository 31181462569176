import React from "react";
import { FAQSection } from "../../components/FAQ";

export const FAQs_Finishes: FAQSection[] = [
    {
        section: "",
        questions: [
            {
                question: "Can you apply Bona Traffic HD on bare wood without using a sealer?",
                answer: <>
                            <p>We do not recommend applying any of our finishes on bare wood for these reasons:</p>
                            <ul>
                                <li>Due to Bona Finishes' excellent adhesion properties, there is a chance that you could end up gluing the wood planks together (referred to as “side-bonding”). The result is that smaller seasonal gaps look larger and could cause breakage at the seams (splintering or fracturing the plank).</li>
                                <li>Sealers are designed to provide a stage or initial build coat before applying finishes. This allows the finish to sit above the wood surface, providing long-lasting protection.</li>
                            </ul>
                        </>
            },
            {
                question: "Can Bona Traffic HD be applied over other Bona Waterborne Finishes?",
                answer: <p>From a "Systems" standpoint, Bona recommends using the same finish throughout the finishing process. However, if a decision is made to change the finish to upgrade to a higher-performing topcoat, we recommend waiting overnight, lightly abrade, and then tack before applying. If this is being done more than a week later, you should abide by the “recoat” rules (good, better, best). If you are applying Bona Traffic HD over a different manufacturer's product, we suggest you consult that manufacturer for compatibility assurance.</p>,
            },
            {
                question: "How long should I wait between finish coats before applying another coat?",
                answer: <p>Bona waterborne products dry in 2-3 hours under ideal conditions. If you are applying the same product then it will be okay to do so once dried. If you are switching to a different product (finishes) then it is recommended to wait overnight, lightly abrade, vacuum then tack before applying the next Bona finish.</p>,
            },
            {
                question: "Will Bona Traffic HD prevent dog scratches or furniture scratches?",
                answer: <p>Bona Traffic HD is the most durable waterborne urethane finish on the market to protect against wear and has excellent scratch and scuff resistance. However, no finishes on the market are 100% scratch-proof. Hence, we emphasize preventative maintenance and floor care. Customers can make simple adjustments such as taking shoes off at the door, putting felt pads under the feet of furniture and appliances, and keeping dog or cat nails neatly trimmed. These strategies, along with dusting and cleaning wood floors with a Bona Microfiber Mop and Bona Hardwood Floor Cleaner are among the best ways to keep floors looking beautiful for years to come.</p>,
            },
            {
                question: "Does Bona Traffic HD Anti-Slip dry rough or gritty?",
                answer: <p>Bona Traffic Anti-Slip does not feel rough or gritty - it should have the same smooth feel as our other finishes. Bona Anti-Slip has a little more grab to it, which is more noticeable in socks. It's the ideal choice for stairs, nurseries, kitchens, and similar locations.</p>,
            },
            {
                question: "Can Bona Finishes be applied over Minwax or other competitor stains?",
                answer: <p>Some contractors have success, and some do not. Bona cannot guarantee success with this system as we do not test for compatibility with other manufacturer's products. We recommend using Bona DriFast Stain with Bona Waterborne Finish.</p>,
            },
            {
                question: "After I apply Bona Traffic HD it looks milky. Will this look go away after it is dry?",
                answer: <p>Bona Traffic HD does have a milky appearance and will dry clear unless there are other flooring issues, such as moisture, which could interfere with the drying process.</p>,
            },
            {
                question: "Why is my floor finish sheen uneven and streaky?",
                answer: <p>When it comes to sheen, proper mixing (whether shaking waterborne or stirring oil-modified) dictates the sheen. You cannot over-shake or over-stir Bona finishes, but not shaking or stirring enough can throw the sheen off.</p>,
            },
            {
                question: "Why is the finish peeling?",
                answer: <p>Finish peeling can occur when you have poor adhesion, compatibility, or moisture issues. Poor adhesion can come from the sanding sequence. Compatibility issues can happen when using a mixed-system without proper testing. Moisture problems such as coating over stain that has not dried will cause peeling. Many variables will contribute to finish peeling.</p>,
            },
            {
                question: "Do I have to apply Bona Traffic HD over Bona Craft Oil 2K?",
                answer: <p>No, in situations where an additional layer of protection is desired, Bona Craft Oil 2K can be top-coated with Bona Traffic HD Anti-Slip, Bona Traffic HD RAW, or Bona Traffic GO, but it is not necessary.</p>,
            },
        ],
    },
];