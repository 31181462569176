import { Product } from "../types";
import { Products_Finishes } from "./Finishes";
import { Products_Sealers } from "./Sealers";
import { Products_Stains } from "./Stains";
import { Products_Sport } from "./Sport";
import { Products_Fillers } from "./Fillers";
import { Products_Oils } from "./Oils";
import { Products_Adhesives } from "./Adhesives";


export const Products: Product[] = 
    Products_Finishes
        .concat(Products_Sealers)
        .concat(Products_Stains)
        .concat(Products_Sport)
        .concat(Products_Fillers)
        .concat(Products_Oils)
        .concat(Products_Adhesives);