import React from 'react';
import { FAQSection } from "../../components/FAQ";
import { Link } from '@mui/material';

export const FAQs_General: FAQSection[] = [
    {
        section: "",
        questions: [
            {
                question: "Can I visit your showroom?",
                answer: <p>Yes!  We have a showroom, open to the public from 8am-4:30pm Monday through Friday.  Our friendly and experienced staff can help guide you to the right product.</p>,
            },
            {
                question: "How can I buy from Rossi Floor Technologies?",
                answer: <p>You can shop in person at our Pittsburgh showroom located at 305 23rd Street, Pittsburgh, PA from 8am-4:30pm Monday-Friday, or online at
                    &nbsp;<Link href="https://catalog.rossifloor.net/catalog/" target="_blank">https://catalog.rossifloor.net/catalog</Link>. You can also place orders over the phone by calling 412.449.0120.  We take all major credit cards.  We ship products out via UPS.</p>,
            },
            {
                question: "Can you recommend a flooring contractor?",
                answer: <p>Give us a call! We'll need a few details but then we can provide you with multiple contractors who are best suited for your job.</p>,
            },
            {
                question: "Do you offer machine repairs?  What about machine rentals?",
                answer: <p>Whether you need a drive blade replaced on your Powernail nailer or a complete overhaul of your Bona sander motor, Rossi Floor Technologies has you covered. We offer a fully insured service center for all flooring equipment repairs and maintenance.  And, while your machine is being repaired, we offer a full line of wood floor and janitorial equipment rentals.</p>,
            },
        ],
    },
];