
import { Box } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Grid2";
import { CoverageChart } from "../components/CoverageChart";
import { ProductSquare } from "../components/ProductSquare";
import { DocumentCenter } from "../components/DocumentCenter";
import { FAQComponent } from "../components/FAQ";
import { ImageStack } from "../components/ImageStack";
import { MobileCarousal } from "../components/MobileCarousal";
import { PageLayout } from "../components/PageLayout";
import { ProductHero } from "../components/ProductHero";
import { ProductTab, ProductTabs } from "../components/ProductTabs";
import { VideoGrid } from "../components/VideoGrid";
import { craft_oil_air, craft_oil_ash, craft_oil_blend_chart, craft_oil_charcoal, craft_oil_clay, craft_oil_frost, craft_oil_neutral, craft_oil_pebble, craft_oil_sand, hero_oils, mobile_hero_oils, tablet_hero_oils } from "../images";
import { VideoCategories, VideoCategory } from "../static-data/Videos";
import { FAQs_Oils } from "../static-data/faqs/Oils";
import { Products_Oils } from "../static-data/products/Oils";
import { Products } from "../static-data/products/Products";

export const OilsPage: React.FC<React.PropsWithChildren<{}>> = (props) => {
    return (
        <PageLayout title="Wood Floor Oils | Rossi Floor Technologies | Pittsburgh, PA">
            <ProductHero
                imgSrc={hero_oils}
                mobileImgSrc={mobile_hero_oils}
                tabletImgSrc={tablet_hero_oils}
                alt=""
                title="Wood Floor Oils"
                subtitle="Stand out against the competition by providing a unique offering from the latest color trends!"
            />
            <div className="rft_content">
                <ProductTabs tabs={tabs} />
            </div>
        </PageLayout>
    )
}

const videos = VideoCategories.filter(p => p.category === VideoCategory.Oils);
const craftOil = Products.filter(p => p.name === "Bona DriFast Stain")[0];

const Overview: React.FC<React.PropsWithChildren<{}>> = (props) => {
    const colorsRow1: React.ReactNode[] = [
        <ImageStack key="mbl_car_1" imgSrc={craft_oil_sand} title="Sand" body="Gentle complements of warm brown and cool pearl invite a sense of ease." imgStyle={{ borderRadius: "20px" }} />,
        <ImageStack key="mbl_car_2" imgSrc={craft_oil_pebble} title="Pebble" body="A light, blissful brown with easy shades of grey." imgStyle={{ borderRadius: "20px" }} />,
        <ImageStack key="mbl_car_3" imgSrc={craft_oil_air} title="Air" body="An earthly essential for a comfortable space." imgStyle={{ borderRadius: "20px" }} />,
        <ImageStack key="mbl_car_4" imgSrc={craft_oil_frost} title="Frost" body="A pleasing neutral with hints of warm white." imgStyle={{ borderRadius: "20px" }} />,
    ];

    const colorsRow2: React.ReactNode[] = [
        <ImageStack key="mbl_car_5" imgSrc={craft_oil_ash} title="Ash" body="Light and cool. Enhances deep grain with hints of stone and sky." imgStyle={{ borderRadius: "20px" }} />,
        <ImageStack key="mbl_car_6" imgSrc={craft_oil_neutral} title="Neutral" body="Widely appealing, fresh and clean." imgStyle={{ borderRadius: "20px" }} />,
        <ImageStack key="mbl_car_7" imgSrc={craft_oil_clay} title="Clay" body="Medium brown that enriches darker grain patterns" imgStyle={{ borderRadius: "20px" }} />,
        <ImageStack key="mbl_car_8" imgSrc={craft_oil_charcoal} title="Charcoal" body="The darkest color for urban sophistication." imgStyle={{ borderRadius: "20px" }} />,
    ];

    return (
        <div className="rft_product_section_container">
            <div style={{ marginTop: "24px" }}>
                <h2 className="rft_section_header">Wood Floor Oil</h2>
                <p >
                    Bona Craft Oil 2K® is a unique blend of different vegetable oils,
                    modified for long-lasting protection of wood floors. The exclusive
                    blend enhances the natural and organic look and color of a wood species
                    with an extremely matte finish to add a warm and rustic feel to a
                    floor. Due to the oil saturating the actual wood, spot repairs on
                    scratches or gouges are easier to fix. For extra durability, you can
                    topcoat Bona Craft Oil 2K with Bona Traffic HD or Traffic HD Raw.
                </p>
                <ul>
                    <li>Extremely high degree of saturation</li>
                    <li>Fast-drying: 8 hours for Neutral, 12 hours for colors</li>
                    <li>Extremely low VOCs - 25 g/L</li>
                    <li>Very low odor</li>
                    <li>GREENGUARD Certified</li>
                    <li>Available in nine base colors, all are blendable to make a completely custom color</li>
                </ul>

            </div>

            <Box sx={{ marginBottom: "48px" }}>
                <MobileCarousal itemsPerRow={4} items={colorsRow1} placeHolderImgSrc={craft_oil_sand} />
            </Box>
            <Box sx={{ marginBottom: "48px" }}>
                <MobileCarousal itemsPerRow={4} items={colorsRow2} placeHolderImgSrc={craft_oil_ash} />
            </Box>

            <Grid container rowSpacing={5} columnSpacing={3} sx={{ marginBottom: "48px" }}>
                <Grid size={{ xs: 12, md: 4 }} >
                    <ProductSquare product={craftOil} />
                </Grid>
                <Grid size={{ xs: 0, md: 1 }}></Grid>
                <Grid size={{ xs: 12, md: 7 }}>
                    <img src={craft_oil_blend_chart} alt="Carft 2k Blend Chart" className="rft_img_center" style={{ maxHeight: "600px" }} />
                </Grid>
            </Grid>
        </div>
    );
};

const HowTo: React.FC<React.PropsWithChildren<{}>> = (props) => {
    return (
        <div className="rft_product_section_container">
            <VideoGrid sections={videos} />
        </div>
    );
};

const tabs: ProductTab[] = [
    {
        label: "Overview",
        component: <Overview />
    },
    {
        label: "FAQs",
        component: <FAQComponent faqs={FAQs_Oils} quickLinks={false} />
    },
    {
        label: "Document Center",
        component: <DocumentCenter subtitle="Helpful documents for wood floor oils." products={Products_Oils} />
    },
    {
        label: "How To's",
        component: <HowTo />
    },
    {
        label: "Coverage Chart",
        component: <CoverageChart />
    },
];