import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import Carousel from 'react-material-ui-carousel';
import Grid from "@mui/material/Grid2";

export interface MobileCarousalProps {
    items: React.ReactNode[];
    itemsPerRow: number;
    placeHolderImgSrc: string;
}

export const MobileCarousal: React.FC<React.PropsWithChildren<MobileCarousalProps>> = (props) => {
    const { items, itemsPerRow, placeHolderImgSrc } = props;
    const isMobile = useMediaQuery('(max-width: 899px)');
    const [firstImgLoaded, setFirstImgLoaded] = useState(false);

    return isMobile ? (
        <>
            <img
                src={placeHolderImgSrc}
                onLoad={() => setFirstImgLoaded(true)}
                alt=""
                style={{ display: "none" }}
            />
            {firstImgLoaded && (
                <Carousel
                    autoPlay={false}
                    animation="slide"
                    navButtonsAlwaysVisible={true}
                    duration={750}
                >
                    {items.map((c, i) => { return c; })}
                </Carousel>
            )}
        </>
    )
        :
        (
            <Grid className="rft_content" container spacing={2}>
                {items.map((c, i) =>
                    <Grid key={`mbl_car_${i}`} size={{ xs: 12, md: (12 / itemsPerRow) }}>
                        {c}
                    </Grid>
                )}
            </Grid>
        );
};