
export const urlToAbout = "/about";
export const urlToAbrasives = "/abrasives";
export const urlToAdhesive = "/adhesives";
export const urlToContactUs  = "/contact";
export const urlToCoatings  = "/coatings";
export const urlToEquipment  = "/equipment";
export const urlToFinishes = "/finishes";
export const urlToSealers = "/sealers";
export const urlToHome = "/";
export const urlToJanEquipment = "/equipment/janitorial";
export const urlToOils = "/oils";
export const urlToRentals = "/equipment/rentals";
export const urlToRepairs = "/equipment/repairs";
export const urlToReviews = "/reviews";
export const urlToSport = "/sport";
export const urlToStains = "/stains";
export const urlToTools = "/tools-and-accessories";
export const urlToWoodEquipment = "/equipment/wood-floor";
export const urlToFillers = "/wood-fillers";
export const urlToHowTo = "/how-to";
export const urlToFaqs = "/faqs";
export const urlToDocumentCenter = "/document-center";
export const urlToCoverageChart = "/coverage-chart";

export const urlToCatalogHome = "https://catalog.rossifloor.net";

