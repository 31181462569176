import Grid from "@mui/material/Grid2";
import React from "react";
import { PageLayout } from "../components/PageLayout";
import { ProductHero } from "../components/ProductHero";
import { hero_rentals, mobile_hero_rentals, tablet_hero_rentals } from "../images";

export const RentalsPage: React.FC<React.PropsWithChildren<{}>> = (props) => {

    return (
        <PageLayout title="Floor Equipment Rentals | Rossi Floor Technologies | Pittsburgh, PA">
            <ProductHero
                imgSrc={hero_rentals}
                mobileImgSrc={mobile_hero_rentals}
                tabletImgSrc={tablet_hero_rentals}
                alt=""
                title="Flooring Equipment Rentals"
                subtitle="Convenient Equipment Rentals for Your Project."
            />
            <div className="rft_content">
                <div className="rft_product_section_container">
                    <Grid className="rft_content" container spacing={3}>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <div className="rft_stack">
                                <div>
                                    <h2 className="rft_section_header">Convenient Equipment Rentals for Your Project</h2>
                                    <p >
                                        At Rossi Floor Technologies, we make equipment rental a breeze for both contractors and homeowners.
                                        Whether you need wood floor machinery or janitorial equipment, we offer flexible short-term and long-term
                                        rental options to suit your project needs. Our inventory is diverse, ensuring you find the right tools
                                        for the job. While walk-in rentals are always welcome, please note that equipment availability changes daily,
                                        so it's a good idea to call ahead first.  Our friendly staff is ready to assist you in finding the perfect
                                        equipment to help you achieve your goals. Experience hassle-free rentals with Rossi Floor Technologies and get started on your project today!
                                    </p>
                                </div>
                            </div >
                        </Grid>
                        <Grid size={{ xs: 0, md: 1 }}></Grid>
                        <Grid size={{ xs: 12, md: 5 }}>
                            <div style={{ backgroundColor: "#0C173C", color: "white", maxWidth: "450px", textAlign: 'center' }}>
                                <h3 style={{ padding: "12px" }}>OUR RENTAL LIST</h3>
                            </div>
                            <div style={{ paddingLeft: "24px" }}>
                                <ul style={{ lineHeight: "1.5"}}>
                                    <li>Belt sanders</li>
                                    <li>Edgers</li>
                                    <li>Auto scrubbers</li>
                                    <li>Walk-behind scrubbers</li>
                                    <li>Rider scrubbers</li>
                                    <li>Battery chargers (25 V and 36 V)</li>
                                    <li>Sweepers</li>
                                    <li>Wet/dry vacs</li>
                                    <li>Extractors</li>
                                    <li>Propane burnishers</li>
                                    <li>Electric burnishers</li>
                                    <li>Marble polishers</li>
                                </ul>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </PageLayout >
    )
}