import React from "react";
import { AdhesivesCard, FillersCard, FinishesCard, OilsCard, SealersCard, StainsCard } from '../components/CategoryCard';
import { PageLayout } from "../components/PageLayout";
import { ProductHero } from '../components/ProductHero';
import {
    hero_finishes,
    mobile_hero_finishes,
    tablet_hero_finishes
} from "../images";

export const CoatingsPage: React.FC<React.PropsWithChildren<{}>> = (props) => {

    return (
        <PageLayout title="Wood Floor Coatings | Rossi Floor Technologies | Pittsburgh, PA">
            <ProductHero
                imgSrc={hero_finishes}
                mobileImgSrc={mobile_hero_finishes}
                tabletImgSrc={tablet_hero_finishes}
                alt=""
                title="Wood Floor Coatings"
                subtitle="The complete system for flooring contractors"
            />
            <div className="rft_background_light">
                <div className="rft_content">
                    <div className="rft_cat_card_container">
                        <FinishesCard />
                        <SealersCard />
                        <StainsCard />
                        <OilsCard />
                        <AdhesivesCard />
                        <FillersCard />

                    </div>
                </div >
            </div>
        </PageLayout >
    )
}