import React from "react"
import { PageLayout } from "../components/PageLayout"
import { CoverageChart } from "../components/CoverageChart"

export const CoverageChartPage: React.FC<React.PropsWithChildren<{}>> = (props) => {

    return (
        <PageLayout title="Coverage Chart | Rossi Floor Technologies">
            <div className="rft_content">
                <CoverageChart />
            </div>
        </PageLayout>
    )
}