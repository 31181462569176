import { AccordionDetails, AccordionSummary, Button, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { createRef, useRef } from "react";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { RftAccordion } from "./RftAccordion";


export interface FAQProps {
    faqs: FAQSection[];
    title?: string;
    quickLinks?: boolean;
}

export interface FAQSection {
    section: string;
    questions: Question[];
}

export interface Question {
    question: string;
    answer: React.ReactNode;
}

export const FAQComponent: React.FC<React.PropsWithChildren<FAQProps>> = ({ faqs, quickLinks = true, title = "" }) => {
    const refsArray = faqs.map(() => createRef<HTMLDivElement>());
    const [expanded, setExpanded] = React.useState<string | false>(false);

    function onSectionClick(index: number) {
        // refsArray[index]?.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        const element = refsArray[index]?.current;
        if (!!element) {
            const yOffset = -175;
            const y = (element?.getBoundingClientRect()?.top ?? 0) + window.scrollY + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }

    }

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <div className="rft_product_section_container">
            <h1 className="rft_product_section_header">{title}</h1>

            {quickLinks && (
                <>
                    <Typography
                        sx={{
                            letterSpacing: ".1em",
                            textTransform: "uppercase",
                            fontSize: "1em",
                            marginBottom: "8px",
                        }}
                    >
                        Quick Links
                    </Typography>
                    <div className="rft_faq_quick_links">
                        {
                            faqs.map((f, i) => {
                                return (
                                    <div key={`quickLinks_${i}`}>
                                        <Button
                                            variant="text"
                                            startIcon={<ArrowForwardIcon />}
                                            sx={{ fontWeight: "bold", letterSpacing: ".1em", fontSize: "1em", padding: "4px", textTransform: "none" }}
                                            onClick={() => { onSectionClick(i); }}
                                        >
                                            {f.section}
                                        </Button>
                                    </div>
                                );
                            })
                        }
                    </div>
                </>
            )}
            {
                faqs.map((f, i) => {
                    return (
                        <div ref={refsArray[i]} key={i} className="rft_faq_section">
                            <h2>{f.section}</h2>
                            {f.questions.map((faq, j) => {
                                return (
                                    <RftAccordion
                                        key={`accordion_${i}`}
                                        defaultExpanded={i === 0 && j === 0}
                                        expanded={expanded === `panel_${i}_${j}`}
                                        onChange={handleChange(`panel_${i}_${j}`)}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content-1"
                                            id="panel1a-header-1"
                                        >
                                            <Typography className="rft_faq_question">{faq.question}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="rft_faq_answer">{faq.answer}</div>
                                        </AccordionDetails>
                                    </RftAccordion>
                                );
                            })}
                        </div>
                    );
                })
            }
        </div >
    );
};