import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React from "react";
import { CoverageChart } from "../components/CoverageChart";
import { DocumentCenter } from '../components/DocumentCenter';
import { FAQComponent } from "../components/FAQ";
import { ImageStack } from '../components/ImageStack';
import { MobileCarousal } from '../components/MobileCarousal';
import { PageLayout } from "../components/PageLayout";
import { ProductHero } from "../components/ProductHero";
import { ProductTab, ProductTabs } from "../components/ProductTabs";
import { VideoGrid } from "../components/VideoGrid";
import { bona_sport_mvp, dedicated_resources, green_solutions, hero_sport, mobile_hero_sport, tablet_hero_sport, time_money_savings, total_system_approach } from "../images";
import { FAQs_Sport } from "../static-data/faqs/Sport";
import { Products_Sport } from "../static-data/products/Sport";
import { VideoCategories, VideoCategory } from "../static-data/Videos";

export const SportPage: React.FC<React.PropsWithChildren<{}>> = (props) => {

    return (
        <PageLayout title="Sport Floors | Rossi Floor Technologies | Pittsburgh, PA">
            <ProductHero
                imgSrc={hero_sport}
                mobileImgSrc={mobile_hero_sport}
                tabletImgSrc={tablet_hero_sport}
                alt=""
                title="Sport Floors"
                subtitle="For professional arenas, college and high school sport floors, trust the Bona Sport® System."
            />
            <div className="rft_content">
                <ProductTabs tabs={tabs} />
            </div>
        </PageLayout>
    )
}

const videos = VideoCategories.filter(p => p.category === VideoCategory.Sport);


const Overview: React.FC<React.PropsWithChildren<{}>> = (props) => {
    
    const items: React.ReactNode[] = [
        <ImageStack imgSrc={dedicated_resources} title="Dedicated Resources" body="Rossi Floor has the experience and the know-how for wood sport floors." />,
        <ImageStack imgSrc={total_system_approach} title="Total system approach" body="Rossi Floor has a complete system of products for specific use on wood sport floors." />,
        <ImageStack imgSrc={green_solutions} title="Green Solutions" body="Bona waterborne sealers, finishes and waterborne gameline paints are all GREENGUARD certified for indoor air quality." />,
        <ImageStack imgSrc={time_money_savings} title="Time and Money Savings" body="The Bona Sport System saves contractors and facilities time and delivers superior results that can give a facility a beautiful floor for years to come." />
    ];

    return (
        <div className="rft_product_section_container" style={{ marginTop: "24px" }}>
            <MobileCarousal itemsPerRow={4} items={items} placeHolderImgSrc={dedicated_resources}/>

            <Grid className="rft_content" container spacing={3} sx={{ marginTop: "48px", marginBottom: "48px" }}>
                <Grid size={{ xs: 12, md: 6 }}>
                    <div className="rft_stack">
                        <div>
                            <h2 className="rft_section_header">Make Bona Sport Floors part of your winning team</h2>
                            <p >
                                We carry everything you need to clean, restore, and maintain wood floors used as indoor courts for basketball, volleyball, and other indoor sports:
                            </p>
                            <ul>
                                <li>Wood floor paint</li>
                                <li>Wood floor sealers</li>
                                <li>Wood floor finishers</li>
                                <li>Wood floor cleaners</li>
                                <li>Wood athletic floor finishers</li>
                                <li>Wood athletic floor sealers</li>
                                <li>Wood athletic floor cleaner</li>
                                <li>Wood floor/athletic floor mops</li>
                            </ul>
                        </div>
                        <div className='rft_stack_shop_now_container'>
                            <Button
                                variant="text"
                                startIcon={<ArrowForwardIcon />}
                                href="https://catalog.rossifloor.net/catalog/Items/Sport/Sport-Products/"
                                target='_blank'
                                className="rft_stack_shop_now"
                            >
                                shop now
                            </Button>
                        </div>
                    </div >
                </Grid>
                <Grid size={{ xs: 12, md: 6 }} sx={{ alignContent: 'center' }}>
                    <img src={bona_sport_mvp} alt="bona sport mvp" className='rft_img_center' />
                </Grid>
            </Grid>
        </div >
    );
};


const HowTo: React.FC<React.PropsWithChildren<{}>> = (props) => {
    return (
        <div className="rft_product_section_container">
            <VideoGrid sections={videos} />
        </div>
    );
};

const tabs: ProductTab[] = [
    {
        label: "Overview",
        component: <Overview />
    },
    // {
    //     label: "FAQs",
    //     component: <FAQComponent faqs={FAQs_Sport} />
    // },
    // {
    //     label: "Document Center",
    //     component: <DocumentCenter subtitle="Helpful documents for Sport Products." products={Products_Sport} />
    // },
    // {
    //     label: "How To's",
    //     component: <HowTo />
    // },
    // {
    //     label: "Coverage Chart",
    //     component: <CoverageChart />
    // },
];