import React from 'react';
import ReactDOM from 'react-dom/client';
import './RossiFloor.scss';
import App from './App';
import { Helmet } from 'react-helmet-async';


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);


