import React from "react"
import { PageLayout } from "../components/PageLayout"
import { ProductHero } from "../components/ProductHero"
import Grid from "@mui/material/Grid2";
import { hero_adhesives, mobile_hero_adhesives, tablet_hero_adhesives } from "../images"
import { VideoGrid } from "../components/VideoGrid"
import { ProductTab, ProductTabs } from "../components/ProductTabs"
import { FAQComponent } from "../components/FAQ"
import { DocumentCenter } from "../components/DocumentCenter"
import { FAQs_Adhesives } from "../static-data/faqs/Adhesives"
import { CoverageChart } from "../components/CoverageChart"
import { Products_Adhesives } from "../static-data/products/Adhesives"
import { VideoCategories, VideoCategory } from "../static-data/Videos";
import { ProductSquare } from "../components/ProductSquare";
import { ProductCategory, ProductSubCategory } from "../static-data/types";

export const AdhesivesPage: React.FC<React.PropsWithChildren<{}>> = (props) => {

    return (
        <PageLayout title="Adhesives | Rossi Floor Technologies | Pittsburgh, PA">
            <ProductHero
                imgSrc={hero_adhesives}
                mobileImgSrc={mobile_hero_adhesives}
                tabletImgSrc={tablet_hero_adhesives}
                alt=""
                title="Wood Floor Adhesives"
                subtitle="Install your wood floor using the right technique, products and tools. "
            />
            <div className="rft_content">
                <ProductTabs tabs={tabs} />
            </div>
        </PageLayout>
    )
}

const videos = VideoCategories.filter(p => p.category === VideoCategory.Adhesives);
const adhesives = Products_Adhesives.filter(p => p.subCategory === ProductSubCategory.Adhesives);
const moistureBarriers = Products_Adhesives.filter(p => p.subCategory === ProductSubCategory.MoistureBarrier);


const Overview: React.FC<React.PropsWithChildren<{}>> = (props) => {
    return (
        <div className="rft_product_section_container">
            <div style={{ marginTop: "24px" }}>
                <h2 className="rft_section_header">Bona adhesives: the safer choice for flooring contractors</h2>
                <p >
                    Bona® silane-based adhesives are an elite range of professional construction adhesive products that
                    are the first in the industry to be GREENGUARD GOLD certified for indoor air quality and in the creation
                    of VOC-free adhesives - which means a safer environment for both contractors and homeowners
                </p>
            </div>
            <div className="rft_grid_350">
                {adhesives.sort((a, b) => { return a.index - b.index; }).map((p) => {
                    return (
                        <ProductSquare product={p} />
                    );
                })}
            </div>

            <h2 className="rft_section_header" style={{ margin: "24px 0" }}>Bona Moisture Barriers</h2>
            <div className="rft_grid_350">
                {moistureBarriers.sort((a, b) => { return a.index - b.index; }).map((p) => {
                    return (
                        <ProductSquare product={p} />
                    );
                })}
            </div>
        </div>
    );
};

const HowTo: React.FC<React.PropsWithChildren<{}>> = (props) => {
    return (
        <div className="rft_product_section_container">
            <VideoGrid sections={videos} />
        </div>
    );
};

const tabs: ProductTab[] = [
    {
        label: "Overview",
        component: <Overview />
    },
    {
        label: "FAQs",
        component: <FAQComponent faqs={FAQs_Adhesives} quickLinks={false} />
    },
    {
        label: "Document Center",
        component: <DocumentCenter subtitle="Helpful documents for Bona adhesives." products={Products_Adhesives} />
    },
    {
        label: "How To's",
        component: <HowTo />
    },
    {
        label: "Coverage Chart",
        component: <CoverageChart />
    },
];