import { useMediaQuery } from '@mui/material';

export function useMediaImage(desktopSrc: string, tabletSrc: string, mobileSrc: string) {

    const isMobile = useMediaQuery('(max-width: 599px)');
    const isTablet = useMediaQuery('(max-width: 899px)');

    if (isMobile) return mobileSrc;
    if (isTablet) return tabletSrc
    return desktopSrc;
}