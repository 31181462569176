import Grid from '@mui/material/Grid2';
import React from "react";
import { PageLayout } from "../components/PageLayout";
import { ProductHero } from "../components/ProductHero";
import { gene_pool, gene_rossi, gene_stephen, hero_about, icon_award, icon_handshake, icon_money, icon_people, icon_phone, mobile_hero_about, neighbors_love_us, tablet_hero_about } from "../images";
import { Divider, Link, Typography } from '@mui/material';
import { urlToReviews } from '../Routing';

export const AboutPage: React.FC<React.PropsWithChildren<{}>> = (props) => {

    return (
        <PageLayout title="About | Rossi Floor Technologies">
            <ProductHero
                imgSrc={hero_about}
                mobileImgSrc={mobile_hero_about}
                tabletImgSrc={tablet_hero_about}
                alt=""
                title="Nationally Recognized Service Center"
                subtitle="Rossi Floor has been a trusted name in floor care for over 40 years."
            />
            <div className='rft_page_content'>
                <Grid className="rft_content" container spacing={3}>
                    <Grid
                        container
                        size={{ xs: 12, md: 6 }}
                        order={{ xs: 3, md: 3 }}
                    >
                        <Grid size={12}>
                            <img src={gene_pool} alt="Gene Rossi" style={{ display: "block", width: "100%", borderRadius: "12px" }} />
                            <Typography className="rft_video_title">Gene Rossi, founder of Rossi Floor Technologies</Typography>
                        </Grid>
                        <Grid size={12}>
                            <div className='rft_map_wrapper' style={{ height: "100%" }}>
                                <iframe
                                    title="rft_map"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6068.197534819102!2d-79.91592182359483!3d40.49519895085907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8834ed672c2c8283%3A0x9542c9ceee5a53f5!2s305%2023rd%20St%2C%20Pittsburgh%2C%20PA%2015215!5e0!3m2!1sen!2sus!4v1729424844960!5m2!1sen!2sus"
                                    loading="lazy" />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid size={{ xs: 12, md: 1 }} order={{ xs: 2, md: 2 }}></Grid>
                    <Grid size={{ xs: 12, md: 5 }} order={{ xs: 1, md: 1 }}>
                        <p className="rft_content_header">Homegrown in Pittsburgh</p>
                        <div className="rft_content_card_body">
                            <p>
                                Rossi Floor Technologies began humbly in 1984 when founder Gene Rossi started repairing machines in Pittsburgh's
                                historic Strip District. With his dedication to quality and customer service, Gene quickly grew his operation,
                                opening his first storefront in the Strip just a few months later. The new location allowed him to offer both wood
                                floor and janitorial machine repairs, serving the growing needs of Pittsburgh's commercial and residential flooring markets.
                            </p>
                            <p>
                                With demand on the rise, Gene moved the business to the North Hills (where he added wood floor equipment sales)
                                before expanding again in 2006 to a 6,000-square-foot warehouse in Sharpsburg, PA, where Rossi Floor Technologies continues to serve clients from across the region.
                            </p>
                            <p>
                                Today, Rossi Floor Technologies is a proud second-generation business, now under the management of Gene's son, Stephen.
                                As the company grows, it remains rooted in the same values that Gene established over four decades ago: exceptional service, expert craftsmanship,
                                and a dedication to helping clients keep their floors and equipment in top shape.
                            </p>
                        </div>
                    </Grid>
                </Grid>
                <Divider sx={{ my: "24px" }} />
                <div className='rft_content'>
                    <div style={{ marginTop: "24px" }}>
                        <h2 className="rft_section_header">The Rossi Floor Difference</h2>
                        <div className='rft_flexbox' style={{ justifyContent: "center" }}>
                            <div className="rft_img_text_stack" style={{ flex: "1 0 30%" }}>
                                <div style={{ maxWidth: "350px" }}>
                                    <div className="rft_img_about_circle">
                                        <img src={gene_rossi} alt="Gene" />
                                    </div>
                                    <h3>2nd Generation, Family Owned</h3>
                                    <p>The Rossi Family has been Pittsburgh's go-to source for wood floor supplies and machine repairs for nearly half a century</p>
                                </div>
                            </div>
                            <div className="rft_img_text_stack" style={{ flex: "1 0 30%" }}>
                                <div style={{ maxWidth: "350px" }}>
                                    <div className="rft_img_about_circle">
                                        <img src={gene_stephen} alt="Gene" />
                                    </div>
                                    <h3>Dedicated to Exceptional Service</h3>
                                    <p>Rossi Floor Technologies remains rooted in exceptional service, expert craftsmanship and a dedication to customers</p>
                                </div>
                            </div>
                            <div className="rft_img_text_stack" style={{ flex: "1 0 30%" }}>
                                <div style={{ maxWidth: "350px" }}>
                                    <div className="rft_img_about_circle">
                                        <img src={neighbors_love_us} alt="Gene" />
                                    </div>
                                    <h3>Your Neighbors Recommend Us</h3>
                                    <p>Over 100 positive reviews.  <Link href={urlToReviews}>See what your neighbors are saying!</Link></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: "24px" }}>
                        <h2 className="rft_section_header">Vision and Values</h2>
                        <p style={{ letterSpacing: "0em", lineHeight: "1.5" }}>At Rossi Floor Technologies, our commitment to excellence is reflected in our core priorities.
                            We prioritize immediate over-the-phone pricing to ensure our customers receive prompt and transparent
                            information, enhancing their decision-making process. Our contractor bulk pricing is designed to
                            support our partners with competitive rates, fostering long-term relationships in both residential
                            and commercial sectors. As the sole local distributor for Bona Products, we guarantee access to
                            top-tier flooring solutions. Our knowledgeable staff is dedicated to providing expert guidance and
                            support, ensuring every customer's needs are met with professionalism and care. We strive to be a
                            trusted partner for both residential and commercial customers, delivering unparalleled service and value.</p>
                    </div>
                    <Grid className="rft_content" container spacing={0} sx={{ marginTop: "48px", marginBottom: "48px", px: "24px" }}>
                        <Grid size={{ xs: 12, md: 6 }}>

                            <div className="rft_why_choose">
                                <img src={icon_phone} alt="" />
                                <p>Immediate Over-the-Phone Pricing</p>
                            </div>
                            <div className="rft_why_choose">
                                <img src={icon_money} alt="" />
                                <p>Contractor Bulk Pricing</p>
                            </div>
                            <div className="rft_why_choose">
                                <img src={icon_award} alt="" style={{ height: "45px" }} />
                                <p>Sole Local Distributor for Bona Products</p>
                            </div>

                        </Grid>
                        <Grid size={{ xs: 12, md: 6 }}>
                            <div className="rft_why_choose">
                                <img src={icon_people} alt="" />
                                <p>Knowledgeable Staff</p>
                            </div>
                            <div className="rft_why_choose">
                                <img src={icon_handshake} alt="" />
                                <p>Residential and Commercial Customers</p>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </PageLayout >
    )
}