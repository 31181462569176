import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React from "react";
import { Video, VideoGridSectionProps } from "../static-data/Videos";


export interface VideoGridProps {
    sections: VideoGridSectionProps[];
}

export const VideoGrid: React.FC<React.PropsWithChildren<VideoGridProps>> = (props) => {
    const { sections } = props;

    return (
        <>
            {
                sections.length === 0 ? <Typography>There are no videos at this time. Check back soon for more tips, tricks, and how-tos! </Typography>
                    : (
                        sections.map((s, i) => {
                            return (
                                <div key={`video_section_${i}`}  style={{ marginBottom: "48px" }}>
                                    <h2 className="rft_section_header" style={{ marginBottom: "24px" }}>{s.title}</h2>
                                    {s.videos.length === 0 ? <Typography > There are no videos at this time. Check back soon for more tips, tricks, and how-tos! </Typography>
                                        : (
                                            <Grid container rowSpacing={3} columnSpacing={3}>
                                                {s.videos.map((v, j) => {
                                                    return (
                                                        <Grid size={{ xs: 12, sm: 6, md: 4 }} key={`video_${i}_${j}`}>
                                                            <VideoPreview title={v.title} url={v.url} />
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        )}
                                </div>
                            );
                        })
                    )
            }
        </>
    );
};

export const VideoPreview: React.FC<React.PropsWithChildren<Video>> = (props) => {
    return (
        <div>
            <iframe
                style={{ width: "100%", minHeight: "200px" }}
                src={props.url}
                title={props.title}
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
            />
            <Typography className="rft_video_title">{props.title}</Typography>
        </div>
    );
};