import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, Divider } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React from "react";
import { PageLayout } from "../components/PageLayout";
import { ProductHero } from "../components/ProductHero";
import { ProductTab } from "../components/ProductTabs";
import { coater, hero_tools, mobile_hero_tools, tablet_hero_tools, threem_painters_tape } from "../images";
import { InfoGraphic } from '../components/InfoGraphic';

export const ToolsPage: React.FC<React.PropsWithChildren<{}>> = (props) => {

    return (
        <PageLayout title="Tools & Accsesories | Rossi Floor Technologies | Pittsburgh, PA">
            <ProductHero
                imgSrc={hero_tools}
                mobileImgSrc={mobile_hero_tools}
                tabletImgSrc={tablet_hero_tools}
                alt=""
                title="Application Tools and Accessories"
                subtitle="Everything You Need for Wood Refinishing"
            />

            <Overview />

        </PageLayout>
    )
}

const Overview: React.FC<React.PropsWithChildren<{}>> = (props) => {
    return (
        <>
            <div className="rft_content" style={{ marginTop: "48px" }}>
                <div className="rft_product_section_container">
                    <h2 className="rft_section_header">Brushes, Tape, Blades, Buckets, and More</h2>
                    <p style={{ marginBottom: "48px" }}>
                        Before you embark on any wood floor finishing or refinishing project, stock up on all the tools
                        and accessories you'll need from Rossi Floor Technologies. We carry rollers, ear plugs, knee pads,
                        and other items and offer bulk contractor pricing.
                    </p>
                </div>
            </div >
            <Divider sx={{ marginTop: "12px", marginBottom: "12px" }} />
            <div className="rft_background_light">
                <div className="rft_content" style={{ padding: '48px 0px' }}>
                    <div className='rft_product_section_container'>
                        <InfoGraphic title="Tools We Carry" imgSrc={coater} color="primary" direction="reverse">
                            <ul>
                                <li>Floor Coaters, t-bars, and rollers</li>
                                <li>Scrapers</li>
                                <li>Painter's tape</li>
                                <li>Ram Board</li>
                                <li>Floor cleaning kits</li>
                                <li>Gloves, masks, booties</li>
                                <li>Buckets, rags and mops</li>
                            </ul>
                        </InfoGraphic>
                    </div>
                </div>
            </div>
        </>
    );
};

const tabs: ProductTab[] = [
    {
        label: "Overview",
        component: <Overview />
    }
];